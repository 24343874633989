import React from "react";
import Layout from "../../components/Layout/Layout.js";
import Edit from "../../components/Resident/Edit.js";

const ResidentEditPage = () => {
  return (
    <Layout>
      <Edit />
    </Layout>
  );
};

export default ResidentEditPage;
