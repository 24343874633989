import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import styles from "./PdfStyles";
import logo from "../../assets/images/main-logo-dark.png";
import mobileImage from "../../assets/images/mobile-image.png";
import shopping from "../../assets/images/shopping-bag.png";
import tag from "../../assets/images/tag.png";
import guitar from "../../assets/images/guitar.png";
import bell from "../../assets/images/bell.png";
import star from "../../assets/images/star.png";
import shop from "../../assets/images/shop.png";
import qr from "../../assets/images/QR.png";
import startupIndia from "../../assets/images/startupIndia.png";
import footerLogo from "../../assets/images/footer-logo.png";

const OnlyPinPdf = ({ resident }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.columns}>
            <View style={styles.column}>
              <Image style={styles.onlyPinLogo} src={logo} />
              <Text style={styles.greeting}>
                {"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}
                {resident?.resident?.firstName +
                  " " +
                  resident?.resident?.lastName}
              </Text>
              <Text style={styles.apartment}>
                {resident?.resident?.apartment?.apartmentNumber +
                  ", " +
                  resident?.resident?.building?.name}
              </Text>
              <Text style={styles.onlyPinText}>Only Pin</Text>
              <Text style={styles.onlyPinText}>
                Please use the resident verification PIN provided below (inside
                the mobile device image) to continue with the registration.
              </Text>
              <Text style={styles.onlyPinPinText}>
                Complete your registration, {"\n"}and start your journey!
              </Text>
            </View>
            <View style={styles.pinColumn}>
              <Image style={styles.onlyPinMobilimg} src={mobileImage}></Image>
              <Text style={styles.onlyPinNo}>{resident?.pin}</Text>
            </View>
          </View>
          <View style={styles.onlyPinInfo}>
            <View style={styles.columns}>
              <View style={styles.column}>
                <View style={styles.title}>
                  <Image style={styles.shopping} src={shopping}></Image>
                  <Text style={styles.head}>Buy</Text>
                </View>
                <Text style={styles.para}>
                  Buy and review items for sale listed within the community.
                </Text>
              </View>
              <View style={styles.column}>
                <View style={styles.title}>
                  <Image style={styles.shopping} src={tag}></Image>
                  <Text style={styles.head}>Sell</Text>
                </View>
                <Text style={styles.para}>
                  List your items for sale to members within the Hiranandani
                  community.
                </Text>
              </View>
              <View style={styles.column}>
                <View style={styles.title}>
                  <Image style={styles.shopping} src={guitar}></Image>
                  <Text style={styles.head}>List your service</Text>
                </View>
                <Text style={styles.para}>
                  Offer your services to the community be it yoga, art, music,
                  etc. please feel free to showcase it on the app.
                </Text>
              </View>
            </View>
            <View style={styles.columns}>
              <View style={styles.column}>
                <View style={styles.title}>
                  <Image style={styles.shopping} src={bell}></Image>
                  <Text style={styles.head}>Notifications</Text>
                </View>
                <Text style={styles.para}>
                  Get notified for item categories you are interested in. When a
                  fellow resident lists the item for sale, you are notified.
                </Text>
              </View>
              <View style={styles.column}>
                <View style={styles.title}>
                  <Image style={styles.shopping} src={shop}></Image>
                  <Text style={styles.head}>Refer service providers</Text>
                </View>
                <Text style={styles.para}>
                  Refer service providers you know, so the entire community can
                  benefit and engage them more confidently.
                </Text>
              </View>
              <View style={styles.column}>
                <View style={styles.title}>
                  <Image style={styles.shopping} src={star}></Image>
                  <Text style={styles.head}>Rate services</Text>
                </View>
                <Text style={styles.para}>
                  Spread positivity by rating the service providers time to
                  time. This will help others in the community to engage them
                  more.
                </Text>
              </View>
            </View>
            <View style={styles.line} />
            <Text style={styles.bulletPara}>
              • The app is completely free for residents to download and use,
              and we intend to keep it this way.{"\n"}• We will never share your
              personal information with service providers or other third parties
              for marketing purposes.{"\n"}• To facilitate delivery, your
              specific location may be shared with service providers when you
              place orders through the app.
              {"\n"}• As good citizens of this community, it is important that
              your services do not violate association bye laws.
            </Text>
          </View>
          <View style={styles.onlyThanksMsg}>
            <Text style={styles.onlyPinText}>
              Enjoy Nivasi! We'd love to know what you think. So, when you have
              a moment, share your feedback through the app and share
              suggestions on how we can improve.
            </Text>
            <Text style={styles.thanks}>Thank you,</Text>
            <Text style={styles.team}>Team Nivasi</Text>
          </View>
          <View style={styles.onlyFooterLine} />
          <View style={styles.footerColumns}>
            <View style={styles.footerColumn}>
              <Image style={styles.onlyQr} src={qr}></Image>
            </View>
            <View style={styles.footerColumn}>
              <Text style={styles.onlyFooterContact}>
                You can text our support team at{" "}
                <Text style={styles.underline}>+91 88821 34232 </Text>
                {"     "} email us at{" "}
                <Text style={styles.underline}> support@nivasi.com </Text>
              </Text>
              <Image style={styles.onlyStartupIndia} src={startupIndia}></Image>
              <Text style={styles.onlyFooterContact}>
                Nivasi is a product of Ekam Illam Private Limited, a Startup
                India recognized entity.
              </Text>
            </View>
            <View style={styles.footerColumn}>
              <Image style={styles.onlyFooterLogo} src={footerLogo}></Image>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default OnlyPinPdf;
