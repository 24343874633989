import React from "react";
import Layout from "../../components/Layout/Layout.js";
import Edit from "../../components/ServiceProvider/Edit.js";

const ServiceProviderEditPage = () => {
  return (
    <Layout>
      <Edit />
    </Layout>
  );
};

export default ServiceProviderEditPage;
