import { Popover, Typography } from "@mui/material";
import React from "react";
import { BOTTOM, LEFT } from "../../utils/constants";

const FlagPopover = (props) => {
  const { openFlagPopover, setOpenFlagPopover, flagMessage } = props;
  const handleClose = () => {
    setOpenFlagPopover(null);
  };
  const open = Boolean(openFlagPopover);
  const id = open ? "simple-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={openFlagPopover}
      onClose={handleClose}
      anchorOrigin={{
        vertical: BOTTOM,
        horizontal: LEFT,
      }}
    >
      <Typography className="flag__popover">
        <Typography className="flag__text" fontWeight={700}>
          Flagged by
        </Typography>
        <Typography>
          {flagMessage?.flaggedBy?.firstName +
            " " +
            flagMessage?.flaggedBy?.lastName +
            " | " +
            flagMessage?.flaggedBy?.apartment?.building?.name +
            " | " +
            flagMessage?.flaggedBy?.apartment?.number}
        </Typography>
      </Typography>
    </Popover>
  );
};

export default FlagPopover;
