import React, { useCallback, useEffect, useRef, useState } from "react";
import ReportsTable from "../Common/ReportsTable";
import {
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Backdrop,
  Typography,
  Breadcrumbs,
  Stack,
  Button,
  Autocomplete,
  TextField,
  Chip,
  Box,
} from "@mui/material";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import loader from "../../assets/images/Loader.gif";
import {
  ALL,
  BLACK,
  DEEP_OCEAN_BLUE,
  FLEX_END,
  RESIDENT_TYPE_FILTER,
  SERVICE_PROVIDER_ENGAGEMENT,
  SMALL,
  START,
} from "../../utils/constants";
import SearchIcon from "@mui/icons-material/Search";
import { fetchAllServiceCategory } from "../../services/service-category";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { fetchServiceProviderReport } from "../../services/reports";
import moment from "moment";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { handleInputChange } from "../../helpers/GetMultiselectFilterValues";
import { reportsDatePickerStyle } from "../Common/CommonStyles";
import {
  formatDate,
  fromDateHandler,
  toDateHandler,
} from "../../helpers/DatePickerValues";
import { serviceProviderEngagementCsvReport } from "../../services/csvReport";

const ServiceProviderEngagement = () => {
  const localStorageItems = JSON.parse(
    localStorage.getItem("serviceProviderReportFilterValues")
  );
  let initialFilterValues;
  if (localStorageItems) {
    initialFilterValues = {
      ...localStorageItems,
      from: moment(dayjs().subtract(30, "day").$d).format("DD-MM-YYYY"),
      to: moment(dayjs().$d).format("DD-MM-YYYY"),
    };
  } else {
    initialFilterValues = {
      category: [ALL],
      resident: [ALL],
      from: moment(dayjs().subtract(30, "day").$d).format("DD-MM-YYYY"),
      to: moment(dayjs().$d).format("DD-MM-YYYY"),
    };
  }

  const [serviceProviders, setServiceProviders] = useState([]);
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const [searchValue, setSearchValue] = useState("");
  const [serviceCategories, setServiceCategories] = useState([]);
  const [sortBy, setSortBy] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const filterValuesRef = useRef(filterValues);
  const searchValuesRef = useRef(searchValue);
  const rowsPerPageRef = useRef(rowsPerPage);
  const dispatch = useDispatch();
  const isInitialRender = useRef(true);
  const [mainContentLoading, setMainContentLoading] = useState(false);

  const breadcrumbs = [
    <Link
      key="1"
      color={DEEP_OCEAN_BLUE}
      to={"/admin/reports/all"}
      className="breadcrumb__link"
    >
      Reports
    </Link>,
    <Typography key="3" color={BLACK}>
      Service Provider Engagement
    </Typography>,
  ];

  useEffect(() => {
    localStorage.setItem(
      "serviceProviderReportFilterValues",
      JSON.stringify({
        category: filterValues.category,
        resident: filterValues.resident,
      })
    );
  }, [filterValues]);

  useEffect(() => {
    filterValuesRef.current = filterValues;
  }, [filterValues]);

  useEffect(() => {
    searchValuesRef.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    rowsPerPageRef.current = rowsPerPage;
  }, [rowsPerPage]);

  const fetchData = useCallback(
    async (pageVal, sortColumn, sortBy) => {
      dispatch(startLoading());
      setMainContentLoading(true);
      const response = await fetchServiceProviderReport(
        pageVal || 0,
        rowsPerPage,
        searchValuesRef.current,
        filterValuesRef.current.from,
        filterValuesRef.current.to,
        filterValuesRef.current.category,
        filterValuesRef.current.resident,
        sortColumn || "",
        sortBy || "asc"
      );
      if (response.success && response.data?.serviceProviders) {
        setTotalRecords(response?.data?.totalRecords || 0);
        setServiceProviders(response?.data?.serviceProviders);
        dispatch(stopLoading());
        setMainContentLoading(false);
      } else {
        dispatch(stopLoading());
        setMainContentLoading(false);
        enqueueSnackbar(response.data, { variant: "error" });
      }
    },
    [rowsPerPage, dispatch]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData, filterValues]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    const fetchServiceProviders = async () => {
      setPage(0);
      dispatch(startLoading());
      const response = await fetchServiceProviderReport(
        0,
        rowsPerPageRef.current,
        searchValue,
        filterValuesRef.current.from,
        filterValuesRef.current.to,
        filterValuesRef.current.category,
        filterValuesRef.current.resident,
        sortColumn || "",
        sortBy || "asc"
      );
      if (response.success && response.data?.serviceProviders) {
        setTotalRecords(response?.data?.totalRecords || 0);
        setServiceProviders(response?.data?.serviceProviders);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    };
    const debouncer = setTimeout(() => {
      fetchServiceProviders();
    }, 500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [searchValue, dispatch, sortBy, sortColumn]);

  const fetchServiceCategories = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllServiceCategory();
    if (response.success && response.data) {
      setServiceCategories(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchServiceCategories();
  }, [fetchServiceCategories]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(newPage, sortColumn, sortBy);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    rowsPerPageRef.current = parseInt(event.target.value, 10);
    setPage(0);
  };

  const handleReviewClick = (review) => {
    const params = new URLSearchParams();

    if (filterValues?.from) {
      params.append("from", filterValues.from);
    }

    if (filterValues?.to) {
      params.append("to", filterValues.to);
    }

    const url = `/admin/reports/service-provider-engagement/reviews/${
      review?.id
    }?${params.toString()}`;

    window.open(url, "_blank");
  };

  const handleFlagClick = (review) => {
    const params = new URLSearchParams();

    if (filterValues?.from) {
      params.append("from", filterValues.from);
    }

    if (filterValues?.to) {
      params.append("to", filterValues.to);
    }

    const url = `/admin/reports/service-provider-engagement/flags/${
      review?.id
    }?${params.toString()}`;

    window.open(url, "_blank");
  };

  const sortOrderHandler = (header) => {
    setSortBy(sortBy === "asc" ? "desc" : "asc");
    setSortColumn(header);
  };

  const renderReviewCell = (data) => {
    return (
      <span
        className="Review__Service__Provider"
        onClick={() => handleReviewClick(data)}
      >
        {data?.reviews}
      </span>
    );
  };

  const renderFlagCell = (data) => {
    return (
      <span
        className="Review__Service__Provider"
        onClick={() => handleFlagClick(data)}
      >
        {data?.flags}
      </span>
    );
  };

  const clearHandler = () => {
    setFilterValues(() => ({
      category: [ALL],
      resident: [ALL],
      from: moment(dayjs().subtract(30, "day").$d).format("DD-MM-YYYY"),
      to: moment(dayjs().$d).format("DD-MM-YYYY"),
    }));
    setSearchValue("");
  };

  const downloadHandler = async () => {
    const response = await serviceProviderEngagementCsvReport(
      searchValue,
      filterValuesRef.current.from,
      filterValuesRef.current.to,
      filterValuesRef.current.category,
      filterValuesRef.current.resident,
      sortColumn || "",
      sortBy || "asc"
    );
    if (response.data) {
      const fileName = "Service-provider-engagement-report.csv";
      const file = new Blob([response.data], { type: "application/csv" });
      const url = window.URL.createObjectURL(file);
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = url;
      anchor.click();
    } else {
      enqueueSnackbar(response.data, { variant: "error" });
    }
  };

  const formattedFromDate = formatDate(filterValues.from);
  const formattedToDate = formatDate(filterValues.to);

  const loading = useSelector((state) => state.common.loading);

  return (
    <>
      <div>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          color={DEEP_OCEAN_BLUE}
          open={loading || mainContentLoading}
        >
          <img src={loader} alt="" className="Nivasi__Loader" />
        </Backdrop>
      </div>
      <Paper className="container" elevation={4}>
        <Grid container spacing={2} paddingBottom={2}>
          <Grid item md={12}>
            <Stack spacing={3}>
              <Breadcrumbs
                separator={<NavigateNextIcon />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Stack>
          </Grid>
          <Grid item md={6}>
            <OutlinedInput
              fullWidth
              placeholder="Search by name, business name"
              size={SMALL}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value.trim())}
              startAdornment={
                <InputAdornment position={START}>
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item md={6}>
            <Autocomplete
              size="small"
              disableCloseOnSelect
              multiple
              options={[
                ALL,
                ...filterValues?.category.filter(
                  (selectedValue) => selectedValue !== ALL
                ),
                ...serviceCategories
                  .map((serviceCategory) => serviceCategory.id)
                  .filter(
                    (option) =>
                      !filterValues?.category.includes(option) && option !== ALL
                  ),
              ]}
              getOptionLabel={(option) => {
                const category = [
                  { id: ALL, name: ALL },
                  ...serviceCategories,
                ].find((s) => s.id === option);
                return category?.name || "";
              }}
              value={filterValues?.category}
              onChange={(_, newValue) => {
                let event = {
                  target: {
                    value: newValue,
                  },
                };
                handleInputChange(
                  event,
                  "category",
                  setFilterValues,
                  filterValues,
                  () => {},
                  ALL
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Category" name="category" />
              )}
              renderTags={(selected, getTagProps) => {
                const displayedTags = selected.slice(0, 2);
                const hiddenTagsCount = selected.length - displayedTags.length;
                return (
                  <Box display="flex" alignItems="center">
                    {displayedTags.map((value, index) => (
                      <Chip
                        size="small"
                        key={value}
                        label={
                          value === ALL
                            ? ALL
                            : serviceCategories.find(
                                (serviceCategory) =>
                                  serviceCategory.id === value
                              )?.name
                        }
                        {...getTagProps({ index })}
                      />
                    ))}
                    {hiddenTagsCount > 0 && (
                      <Box ml={1}>+{hiddenTagsCount}</Box>
                    )}
                  </Box>
                );
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Autocomplete
              size="small"
              disableCloseOnSelect
              multiple
              limitTags={1}
              options={[
                ALL,
                ...filterValues?.resident.filter(
                  (selectedValue) => selectedValue !== ALL
                ),
                ...RESIDENT_TYPE_FILTER.filter(
                  (option) =>
                    !filterValues?.resident.includes(option) && option !== ALL
                ),
              ]}
              value={filterValues?.resident}
              onChange={(_, newValue) => {
                let event = {
                  target: {
                    value: newValue,
                  },
                };
                handleInputChange(
                  event,
                  "resident",
                  setFilterValues,
                  filterValues,
                  () => {},
                  ALL
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Residency Status"
                  name="resident"
                />
              )}
              renderTags={(selected, getTagProps) => {
                const displayedTags = selected.slice(0, 1);
                const hiddenTagsCount = selected.length - displayedTags.length;
                return (
                  <Box display="flex" alignItems="center">
                    {displayedTags.map((value, index) => (
                      <Chip
                        size="small"
                        key={value}
                        label={
                          value === ALL
                            ? ALL
                            : RESIDENT_TYPE_FILTER.find(
                                (resident) => resident === value
                              )
                        }
                        {...getTagProps({ index })}
                      />
                    ))}
                    {hiddenTagsCount > 0 && (
                      <Box ml={1}>+{hiddenTagsCount}</Box>
                    )}
                  </Box>
                );
              }}
            />
          </Grid>
          <Grid item md={6} style={{ paddingTop: 8 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker", "DatePicker"]}>
                <DatePicker
                  sx={reportsDatePickerStyle}
                  label="From"
                  format="DD/MM/YYYY"
                  value={dayjs(formattedFromDate)}
                  disableFuture
                  onChange={(newFromValue) =>
                    fromDateHandler(newFromValue, setFilterValues)
                  }
                />
                <DatePicker
                  sx={reportsDatePickerStyle}
                  label="To"
                  format="DD/MM/YYYY"
                  value={dayjs(formattedToDate)}
                  onChange={(newToValue) =>
                    toDateHandler(newToValue, setFilterValues)
                  }
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={FLEX_END} marginBottom={2}>
          <Grid md={2} item className="resident__clear__button">
            <Button
              variant="contained"
              className="submit__button"
              onClick={clearHandler}
            >
              Clear All
            </Button>
          </Grid>
          <Grid md={2} item className="resident__clear__button">
            <Button
              variant="contained"
              className="submit__button"
              onClick={downloadHandler}
            >
              Download CSV
            </Button>
          </Grid>
        </Grid>
        <ReportsTable
          from={SERVICE_PROVIDER_ENGAGEMENT}
          headers={[
            { label: "Name" },
            { label: "Business Name" },
            { label: "Phone", width: "14%" },
            { label: "Category" },
            { label: "Phone Taps", isSortable: true, sortKey: "Clicks" },
            { label: "Views", isSortable: true, sortKey: "Views" },
            { label: "Reviews", isSortable: true, sortKey: "Review" },
            {
              label: "Flags",
              isSortable: true,
              sortKey: "Flags",
            },
            {
              label: "Avg. Rating",
              isSortable: true,
              sortKey: "Average Rating",
            },
          ]}
          data={serviceProviders?.map((row) => {
            const formattedPhone =
              row?.phone &&
              `${(row.countryCode = "+91")}-${row.phone.slice(
                0,
                5
              )}-${row.phone.slice(5)}`;

            return {
              id: row?.id,
              commonColumns: [
                row?.firstName ? row?.firstName + " " + row?.lastName : "-",
                row?.businessName || "-",
                formattedPhone || "-",
                row?.category || "-",
                row?.clicks || 0,
                row?.views || 0,
                renderReviewCell(row) || 0,
                renderFlagCell(row) || 0,
                (row?.averageRating !== 0 &&
                  row?.averageRating !== 0.0 &&
                  row?.averageRating.toFixed(1)) ||
                  "-",
              ],
              resident: row?.resident,
              data: row,
            };
          })}
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          sortOrderHandler={sortOrderHandler}
          sortBy={sortBy}
          sortColumn={sortColumn}
          handleReviewClick={handleReviewClick}
        />
      </Paper>
    </>
  );
};

export default ServiceProviderEngagement;
