import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  closeSelectLanguageModal,
  startLoading,
} from "../../redux/Slices/CommonSlice";
import { Formik } from "formik";
import { CENTER, LANGUAGE_MESSAGE, ROW } from "../../utils/constants";

const SelectLanguageModal = (props) => {
  const { onOkHandler, onCancelHandler, cancelText, okText } = props;
  const open = useSelector((store) => store.common.selectLanguageModalOpen);
  const dispatch = useDispatch();

  let resetFunc = () => {};

  const cancelHandler = () => {
    onCancelHandler();
    dispatch(closeSelectLanguageModal());
    resetFunc();
  };

  return (
    <Formik
      initialValues={{
        language: "English",
      }}
      enableReinitialize
      onSubmit={async (values) => {
        dispatch(startLoading());
        onOkHandler(values);
        dispatch(closeSelectLanguageModal());
        resetFunc();
      }}
    >
      {({ values, handleSubmit, handleChange, resetForm }) => {
        resetFunc = resetForm;
        return (
          <>
            <Modal
              open={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className="Select__Language__Modal">
                <Typography
                  id="modal-modal-description"
                  className="Delete__Reason__Modal"
                >
                  {LANGUAGE_MESSAGE}
                </Typography>
                <form onSubmit={handleSubmit} className="form">
                  <Grid container textAlign={CENTER}>
                    <Grid item md={12}>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="language"
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            checked={values?.language === "English"}
                            value="English"
                            control={<Radio />}
                            label="English"
                          />
                          <FormControlLabel
                            checked={values?.language === "Tamil"}
                            value="Tamil"
                            control={<Radio />}
                            label="Tamil"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item md={12}>
                      <Stack
                        direction={ROW}
                        spacing={2}
                        justifyContent={CENTER}
                        marginTop={"10px"}
                      >
                        <Button
                          variant="outlined"
                          onClick={cancelHandler}
                          className="cancel__button"
                        >
                          {cancelText}
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          className="submit__button"
                        >
                          {okText}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Modal>
          </>
        );
      }}
    </Formik>
  );
};

export default SelectLanguageModal;
