import React from "react";
import BuySellFlags from "../../components/BuySell/BuySellFlags";
import Layout from "../../components/Layout/Layout";

const BuySellFlagsPage = () => {
  return (
    <Layout>
      <BuySellFlags />
    </Layout>
  );
};

export default BuySellFlagsPage;
