import adminRootInstance from "../utils/config/axios-config";

export const fetchAllServiceProviderReview = async (
  page,
  rowsPerPage,
  searchValue,
  anonymous,
  flagged,
  deleted,
  sortColumn,
  sortBy
) => {
  try {
    const response = await adminRootInstance.get(
      `/service-provider-review?offset=${page}&limit=${rowsPerPage}&searchValue=${searchValue}&anonymous=${anonymous}&flagged=${flagged}&status=${deleted}&sortColumn=${sortColumn}&sortBy=${sortBy}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchServiceProviderReview = async (id) => {
  try {
    const response = await adminRootInstance.get(
      `/service-provider-review/${id}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const updateServiceProviderReview = async (id, values) => {
  try {
    const response = await adminRootInstance.put(
      `/service-provider-review/${id}`,
      values
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data?.message,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const deleteServiceProviderReview = async (id) => {
  try {
    const response = await adminRootInstance.delete(
      `/service-provider-review/${id}`
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data?.message,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const unHideServiceProviderReview = async (id) => {
  try {
    const response = await adminRootInstance.put(
      `/service-provider-review/un-hide/${id}`
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data?.message,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};
