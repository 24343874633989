import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import NavigationIcon from "@mui/icons-material/Navigation";
import {
  BOLD,
  DEEP_OCEAN_BLUE,
  FIT_CONTENT,
  INHERIT,
} from "../../utils/constants";
import { useNavigate } from "react-router-dom";

const ReportList = (props) => {
  const { selectedTab } = props;
  const navigate = useNavigate();

  const handleNavigate = (pathName) => {
    navigate(`/admin/reports/${pathName}`);
  };

  return (
    <Paper className="container" elevation={4}>
      <Grid container>
        <Box width={"100%"} border={"1px solid #e0e0e0"}>
          {selectedTab === 0 ? (
            <List>
              <ListItem sx={{ width: FIT_CONTENT }}>
                <ListItemText
                  primary={
                    <Typography
                      sx={{ color: DEEP_OCEAN_BLUE, fontWeight: BOLD }}
                    >
                      Resident Report
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                sx={{ width: FIT_CONTENT }}
                onClick={() => {
                  handleNavigate("resident-engagement");
                }}
              >
                <ListItemButton
                  sx={{
                    "&:hover": {
                      backgroundColor: INHERIT,
                    },
                  }}
                >
                  <ListItemIcon>
                    <NavigationIcon className="report__navigation__icon" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          "&:hover": {
                            color: DEEP_OCEAN_BLUE,
                            fontWeight: BOLD,
                          },
                        }}
                      >
                        Resident Engagement
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                sx={{ width: FIT_CONTENT }}
                onClick={() => {
                  handleNavigate("buy-sell");
                }}
              >
                <ListItemButton
                  sx={{
                    "&:hover": {
                      backgroundColor: INHERIT,
                    },
                  }}
                >
                  <ListItemIcon>
                    <NavigationIcon className="report__navigation__icon" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          "&:hover": {
                            color: DEEP_OCEAN_BLUE,
                            fontWeight: BOLD,
                          },
                        }}
                      >
                        Buy & Sell
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                sx={{ width: FIT_CONTENT }}
                onClick={() => {
                  handleNavigate("word-cloud");
                }}
              >
                <ListItemButton
                  sx={{
                    "&:hover": {
                      backgroundColor: INHERIT,
                    },
                  }}
                >
                  <ListItemIcon>
                    <NavigationIcon className="report__navigation__icon" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          "&:hover": {
                            color: DEEP_OCEAN_BLUE,
                            fontWeight: BOLD,
                          },
                        }}
                      >
                        Word Cloud
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </List>
          ) : (
            <List>
              <ListItem sx={{ width: FIT_CONTENT }}>
                <ListItemText
                  primary={
                    <Typography
                      sx={{ color: DEEP_OCEAN_BLUE, fontWeight: BOLD }}
                    >
                      Service Provider Report
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                sx={{ width: FIT_CONTENT }}
                onClick={() => {
                  handleNavigate("service-provider-engagement");
                }}
              >
                <ListItemButton
                  sx={{
                    "&:hover": {
                      backgroundColor: INHERIT,
                    },
                  }}
                >
                  <ListItemIcon>
                    <NavigationIcon className="report__navigation__icon" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          "&:hover": {
                            color: DEEP_OCEAN_BLUE,
                            fontWeight: BOLD,
                          },
                        }}
                      >
                        Service Provider Engagement
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </List>
          )}
        </Box>
      </Grid>
    </Paper>
  );
};

export default ReportList;
