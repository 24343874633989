import Layout from "../../components/Layout/Layout.js";
import List from "../../components/ReviewManagement/List.js";
import React from "react";

const ReviewListPage = () => {
  return (
    <Layout>
      <List />
    </Layout>
  );
};

export default ReviewListPage;
