import React, { useEffect, useState, useCallback } from "react";
import { fetchResidentReferrals } from "../../../services/reports";
import ReportsTable from "../../Common/ReportsTable";
import { formatPhoneNumber } from "../../../helpers/formatValues";
import { Link } from "react-router-dom";
import { DEEP_OCEAN_BLUE } from "../../../utils/constants";
const Referrals = ({ residentId, from, to }) => {
  const [referrals, setReferrals] = useState([]);

  const loadData = useCallback(async () => {
    const params = { from, to };
    const res = await fetchResidentReferrals(residentId, params);
    if (res && res.success) {
      setReferrals(res.data);
    }
  }, [residentId, from, to]);

  useEffect(() => {
    if (residentId) {
      loadData();
    }
  }, [residentId, loadData]);

  const nameRenderer = (data) => {
    return (
      <Link
        color={DEEP_OCEAN_BLUE}
        to={`/admin/service-provider/view/${data.id}`}
        target="_blank"
      >
        {data?.firstName ? data?.firstName + " " + data?.lastName : "-"}
      </Link>
    );
  };

  const businessNameRenderer = (data) => {
    return (
      <Link
        to={`/admin/service-provider/view/${data.id}`}
        color={DEEP_OCEAN_BLUE}
        target="_blank"
      >
        {data?.businessName}
      </Link>
    );
  };

  return (
    <div>
      <ReportsTable
        noPagination={true}
        headers={[
          { label: "Name" },
          { label: "Phone Number" },
          { label: "Business Name" },
        ]}
        data={
          referrals &&
          referrals?.map((row) => {
            return {
              id: row?.id,
              commonColumns: [
                row?.firstName ? nameRenderer(row) : "-",
                row?.phone
                  ? formatPhoneNumber(row.phone, row.countryCode)
                  : "-",
                businessNameRenderer(row) || "-",
              ],
              data: row,
            };
          })
        }
      />
    </div>
  );
};

export default Referrals;
