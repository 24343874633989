import Create from "../../components/CreateServiceProvider/Create";
import Layout from "../../components/Layout/Layout";

const CreateServiceProvider = () => {
  return (
    <Layout>
      <Create />
    </Layout>
  );
};

export default CreateServiceProvider;
