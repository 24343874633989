import React from "react";
import Layout from "../../components/Layout/Layout.js";
import BuySellList from "../../components/Reports/BuySellList.js";

const BuySellPage = () => {
  return (
    <Layout>
      <BuySellList />
    </Layout>
  );
};

export default BuySellPage;
