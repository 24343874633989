import { createSlice } from "@reduxjs/toolkit";

const AuthSlice = createSlice({
  name: "authSlice",
  initialState: {
    isAuthenticated: false,
    userName: "",
    id: null,
  },
  reducers: {
    loginHandler: (state, action) => {
      state.isAuthenticated = true;
      state.userName = action.payload;
    },
    loginHandlerWithId: (state, action) => {
      state.id = action.payload;
    },
    loginFailed: (state) => {
      state.isAuthenticated = false;
      state.userName = "";
      state.id = null;
    },
    logoutHandler: (state) => {
      state.isAuthenticated = false;
      state.userName = "";
      state.id = null;
    },
  },
});

export const { loginHandler, logoutHandler, loginHandlerWithId } =
  AuthSlice.actions;
export default AuthSlice.reducer;
