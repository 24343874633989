import { Paper } from "@mui/material";
import ServiceProviderList from "./List";

const ServiceProvider = () => {
  return (
    <Paper className="container" elevation={4}>
      <ServiceProviderList />
    </Paper>
  );
};

export default ServiceProvider;
