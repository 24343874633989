import React, { useEffect, useState } from "react";
import {
  CENTER,
  DEEP_OCEAN_BLUE,
  FLEX,
  SPACE_BETWEEN,
} from "../../utils/constants";
import {
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Rating,
  Typography,
} from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchServiceProviderReviewReport } from "../../services/reports";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { enqueueSnackbar } from "notistack";

const ServiceProviderReviews = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [flags, setFlags] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const from = params.get("from");
  const to = params.get("to");

  useEffect(() => {
    const fetchData = async () => {
      dispatch(startLoading());
      const response = await fetchServiceProviderReviewReport(id, from, to);
      if (response.success && response.data) {
        setFlags(response?.data);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    };
    fetchData();
  }, [id, dispatch, from, to]);

  return (
    <Paper className="paper__container" elevation={4} sx={{ minHeight: 625 }}>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "12px",
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          align={CENTER}
          marginBottom={"15px"}
          color={DEEP_OCEAN_BLUE}
          fontWeight={600}
        >
          Review Details
        </Typography>
        <List>
          <Grid container spacing={3}>
            <Grid item md={2} display={FLEX} justifyContent={SPACE_BETWEEN}>
              <Typography
                paddingLeft={"15px"}
                className="TableComp__table__header"
              >
                Review by
              </Typography>
            </Grid>
            <Grid item md={2} display={FLEX} justifyContent={SPACE_BETWEEN}>
              <Typography
                paddingLeft={"15px"}
                className="TableComp__table__header"
              >
                Rating
              </Typography>
            </Grid>
            <Grid item md={8} display={FLEX} alignItems={CENTER}>
              <Typography
                className="TableComp__table__header"
                paddingLeft={"5px"}
              >
                Description
              </Typography>
            </Grid>
          </Grid>
          {flags?.map((flag) => {
            return (
              <>
                <ListItem sx={{ marginTop: 3, marginBottom: 3 }}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      md={2}
                      display={FLEX}
                      justifyContent={SPACE_BETWEEN}
                    >
                      <Link
                        key={flag?.id}
                        color={DEEP_OCEAN_BLUE}
                        to={`/admin/resident/view/${flag?.resident?.id}`}
                      >
                        <Typography className="Flagged__By__Name">
                          {`${flag?.resident?.firstName} ${flag?.resident?.lastName} | ${flag?.resident?.apartment?.number} | ${flag?.resident?.apartment?.building?.name}`}
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item md={2} display={FLEX} alignItems={CENTER}>
                      <Rating
                        name="rating-stars"
                        value={flag?.rating}
                        precision={0.5}
                        readOnly
                      />
                    </Grid>
                    <Grid item md={8} display={FLEX} alignItems={CENTER}>
                      <Typography>{flag?.description || "-"}</Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                {flags.length > 1 && <Divider component="li" />}
              </>
            );
          })}
        </List>
      </Typography>
    </Paper>
  );
};

export default ServiceProviderReviews;
