import React from "react";
import Layout from "../../components/Layout/Layout";
import ServiceProviderReviews from "../../components/Reports/ServiceProviderReviews";

const ServiceProviderReviewsReportPage = () => {
  return (
    <Layout>
      <ServiceProviderReviews />
    </Layout>
  );
};

export default ServiceProviderReviewsReportPage;
