import adminRootInstance from "../utils/config/axios-config";

export const fetchAllResidents = async (
  page,
  rowsPerPage,
  searchValue,
  filterValues,
  sortColumn,
  sortBy
) => {
  try {
    const response = await adminRootInstance.get(
      `/residents?offset=${page}&limit=${rowsPerPage}&searchValue=${searchValue}&status=${filterValues.status}&deleteStatus=${filterValues.deleteStatus}&sortColumn=${sortColumn}&sortBy=${sortBy}&building=${filterValues.buildingName}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchResident = async (id) => {
  try {
    const response = await adminRootInstance.get(`/resident/${id}`);
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const updateResident = async (id, values) => {
  try {
    const response = await adminRootInstance.put(`/resident/${id}`, values);
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data?.message,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const deleteResident = async (id, reason, userRequested) => {
  try {
    const response = await adminRootInstance.post(`/resident/${id}`, {
      reason: reason,
      userRequested: userRequested,
    });
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchAllResidentNames = async (id) => {
  try {
    const response = await adminRootInstance.get(`/resident-basic-details`);
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const duplicatePhoneNumber = async (phoneNumber) => {
  try {
    const response = await adminRootInstance.get(
      `/resident/duplicate-phone-number/${phoneNumber}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const uploadImage = async (file, id) => {
  try {
    const formData = new FormData();
    const newFile = new File([file], `image.${file?.type?.split("/").pop()}`);
    formData.append("file", newFile);
    const response = await adminRootInstance.post(
      `/resident/image-upload/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const deleteResidentImage = async (id) => {
  try {
    const response = await adminRootInstance.delete(
      `/resident/remove-image/${id}`
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const canPrintPin = async (phoneNumber) => {
  try {
    const response = await adminRootInstance.get(
      `/resident/can-print-pin/${phoneNumber}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};
