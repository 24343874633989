import Layout from "../../components/Layout/Layout";
import List from "../../components/BuySell/List";

const BuySellListPage = () => {
  return (
    <Layout>
      <List />
    </Layout>
  );
};

export default BuySellListPage;
