import React, { useCallback, useEffect, useRef, useState } from "react";
import ReportsTable from "../Common/ReportsTable";
import {
  Grid,
  Paper,
  Backdrop,
  Button,
  Breadcrumbs,
  Stack,
  Typography,
  Autocomplete,
  TextField,
  Box,
  Chip,
} from "@mui/material";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { fetchAllResidentNames } from "../../services/resident";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { ALL, BLACK, DEEP_OCEAN_BLUE, FLEX_END } from "../../utils/constants";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { fetchProductReport } from "../../services/reports";
import { fetchBuildingForCommunity } from "../../services/building";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { handleInputChange } from "../../helpers/GetMultiselectFilterValues";
import { reportsDatePickerStyle } from "../Common/CommonStyles";
import {
  formatDate,
  fromDateHandler,
  toDateHandler,
} from "../../helpers/DatePickerValues";
import loader from "../../assets/images/Loader.gif";
import { buySellProductCsvReport } from "../../services/csvReport";

const BuySellList = () => {
  const localStorageItems = JSON.parse(
    localStorage.getItem("buySellReportFilterValues")
  );
  let initialFilterValues;
  if (localStorageItems) {
    initialFilterValues = {
      ...localStorageItems,
      from: moment(dayjs().subtract(30, "day").$d).format("DD-MM-YYYY"),
      to: moment(dayjs().$d).format("DD-MM-YYYY"),
    };
  } else {
    initialFilterValues = {
      resident: [ALL],
      buildingName: [ALL],
      apartmentNumber: [ALL],
      from: moment(dayjs().subtract(30, "day").$d).format("DD-MM-YYYY"),
      to: moment(dayjs().$d).format("DD-MM-YYYY"),
    };
  }

  const [products, setProducts] = useState([]);
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const filterValuesRef = useRef(filterValues);
  const [sortBy, setSortBy] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const rowsPerPageRef = useRef(rowsPerPage);
  const [buildings, setBuildings] = useState([]);
  const [apartments, setApartments] = useState([]);
  const [residents, setResidents] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setPage(0);
    localStorage.setItem(
      "buySellReportFilterValues",
      JSON.stringify({
        resident: filterValues.resident,
        buildingName: filterValues.buildingName,
        apartmentNumber: filterValues.apartmentNumber,
      })
    );
  }, [filterValues]);

  const breadcrumbs = [
    <Link
      key="1"
      color={DEEP_OCEAN_BLUE}
      to={"/admin/reports/all"}
      className="breadcrumb__link"
    >
      Reports
    </Link>,
    <Typography key="3" color={BLACK}>
      Buy & Sell
    </Typography>,
  ];

  useEffect(() => {
    filterValuesRef.current = filterValues;
  }, [filterValues]);

  useEffect(() => {
    rowsPerPageRef.current = rowsPerPage;
  }, [rowsPerPage]);

  const fetchData = useCallback(
    async (pageVal) => {
      dispatch(startLoading());
      const response = await fetchProductReport(
        pageVal || 0,
        rowsPerPage,
        filterValuesRef.current.from,
        filterValuesRef.current.to,
        filterValuesRef.current.buildingName,
        filterValuesRef.current.apartmentNumber,
        filterValuesRef.current.resident,
        sortColumn,
        sortBy
      );
      if (response.success && response.data?.products) {
        setTotalRecords(response?.data?.totalRecords || 0);
        setProducts(response?.data?.products);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    },
    [rowsPerPage, dispatch, sortBy, sortColumn]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData, filterValues]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(newPage, sortBy, sortColumn);
  };

  const sortOrderHandler = (header) => {
    setSortBy(sortBy === "asc" ? "desc" : "asc");
    setSortColumn(header);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    rowsPerPageRef.current = parseInt(event.target.value, 10);
    setPage(0);
  };

  const formattedFromDate = formatDate(filterValues.from);
  const formattedToDate = formatDate(filterValues.to);

  useEffect(() => {
    (async () => {
      const response = await fetchBuildingForCommunity(1);
      if (response.success && response.data) {
        setBuildings(response.data);
      } else {
        setBuildings([]);
      }
    })();
  }, []);

  const clearHandler = () => {
    setFilterValues(() => ({
      from: moment(dayjs().subtract(30, "day").$d).format("DD-MM-YYYY"),
      to: moment(dayjs().$d).format("DD-MM-YYYY"),
      buildingName: [ALL],
      apartmentNumber: [ALL],
      resident: [ALL],
    }));
  };

  useEffect(() => {
    const filteredApartments = () => {
      let allApartments = [];
      buildings.map((building) =>
        building?.apartments.map((apartment) => allApartments.push({...apartment, building: building}))
      );
      setApartments(allApartments);
    };
    filteredApartments();
  }, [buildings]);

  const getApartmentsByBuilding = (buildingId) => {
    buildingId.length === 0 && buildingId.push(ALL);
    const selectedBuilding = buildingId.filter((val) => val !== ALL);
    const lastIndex = buildingId.length - 1;
    const lastElement = buildingId[lastIndex];

    const apartmentsBySelectedBuilding = [];
    if (lastElement === ALL) {
      buildings.forEach((apartment) => {
        apartment.apartments.forEach((ap) =>
          apartmentsBySelectedBuilding.push({...ap, building: apartment})
        );
      });
      setApartments(apartmentsBySelectedBuilding);
    } else {
      let apartmentByBuilding = buildings.filter((building) =>
        selectedBuilding.includes(building.id)
      );
      apartmentByBuilding.forEach((apartment) => {
        apartment.apartments.forEach((ap) =>
          apartmentsBySelectedBuilding.push({...ap, building: apartment})
        );
      });
      setApartments(apartmentsBySelectedBuilding);
    }
    setFilterValues((prev) => ({
      ...prev,
      apartmentNumber: [ALL],
      resident: [ALL],
    }));
  };

  const fetchResidentsAll = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllResidentNames();
    if (response.success && response.data) {
      setResidents(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchResidentsAll();
  }, [fetchResidentsAll]);

  const getResidentsByApartments = () => {
    setFilterValues((prev) => ({
      ...prev,
      resident: [ALL],
    }));
  };

  const handleListingClick = (listing) => {
    const params = new URLSearchParams();

    if (filterValues?.from) {
      params.append("from", filterValues.from);
    }

    if (filterValues?.to) {
      params.append("to", filterValues.to);
    }

    if (listing?.numberOfListings) {
      params.append("totalRecords", listing?.numberOfListings);
    }

    const url = `/admin/reports/buy-sell/listing/${
      listing?.residentId
    }?${params.toString()}`;

    window.open(url, "_blank");
  };

  const renderListingCell = (data) => {
    return (
      <span
        className="Review__Service__Provider"
        onClick={() => handleListingClick(data)}
      >
        {data?.numberOfListings || "-"}
      </span>
    );
  };

  const downloadHandler = async (pageVal) => {
    const response = await buySellProductCsvReport(
      0,
      rowsPerPage,
      filterValuesRef.current.from,
      filterValuesRef.current.to,
      filterValuesRef.current.buildingName,
      filterValuesRef.current.apartmentNumber,
      filterValuesRef.current.resident,
      sortColumn,
      sortBy
    );
    if (response.data) {
      const fileName = "Buy-sell-product-report.csv";
      const file = new Blob([response.data], { type: "application/csv" });
      const url = window.URL.createObjectURL(file);
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = url;
      anchor.click();
    } else {
      enqueueSnackbar(response.data, { variant: "error" });
    }
  };

  const loading = useSelector((state) => state.common.loading);

  return (
    <>
      <div>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          color={DEEP_OCEAN_BLUE}
          open={loading}
        >
          <img src={loader} alt="" className="Nivasi__Loader" />
        </Backdrop>
      </div>
      <Paper className="container" elevation={4}>
        <Grid container spacing={2} paddingBottom={2}>
          <Grid item md={12}>
            <Stack spacing={3}>
              <Breadcrumbs
                separator={<NavigateNextIcon />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Stack>
          </Grid>
          <Grid item md={6} marginTop={"-10px"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker", "DatePicker"]}>
                <DatePicker
                  sx={reportsDatePickerStyle}
                  label="From"
                  value={dayjs(formattedFromDate)}
                  disableFuture
                  onChange={(newFromValue) =>
                    fromDateHandler(newFromValue, setFilterValues)
                  }
                />
                <DatePicker
                  sx={reportsDatePickerStyle}
                  label="To"
                  value={dayjs(formattedToDate)}
                  minDate={dayjs(formattedFromDate)}
                  onChange={(newToValue) =>
                    toDateHandler(newToValue, setFilterValues)
                  }
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item md={6}>
            <Autocomplete
              size="small"
              disableCloseOnSelect
              multiple
              options={[
                ALL,
                ...filterValues?.buildingName.filter(
                  (selectedValue) => selectedValue !== ALL
                ),
                ...buildings
                  .map((building) => building.id)
                  .filter(
                    (option) =>
                      !filterValues?.buildingName.includes(option) &&
                      option !== ALL
                  ),
              ]}
              getOptionLabel={(option) => {
                const building = [{ id: ALL, name: ALL }, ...buildings].find(
                  (s) => s.id === option
                );
                return building?.name || "";
              }}
              value={filterValues?.buildingName}
              onChange={(_, newValue) => {
                let event = {
                  target: {
                    value: newValue,
                  },
                };

                handleInputChange(
                  event,
                  "buildingName",
                  setFilterValues,
                  filterValues,
                  () => {},
                  ALL
                );

                getApartmentsByBuilding(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Building Name" name="building" />
              )}
              renderTags={(selected, getTagProps) => {
                const displayedTags = selected.slice(0, 1);
                const hiddenTagsCount = selected.length - displayedTags.length;
                return (
                  <Box display="flex" alignItems="center">
                    {displayedTags.map((value, index) => (
                      <Chip
                        size="small"
                        key={value}
                        label={
                          value === ALL
                            ? ALL
                            : buildings.find(
                                (building) => building.id === value
                              )?.name
                        }
                        {...getTagProps({ index })}
                      />
                    ))}
                    {hiddenTagsCount > 0 && (
                      <Box ml={1}>+{hiddenTagsCount}</Box>
                    )}
                  </Box>
                );
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Autocomplete
              size="small"
              disableCloseOnSelect
              multiple
              options={[
                ALL,
                ...apartments.filter(option => {
                 return filterValues.buildingName.includes(ALL) || filterValues.buildingName.includes(option.building?.id)
                })
                  .map((apartment) => apartment.id)
                  .filter(
                    (option) =>
                      !filterValues?.apartmentNumber.includes(option) &&
                      option !== ALL
                  ),
              ]}
              getOptionLabel={(option) => {
                const apartment = [{ id: ALL, name: ALL }, ...apartments].find(
                  (s) => s.id === option
                );
                let apartmentName = '';
                if (apartment && apartment.name) {
                  apartmentName = `${apartment?.name} ${apartment.building ? ' - ' + apartment.building.name : ''}`
                } else {
                  apartmentName = `${apartment?.apartmentNumber} ${apartment.building ? ' - ' + apartment.building.name : ''}`
                }
                return apartmentName;
              }}
              value={filterValues?.apartmentNumber}
              onChange={(_, newValue) => {
                let event = {
                  target: {
                    value: newValue,
                  },
                };
                handleInputChange(
                  event,
                  "apartmentNumber",
                  setFilterValues,
                  filterValues,
                  () => {},
                  ALL
                );
                getResidentsByApartments();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Apartment Number"
                  name="apartment"
                />
              )}
              renderTags={(selected, getTagProps) => {
                const displayedTags = selected.slice(0, 1);
                const hiddenTagsCount = selected.length - displayedTags.length;
                return (
                  <Box display="flex" alignItems="center">
                    {displayedTags.map((value, index) => (
                      <Chip
                        size="small"
                        key={`${value}-${index}`}
                        label={
                          value === ALL
                            ? ALL
                            : apartments.find(
                                (apartment) => apartment.id === value
                              )?.apartmentNumber
                        }
                        {...getTagProps({ index })}
                      />
                    ))}
                    {hiddenTagsCount > 0 && (
                      <Box ml={1}>+{hiddenTagsCount}</Box>
                    )}
                  </Box>
                );
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Autocomplete
              size="small"
              disableCloseOnSelect
              multiple
              options={[
                ALL,
                ...filterValues?.resident.filter(
                  (selectedValue) => selectedValue !== ALL
                ),
                ...residents.filter((option) => (filterValues.apartmentNumber.includes(ALL) || filterValues.apartmentNumber.includes(option?.apartment?.id)) 
                && ((filterValues.buildingName.includes(ALL) || filterValues.buildingName.includes(option?.apartment?.building?.id))))
                .map((resident) => resident.id)
                  .filter(
                    (option) =>
                      !filterValues?.resident.includes(option) && option !== ALL
                  ),
              ]}
              getOptionLabel={(option) => {
                const resident = [{ id: ALL, name: ALL }, ...residents].find(
                  (s) => s.id === option
                );
                let residentName = resident?.name;
                if (resident && resident.name) {
                  residentName = `${resident?.name}  ${resident.apartment?.building ? ' - ' +  resident.apartment?.building.name + ' - ' +resident?.apartment?.number : ''}`
                }
                return residentName;
              }}
              value={filterValues?.resident}
              onChange={(_, newValue) => {
                let event = {
                  target: {
                    value: newValue,
                  },
                };
                handleInputChange(
                  event,
                  "resident",
                  setFilterValues,
                  filterValues,
                  () => {},
                  ALL
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Resident Name"
                  name="residentName"
                />
              )}
              renderTags={(selected, getTagProps) => {
                const displayedTags = selected.slice(0, 1);
                const hiddenTagsCount = selected.length - displayedTags.length;
                return (
                  <Box display="flex" alignItems="center">
                    {displayedTags.map((value, index) => (
                      <Chip
                        size="small"
                        key={value}
                        label={
                          value === ALL
                            ? ALL
                            : residents.find(
                                (resident) => resident.id === value
                              )?.name
                        }
                        {...getTagProps({ index })}
                      />
                    ))}
                    {hiddenTagsCount > 0 && (
                      <Box ml={1}>+{hiddenTagsCount}</Box>
                    )}
                  </Box>
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={FLEX_END} marginBottom={2}>
          <Grid item className="resident__clear__button">
            <Button
              variant="contained"
              className="submit__button"
              onClick={clearHandler}
            >
              Clear All
            </Button>
          </Grid>
          <Grid md={2} item className="resident__clear__button">
            <Button
              variant="contained"
              className="submit__button"
              onClick={downloadHandler}
            >
              Download CSV
            </Button>
          </Grid>
        </Grid>
        <ReportsTable
          from="buySellList"
          headers={[
            { label: "Resident" },
            { label: "Building" },
            { label: "Apartment No" },
            { label: "# of Listing", isSortable: true, sortKey: "Listing" },
          ]}
          data={products?.map((row) => {
            return {
              id: row?.id,
              commonColumns: [
                row?.residentFirstName
                  ? row?.residentFirstName + " " + row?.residentLastName
                  : "-",
                row?.buildingName,
                row?.apartmentNumber,
                renderListingCell(row),
              ],
              data: row,
            };
          })}
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          sortOrderHandler={sortOrderHandler}
        />
      </Paper>
    </>
  );
};

export default BuySellList;
