import Layout from "../../components/Layout/Layout";
import Create from "../../components/ServiceProvider/Create";

const ServiceProviderCreatePage = () => {
  return (
    <Layout>
      <Create />
    </Layout>
  );
};

export default ServiceProviderCreatePage;
