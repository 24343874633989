import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { loginHandler } from "../redux/Slices/AuthSlice";

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");

  const isAuthenticated = useSelector((state) => {
    if (accessToken && !state.auth.isAuthenticated) {
      let userName = localStorage.getItem("userName");
      dispatch(loginHandler(userName));
    }
    return accessToken || state.auth.isAuthenticated;
  });
  if (isAuthenticated) {
    return children;
  } else {
    localStorage.setItem("previousURL", location.pathname + location.search);
    return <Navigate to="/admin/login" replace />;
  }
};

export default ProtectedRoute;
