export const handleInputChange = (
  event,
  field,
  setFilterValues,
  prevValues,
  setFieldOpen,
  ALL
) => {
  const currentValue = event.target.value;
  let updatedValue;

  if (currentValue.includes(ALL)) {
    if (currentValue.length === 1) {
      updatedValue = [ALL];
      setFieldOpen(false);
    } else if (prevValues[field].includes(ALL)) {
      updatedValue = currentValue.filter((value) => value !== ALL);
    } else {
      updatedValue = [ALL];
      setFieldOpen(false);
    }
  } else if (currentValue.length === 0) {
    updatedValue = [ALL];
    setFieldOpen(false);
  } else {
    updatedValue = currentValue;
  }

  setFilterValues((prev) => ({
    ...prev,
    [field]: updatedValue,
  }));
};
