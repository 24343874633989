import * as Yup from "yup";

const CommonValidations = Yup.object({
  firstName: Yup.string()
    .required("First name is required")
    .max(30, "First name must be at most 30 characters"),
  lastName: Yup.string()
    .required("Last name is required")
    .max(30, "Last name must be at most 30 characters"),
});

export default CommonValidations;
