import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeConfirmationModal } from "../../redux/Slices/CommonSlice";
import {
  CENTER,
  DEEP_OCEAN_BLUE,
  FLEX,
  ROW,
  SERVICE_PROVIDER_EDIT,
  UNSET,
} from "../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmationModal = (props) => {
  const {
    message,
    onOkHandler,
    onCancelHandler,
    cancelText,
    okText,
    phoneNo,
    reason,
    from,
  } = props;
  const open = useSelector((store) => store.common.confirmationModalOpen);
  const dispatch = useDispatch();

  const cancelHandler = () => {
    onCancelHandler();
    dispatch(closeConfirmationModal());
  };

  const okHandler = () => {
    onOkHandler();
    dispatch(closeConfirmationModal());
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={reason ? "DeleteReason__Modal" : "Confirmation__Modal"}
          width={from === SERVICE_PROVIDER_EDIT ? 600 : UNSET}
        >
          {reason && (
            <>
              <Grid container spacing={3} marginBottom={5}>
                <IconButton
                  className="DeleteReason__Modal__Close"
                  onClick={() => dispatch(closeConfirmationModal())}
                >
                  <CloseIcon />
                </IconButton>
                <Grid item md={12} marginTop={-5}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    align={CENTER}
                  >
                    Former Resident: Reason for Leaving
                  </Typography>
                </Grid>
                <Grid item md={4} display={FLEX}>
                  <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                    Phone Number
                  </Typography>
                  <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                    :
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography>{phoneNo}</Typography>
                </Grid>

                <Grid item md={4} display={FLEX}>
                  <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                    Reason
                  </Typography>
                  <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                    :
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography>{reason}</Typography>
                </Grid>
              </Grid>
            </>
          )}
          {!reason && (
            <Typography
              id="modal-modal-description"
              display={FLEX}
              justifyContent={CENTER}
            >
              {message}
            </Typography>
          )}
          <Stack
            direction={ROW}
            spacing={2}
            justifyContent={CENTER}
            marginTop={3}
          >
            <Button
              variant="outlined"
              onClick={cancelHandler}
              className="cancel__button"
            >
              {cancelText}
            </Button>
            <Button
              variant="contained"
              onClick={okHandler}
              className="submit__button"
            >
              {okText}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
