import React, { useEffect, useState } from "react";
import {
  CENTER,
  DEEP_OCEAN_BLUE,
  FLEX,
  SPACE_BETWEEN,
} from "../../utils/constants";
import {
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { fetchServiceProviderFlags } from "../../services/service-provider";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";

const ServiceProviderListFlags = () => {
  const [flags, setFlags] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(startLoading());
      const response = await fetchServiceProviderFlags(id);
      if (response.success && response.data) {
        dispatch(stopLoading());
        setFlags(response.data);
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    };
    fetchData();
  }, [id, dispatch]);

  return (
    <Paper className="paper__container" elevation={4} sx={{ minHeight: 625 }}>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "12px",
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          align={CENTER}
          marginBottom={"15px"}
          color={DEEP_OCEAN_BLUE}
          fontWeight={600}
        >
          Flagged Details
        </Typography>
        <List>
          <Grid container spacing={3}>
            <Grid item md={2} display={FLEX} justifyContent={SPACE_BETWEEN}>
              <Typography
                paddingLeft={"15px"}
                className="TableComp__table__header"
              >
                Flagged by
              </Typography>
            </Grid>
            <Grid item md={10} display={FLEX} alignItems={CENTER}>
              <Typography
                className="TableComp__table__header"
                paddingLeft={"5px"}
              >
                Reason
              </Typography>
            </Grid>
          </Grid>
          {flags?.map((flag) => {
            return (
              <>
                <ListItem sx={{ marginTop: 3, marginBottom: 3 }}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      md={2}
                      display={FLEX}
                      justifyContent={SPACE_BETWEEN}
                    >
                      <Link
                        key={flag?.id}
                        color={DEEP_OCEAN_BLUE}
                        to={`/admin/resident/view/${flag?.resident?.id}`}
                        disabled={flag?.resident?.deleted}
                      >
                        <Typography className="Flagged__By__Name">
                          {`${flag?.resident?.firstName} ${flag?.resident?.lastName} | ${flag?.resident?.apartment?.number} | ${flag?.resident?.apartment?.building?.name}`}
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item md={10} display={FLEX} alignItems={CENTER}>
                      <Typography>{flag?.reason || "-"}</Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                {flags.length > 1 && <Divider component="li" />}
              </>
            );
          })}
        </List>
      </Typography>
    </Paper>
  );
};

export default ServiceProviderListFlags;
