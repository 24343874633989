import React, { useEffect, useState, useCallback } from 'react';
import { fetchResidentReviews } from '../../services/reports';
import { useParams, useSearchParams } from "react-router-dom";
import { Rating, Paper, Grid, Typography, Stack, Breadcrumbs } from '@mui/material';
import ReportsTable from "../Common/ReportsTable";
import { DEEP_OCEAN_BLUE, BLACK } from "../../utils/constants";
import { Link } from 'react-router-dom';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const ResidentReviews = () => {
    const RatingStars = (value) => {
        return (
          <Rating
            name="rating-stars"
            value={value}
            precision={0.5} // allow half star increments
            readOnly // make the rating read-only
          />
        );
    };

    const { residentId } = useParams();
    const [searchParams] = useSearchParams();
    const [reviews, setReviews] = useState([]);

    const loadData = useCallback(async () => {
        const res = await fetchResidentReviews(residentId, {
            from: searchParams.get('from'),
            to: searchParams.get('to')
        })
        setReviews(res.data)
    }, [residentId, searchParams])

    useEffect(() => {
        loadData();
    }, [loadData])

    const breadcrumbs = [
        <Link
          key="1"
          color={DEEP_OCEAN_BLUE}
          to={"/admin/reports/resident-engagement"}
          className="breadcrumb__link"
        >
          Resident Engagement
        </Link>,
        <Typography key="3" color={BLACK}>
          {`Reviews by ${searchParams.get('resident')}`}
        </Typography>,
    ];

    const businessNameRenderer = (data) => {
        return <Link to={`/admin/service-provider/view/${data.id}`} color={DEEP_OCEAN_BLUE}  target="_blank">{data?.businessName}</Link>
    }

    return(
        <>
            <Paper className="container" elevation={4}>
                <Grid container spacing={2} paddingBottom={2}>
                    <Grid item md={12}>
                        <Typography variant='h5'>{`Reviews by ${searchParams.get('resident')}`}</Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Stack spacing={3}>
                        <Breadcrumbs
                            separator={<NavigateNextIcon />}
                            aria-label="breadcrumb"
                        >
                            {breadcrumbs}
                        </Breadcrumbs>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container>
                    <ReportsTable
                    noPagination={true}
                    headers={[
                        { label: "Review To" },
                        { label: "Review" },
                        { label: "Rating" },
                    ]}
                    data={reviews?.map((row) => {
                        return {
                        id: row?.id,
                        commonColumns: [
                            businessNameRenderer(row.serviceProvider),
                            row?.serviceProvider?.description,
                            RatingStars(row.rating || 0),
                        ],
                        data: row,
                        };
                    })}
                    />
                </Grid>
            </Paper>
        </>
    )
}

export default ResidentReviews;