import { pdf } from "@react-pdf/renderer";
import PinPdf from "../utils/templates/PinPdf";
import TermsConditionsPdf from "../utils/templates/TermsConditionsPdf";
import OnlyPinPdf from "../utils/templates/OnlyPinPdf";

export const OpenPdfViewer = async (title, content, values) => {
  const blob = await pdf(
    content?.resident && title === "Resident Verification Pin Full" ? (
      <PinPdf resident={content} />
    ) : content?.resident && title === "Resident Verification Pin Only" ? (
      <OnlyPinPdf resident={content} />
    ) : (
      <TermsConditionsPdf serviceProvider={content} language={values} />
    )
  ).toBlob();

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // Open a new tab and set up the PDF viewer
  const newTab = window.open("", "_blank");
  if (newTab) {
    newTab.document.title = title;
    newTab.document.body.style.margin = 0;

    // Create an iframe to display the PDF
    const iframe = newTab.document.createElement("iframe");
    iframe.style.width = "100%";
    iframe.style.height = "100vh";
    iframe.src = url;

    newTab.document.body.appendChild(iframe);
  }
};
