import React, { useState } from "react";
import Layout from "../../components/Layout/Layout.js";
import TabNavigator from "../../components/Common/TabNavigator.js";

const ReportsListPage = () => {
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Layout>
      <TabNavigator
        menu={[
          "Residents",
          "Service Providers",
        ]}
        selectedTab={value}
        handleTabChange={handleTabChange}
      />
    </Layout>
  );
};

export default ReportsListPage;
