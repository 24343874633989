import adminRootInstance from "../utils/config/axios-config";

export const fetchAllProduct = async (
  page,
  rowsPerPage,
  searchValue,
  category,
  resident,
  condition,
  deliveryType,
  freeListing,
  sold,
  expired,
  deleteStatus,
  building,
  apartment,
  sortColumn,
  sortBy,
  flagged
) => {
  try {
    const response = await adminRootInstance.get(
      `/product?offset=${page}&limit=${rowsPerPage}&searchValue=${searchValue}&category=${category}&resident=${resident}&condition=${condition}&deliveryType=${deliveryType}&freeListing=${freeListing}&sold=${sold}&expired=${expired}&building=${building}&apartment=${apartment}&deleteStatus=${deleteStatus}&sortColumn=${sortColumn}&sortBy=${sortBy}&flagged=${flagged}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchProduct = async (id) => {
  try {
    const response = await adminRootInstance.get(`/product/${id}`);
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const updateProduct = async (id, values) => {
  try {
    const response = await adminRootInstance.put(`/product/${id}`, values);
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data?.message,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const deleteProduct = async (id, reason) => {
  try {
    const response = await adminRootInstance.post(`/product/${id}`, {
      reason: reason,
    });
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const updateImage = async (file, id) => {
  try {
    if (file) {
      const formData = new FormData();
      const newFile = new File([file], `image.${file?.type?.split("/").pop()}`);
      formData.append("file", newFile);
      const response = await adminRootInstance.put(
        `/product/image-update/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response?.data?.success) {
        return {
          success: true,
          data: response?.data?.data,
        };
      } else {
        return {
          success: false,
          data: response?.data?.message || "Something went wrong!",
        };
      }
    } else {
      const response = await adminRootInstance.delete(
        `/product/image-delete/${id}`
      );
      if (response?.data?.success) {
        return {
          success: true,
          data: response?.data?.data,
        };
      } else {
        return {
          success: false,
          data: response?.data?.message || "Something went wrong!",
        };
      }
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const uploadImage = async (files, id) => {
  try {
    const formData = new FormData();
    files.forEach((file) => {
      const newFile = new File([file], `image.${file?.type?.split("/").pop()}`);
      formData.append("files", newFile);
    });
    const response = await adminRootInstance.post(
      `/product/image-upload/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const buySellItemFlags = async (id) => {
  try {
    const response = await adminRootInstance.get(
      `/product/buySellItemFlags/${id}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};
