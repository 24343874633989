import React from "react";
import Backdrop from "@mui/material/Backdrop";
import { useSelector } from "react-redux";
import { DEEP_OCEAN_BLUE } from "../../utils/constants";
import loader from "../../assets/images/Loader.gif";

const Loader = () => {
  const open = useSelector((store) => store.common.loading);

  return (
    <div>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        color={DEEP_OCEAN_BLUE}
        open={open}
      >
        <img src={loader} alt="" className="Nivasi__Loader" />
      </Backdrop>
    </div>
  );
};

export default Loader;
