import adminRootInstance from "../utils/config/axios-config";

export const fetchAllPreferredLanguage = async (id) => {
  try {
    const response = await adminRootInstance.get(`/auth/preferred-language`);
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};
