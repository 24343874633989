import { Box, Tab, Tabs } from "@mui/material";
import "./Common.scss";
import { DEEP_OCEAN_BLUE, INHERIT, NONE, WHITE } from "../../utils/constants";
import ReportList from "../Reports/ReportList.js";

const TabNavigator = (props) => {
  const { menu, selectedTab, handleTabChange } = props;

  return (
    <Box bgcolor={"background.paper"}>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        centered
        className="Tabs"
        sx={{
          ".MuiTabs-indicator": {
            display: NONE,
          },
          width: "50%",
        }}
      >
        {menu.map((menuText, index) => (
          <Tab
            key={index}
            label={menuText}
            sx={{
              width: "50%",
              borderRadius:
                index === 0
                  ? "10px 0px 0px 10px"
                  : index === menu.length - 1
                  ? "0px 10px 10px 0px"
                  : "",
              bgcolor: selectedTab === index ? DEEP_OCEAN_BLUE : WHITE,
              color: selectedTab === index ? WHITE : INHERIT,
              textTransform: NONE,
              fontWeight: 600,
              "&.Mui-selected": {
                bgcolor: DEEP_OCEAN_BLUE,
                color: WHITE,
              },
            }}
          />
        ))}
      </Tabs>
      <ReportList selectedTab={selectedTab} />
    </Box>
  );
};

export default TabNavigator;
