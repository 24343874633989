import List from "../../components/Resident/List.js";
import React from "react";
import Layout from "../../components/Layout/Layout.js";

const ResidentListPage = () => {
  return (
    <Layout>
      <List />
    </Layout>
  );
};

export default ResidentListPage;
