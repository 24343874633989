import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  closeDeleteReasonModal,
  startLoading,
} from "../../redux/Slices/CommonSlice";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  CENTER,
  DEEP_OCEAN_BLUE,
  REMOVE_MESSAGE,
  RESIDENT_LIST,
  ROW,
  SERVICE_PROVIDER_LIST,
  SERVICE_PROVIDER_VIEW,
  SMALL,
  SUSPEND_MESSAGE,
} from "../../utils/constants";

const DeleteReasonModal = (props) => {
  const {
    onOkHandler,
    onCancelHandler,
    cancelText,
    okText,
    from,
    isUserRequestOptionAvialable,
  } = props;
  const open = useSelector((store) => store.common.deleteReasonModalOpen);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    reason: Yup.string().required("Reason is required"),
  });

  let resetFunc = () => {};

  const cancelHandler = () => {
    onCancelHandler();
    dispatch(closeDeleteReasonModal());
    resetFunc();
  };

  return (
    <Formik
      initialValues={{
        reason: "",
        userRequested: false,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        dispatch(startLoading());
        onOkHandler(values);
        dispatch(closeDeleteReasonModal());
        resetFunc();
      }}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        touched,
        resetForm,
      }) => {
        resetFunc = resetForm;
        return (
          <>
            <Modal
              open={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className="DeleteReason__Modal">
                <Typography
                  id="modal-modal-description"
                  className="Delete__Reason__Modal"
                >
                  {from === SERVICE_PROVIDER_LIST ||
                  from === RESIDENT_LIST ||
                  from === SERVICE_PROVIDER_VIEW
                    ? SUSPEND_MESSAGE
                    : REMOVE_MESSAGE}
                </Typography>
                <form onSubmit={handleSubmit} className="form">
                  <Grid container>
                    <Grid item md={12}>
                      <TextField
                        label="Reason *"
                        fullWidth
                        size={SMALL}
                        value={values.reason || ""}
                        onChange={handleChange}
                        name="reason"
                        multiline
                        minRows={5}
                        maxRows={5}
                        onBlur={handleBlur}
                        error={touched.reason && Boolean(errors.reason)}
                        helperText={touched.reason && errors.reason}
                      />
                    </Grid>
                    {from === RESIDENT_LIST && (
                      <Grid item md={12} marginTop={11}>
                        {isUserRequestOptionAvialable && (
                          <FormControl>
                            <FormControlLabel
                              key={"userDeleted"}
                              name="userRequested"
                              control={
                                <Checkbox
                                  sx={{
                                    color: DEEP_OCEAN_BLUE,
                                  }}
                                  size={SMALL}
                                  value={values.userRequested}
                                  checked={values.userRequested}
                                  onChange={handleChange}
                                />
                              }
                              label={
                                <Typography className="label__fontsize only__available">
                                  User requested their account to be deleted
                                </Typography>
                              }
                            />
                          </FormControl>
                        )}
                      </Grid>
                    )}
                    <Grid item md={12}>
                      <Stack
                        direction={ROW}
                        spacing={2}
                        justifyContent={CENTER}
                        marginTop={from === RESIDENT_LIST ? "" : 13}
                      >
                        <Button
                          variant="outlined"
                          onClick={cancelHandler}
                          className="cancel__button"
                        >
                          {cancelText}
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          className="submit__button"
                        >
                          {okText}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Modal>
          </>
        );
      }}
    </Formik>
  );
};

export default DeleteReasonModal;
