import {
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  SvgIcon,
  Switch,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Loader from "../Common/Loader";
import { Formik } from "formik";
import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { enqueueSnackbar } from "notistack";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  fetchProduct,
  updateImage,
  updateProduct,
  uploadImage,
} from "../../services/buy-sell";
import {
  BLACK,
  BUY_SELL_EDIT,
  CENTER,
  DEEP_OCEAN_BLUE,
  FLEX,
  SMALL,
  TEXT_COLOR,
} from "../../utils/constants";
import { fetchAllResidentNames } from "../../services/resident";
import { CONDITION_FILTER } from "../../utils/constants";
import { fetchAllProductCategory } from "../../services/product-category";
import { fetchAllDeliveryType } from "../../services/delivery-type";
import moment from "moment";
import ImagePicker from "../ServiceProvider/ImagePicker";
import { theme } from "../Common/CommonStyles";
import { NumberInputStyles } from "../Common/CommonStyles";

const Edit = () => {
  const [products, setProducts] = useState({});
  const [residents, setResidents] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchResidentNames = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllResidentNames();
    if (response.success && response.data) {
      setResidents(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchResidentNames();
  }, [fetchResidentNames]);

  useEffect(() => {
    const fetchProductData = async () => {
      dispatch(startLoading());
      const response = await fetchProduct(id);
      if (response.success && response?.data) {
        while (response?.data?.images?.length < 3) {
          response?.data?.images.push({ id: null, url: null });
        }
        setProducts(response?.data);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    };
    fetchProductData();
  }, [id, dispatch]);

  const fetchProductCategory = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllProductCategory();
    if (response.success && response.data) {
      setProductCategory(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchProductCategory();
  }, [fetchProductCategory]);

  const fetchDeliveryType = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllDeliveryType();
    if (response.success && response.data) {
      setDeliveryTypes(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchDeliveryType();
  }, [fetchDeliveryType]);

  const breadcrumbs = [
    <Link
      key="1"
      color={DEEP_OCEAN_BLUE}
      to={"/admin/buy-sell/all"}
      className="breadcrumb__link"
    >
      Buy & Sell
    </Link>,
    <Typography key="3" color={BLACK}>
      Edit
    </Typography>,
  ];

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    productCategoryId: Yup.string().required("Category is required"),
    condition: Yup.string().required("Condition is required"),
    deliveryTypeId: Yup.string().required("Delivery Type is required"),
    description: Yup.string()
      .required("Description is required")
      .max(1000, "Description must be at most 1000 characters"),
    askingPrice: Yup.string().required("Asking Price is required"),
  });

  useEffect(() => {
    dispatch(startLoading());
    const preloadImages = async () => {
      const promises = products?.images
        ?.filter((image) => image.id)
        ?.map((src) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src?.url;
            img.onload = resolve;
            img.onerror = reject;
          });
        });

      try {
        await Promise.all(promises);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
      }
    };

    preloadImages();
  }, [products?.images, dispatch]);

  const indexHandler = () => {
    setImageIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));
  };

  const imageIndexHandler = () => {
    setImageIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
  };

  return (
    <Formik
      initialValues={{
        image: products?.images,
        title: products?.buySellItemCategory?.buySellItem?.title || "",
        residentId:
          products?.buySellItemCategory?.buySellItem?.resident?.id || "",
        condition: products?.buySellItemCategory?.buySellItem?.condition || "",
        productCategoryId:
          products?.buySellItemCategory?.productCategory?.id || "",
        deliveryTypeId:
          products?.buySellItemCategory?.buySellItem?.deliveryType?.id || "",
        freeListing:
          products?.buySellItemCategory?.buySellItem?.freeListing || false,
        sold: products?.buySellItemCategory?.buySellItem?.sold || false,
        description:
          products?.buySellItemCategory?.buySellItem?.description || "",
        originalPrice:
          products?.buySellItemCategory?.buySellItem?.originalPrice || "",
        askingPrice:
          products?.buySellItemCategory?.buySellItem?.askingPrice || "",
        expiryDate:
          dayjs(products?.buySellItemCategory?.buySellItem?.expiryDate) || null,
        yearOfPurchase: products?.buySellItemCategory?.buySellItem
          ?.yearOfPurchase
          ? dayjs(products?.buySellItemCategory?.buySellItem?.yearOfPurchase)
          : null,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        values.yearOfPurchase = values?.yearOfPurchase?.$y;
        values.expiryDate = moment(values?.expiryDate?.$d).format("DD-MM-YYYY");
        dispatch(startLoading());
        const result = await updateProduct(id, values);
        if (result.success) {
          let finalImages = values?.image;
          let uploadImages = finalImages
            .filter(
              (image) =>
                typeof image.url === "object" &&
                image.url !== null &&
                image.id === null
            )
            ?.map((image) => image?.url);
          if (uploadImages?.length > 0) {
            let response = await uploadImage(
              uploadImages,
              products?.buySellItemCategory?.buySellItem?.id
            );
            if (!response?.success) {
              enqueueSnackbar(response?.data, { variant: "error" });
            }
          }
          const updatePromises = finalImages
            ?.filter(
              (filteredImage) =>
                typeof filteredImage?.url !== "string" &&
                filteredImage?.id !== null
            )
            ?.map(async (image) => {
              return updateImage(image?.url, image?.id).then((response) => {
                if (!response.success) {
                  throw new Error(response?.data);
                }
                return response?.data;
              });
            });
          try {
            await Promise.all(updatePromises);
            dispatch(stopLoading());
            enqueueSnackbar(result?.data, {
              variant: "success",
            });
            navigate(`/admin/buy-sell/all`);
          } catch (error) {
            dispatch(stopLoading());
            enqueueSnackbar(error.message, { variant: "error" });
            navigate(`/admin/buy-sell/all`);
          }
        } else {
          dispatch(stopLoading());
          enqueueSnackbar(result?.data, { variant: "error" });
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldError,
        errors,
        touched,
      }) => {
        const handleFreeListing = (e) => {
          if (e.target.checked) {
            setFieldValue("askingPrice", "0");
          } else {
            setFieldValue("askingPrice", "");
          }
        };

        return (
          <Paper className="paper__container" elevation={4}>
            <form onSubmit={handleSubmit} className="form">
              <Grid container spacing={2} paddingBottom={"20px"}>
                <Grid item md={12}>
                  <Stack spacing={3}>
                    <Breadcrumbs
                      separator={<NavigateNextIcon />}
                      aria-label="breadcrumb"
                    >
                      {breadcrumbs}
                    </Breadcrumbs>
                  </Stack>
                </Grid>
                <Grid item md={3}>
                  <Paper
                    elevation={4}
                    className="BuySell__basic__details__container"
                  >
                    <div onClick={indexHandler} className="image__arrow__icon">
                      <SvgIcon>
                        <path d="M14 6l-6 6 6 6V6z" fill={DEEP_OCEAN_BLUE} />
                      </SvgIcon>
                    </div>
                    <ImagePicker
                      defaultImage={values?.image?.[imageIndex]?.url}
                      from={BUY_SELL_EDIT}
                      imageList={values?.image}
                      setFieldValue={setFieldValue}
                      imageIndex={imageIndex}
                      setImageIndex={setImageIndex}
                    />
                    <div
                      onClick={imageIndexHandler}
                      className="image__arrow__icon"
                    >
                      <SvgIcon>
                        <path d="M10 6l6 6-6 6V6z" fill={DEEP_OCEAN_BLUE} />
                      </SvgIcon>
                    </div>
                  </Paper>
                </Grid>
                <Grid item md={9}>
                  <Paper
                    className="ResidentView__basic__details__container"
                    elevation={4}
                  >
                    <Grid container spacing={4} paddingTop={"6px"}>
                      <Grid item md={6}>
                        <TextField
                          label="Title"
                          fullWidth
                          size={SMALL}
                          value={values?.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="title"
                          required
                          InputLabelProps={{
                            shrink: values.title ? true : false,
                          }}
                          error={touched.title && Boolean(errors.title)}
                          helperText={touched.title && errors.title}
                        ></TextField>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl size={SMALL} fullWidth>
                          <InputLabel id="resident-label" required>
                            Resident
                          </InputLabel>
                          <Select
                            disabled
                            labelId="resident-label"
                            label="Resident"
                            onChange={handleChange}
                            name="residentId"
                            value={values?.residentId || ""}
                          >
                            {residents?.map((resident) => (
                              <MenuItem key={resident.id} value={resident.id}>
                                {resident.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl size={SMALL} fullWidth>
                          <InputLabel id="category-label" required>
                            Category
                          </InputLabel>
                          <Select
                            labelId="category-label"
                            label="Category"
                            onChange={handleChange}
                            name="productCategoryId"
                            value={values?.productCategoryId}
                            error={
                              touched.productCategoryId &&
                              Boolean(errors.productCategoryId)
                            }
                            helperText={
                              touched.productCategoryId &&
                              errors.productCategoryId
                            }
                          >
                            {productCategory?.map((category) => (
                              <MenuItem key={category.id} value={category.id}>
                                {category.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl size={SMALL} fullWidth>
                          <InputLabel id="condition-label" required>
                            Condition
                          </InputLabel>
                          <Select
                            labelId="condition-label"
                            label="Condition"
                            onChange={handleChange}
                            name="condition"
                            value={values?.condition || ""}
                            error={
                              touched.condition && Boolean(errors.condition)
                            }
                            helperText={touched.condition && errors.condition}
                          >
                            {CONDITION_FILTER?.map((condition) => (
                              <MenuItem key={condition} value={condition}>
                                {condition}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl size={SMALL} fullWidth>
                          <InputLabel id="deliveryType-label" required>
                            Delivery Type
                          </InputLabel>
                          <Select
                            labelId="deliveryType-label"
                            label="Delivery Type"
                            onChange={handleChange}
                            name="deliveryTypeId"
                            value={values?.deliveryTypeId || ""}
                            error={
                              touched.deliveryTypeId &&
                              Boolean(errors.deliveryTypeId)
                            }
                            helperText={
                              touched.deliveryTypeId && errors.deliveryTypeId
                            }
                          >
                            {deliveryTypes?.map((deliveryType) => (
                              <MenuItem
                                key={deliveryType.id}
                                value={deliveryType.id}
                              >
                                {deliveryType.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6} display={FLEX} alignItems={CENTER}>
                        <FormControl
                          size={SMALL}
                          fullWidth
                          className="ServiceProviderCreate__switch__container"
                        >
                          <Typography
                            color={TEXT_COLOR}
                            fontSize="1rem"
                            fontWeight="400"
                            paddingTop={"8px"}
                          >
                            Free
                          </Typography>
                          <ThemeProvider theme={theme}>
                            <Switch
                              name="freeListing"
                              checked={values?.freeListing}
                              onChange={(e) => {
                                handleChange(e);
                                handleFreeListing(e);
                              }}
                            />
                          </ThemeProvider>
                        </FormControl>
                        <FormControl
                          size={SMALL}
                          fullWidth
                          className="ServiceProviderCreate__switch__container"
                        >
                          <Typography
                            color={TEXT_COLOR}
                            fontSize="1rem"
                            fontWeight="400"
                            paddingTop={"8px"}
                          >
                            Sold
                          </Typography>
                          <ThemeProvider theme={theme}>
                            <Switch
                              name="sold"
                              checked={values?.sold}
                              onChange={handleChange}
                            />
                          </ThemeProvider>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <div className="ResidentView__section__divider">
                <span className="ResidentView__section__divider__span">
                  Other Information
                </span>
              </div>
              <Grid container spacing={2}>
                <Grid item md={12} marginTop={"40px"} marginBottom={"20px"}>
                  <TextField
                    required
                    label="Description"
                    multiline
                    fullWidth
                    maxRows={3}
                    minRows={3}
                    size={SMALL}
                    value={values?.description}
                    onChange={handleChange}
                    name="description"
                    onBlur={handleBlur}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  ></TextField>
                </Grid>
                <Grid item md={3} marginTop={"20px"}>
                  <TextField
                    type="number"
                    label="Original Price"
                    fullWidth
                    size={SMALL}
                    sx={NumberInputStyles}
                    value={values?.originalPrice}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="originalPrice"
                    InputLabelProps={{
                      shrink: values.originalPrice ? true : false,
                    }}
                    error={
                      touched.originalPrice && Boolean(errors.originalPrice)
                    }
                    helperText={touched.originalPrice && errors.originalPrice}
                  ></TextField>
                </Grid>
                <Grid item md={3} marginTop={"20px"}>
                  <TextField
                    type="number"
                    disabled={values?.freeListing ? true : false}
                    sx={NumberInputStyles}
                    required={values.freeListing ? false : true}
                    label={"Asking Price"}
                    fullWidth
                    size={SMALL}
                    value={values?.askingPrice}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="askingPrice"
                    InputLabelProps={{
                      shrink: values.askingPrice ? true : false,
                    }}
                    error={touched.askingPrice && Boolean(errors.askingPrice)}
                    helperText={touched.askingPrice && errors.askingPrice}
                  ></TextField>
                </Grid>
                <Grid item md={3} marginTop={"12px"}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        disabled
                        sx={{
                          "& .MuiInputBase-input": {
                            paddingTop: "9px",
                            paddingBottom: "9px",
                          },
                        }}
                        label="Expiry Date"
                        name="expiryDate"
                        value={values?.expiryDate}
                        onChange={(newDate) => {
                          setFieldValue("expiryDate", dayjs(newDate));
                        }}
                        minDate={dayjs()}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item md={3} marginTop={"20px"}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{
                        "& .MuiInputBase-input": {
                          paddingTop: "9px",
                          paddingBottom: "9px",
                        },
                      }}
                      views={["year"]}
                      label={<span>Year of Purchase</span>}
                      name="yearOfPurchase"
                      value={values?.yearOfPurchase}
                      onChange={(newYear) => {
                        setFieldValue("yearOfPurchase", newYear);
                      }}
                      maxDate={dayjs()}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  md={12}
                  display={FLEX}
                  justifyContent={CENTER}
                  marginTop={"20px"}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    className="submit__button"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              <Loader />
            </form>
          </Paper>
        );
      }}
    </Formik>
  );
};

export default Edit;
