import dayjs from "dayjs";
import moment from "moment";

export const formatDate = (dateString) => {
  const parsedDate = moment(dateString, "DD-MM-YYYY");
  const formattedDate = parsedDate.format();
  return formattedDate;
};

export const fromDateHandler = (newFromDate, setFilterValues) => {
  const updatedFromDate = moment(dayjs(newFromDate).$d).format("DD-MM-YYYY");
  setFilterValues((prevState) => ({
    ...prevState,
    from: updatedFromDate,
  }));
};

export const toDateHandler = (newToDate, setFilterValues) => {
  const updatedToDate = moment(dayjs(newToDate).$d).format("DD-MM-YYYY");
  setFilterValues((prevState) => ({
    ...prevState,
    to: updatedToDate,
  }));
};
