import { createSlice } from "@reduxjs/toolkit";

const CommonSlice = createSlice({
  name: "commonSlice",
  initialState: {
    loading: false,
    confirmationModalOpen: false,
    deleteReasonModalOpen: false,
    selectLanguageModalOpen: false,
  },
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    openConfirmationModal: (state) => {
      state.confirmationModalOpen = true;
    },
    closeConfirmationModal: (state) => {
      state.confirmationModalOpen = false;
    },
    openDeleteReasonModal: (state) => {
      state.deleteReasonModalOpen = true;
    },
    closeDeleteReasonModal: (state) => {
      state.deleteReasonModalOpen = false;
    },
    openSelectLanguageModal: (state) => {
      state.selectLanguageModalOpen = true;
    },
    closeSelectLanguageModal: (state) => {
      state.selectLanguageModalOpen = false;
    },
  },
});

export const {
  startLoading,
  stopLoading,
  openConfirmationModal,
  closeConfirmationModal,
  openDeleteReasonModal,
  closeDeleteReasonModal,
  openSelectLanguageModal,
  closeSelectLanguageModal,
} = CommonSlice.actions;
export default CommonSlice.reducer;
