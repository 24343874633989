import adminRootInstance from "../utils/config/axios-config";

export const fetchServiceProviderView = async (
  page,
  rowsPerPage,
  searchValue,
  from,
  to
) => {
  try {
    const response = await adminRootInstance.get(
      `/reports/service-provider-view?offset=${page}&limit=${rowsPerPage}&searchValue=${searchValue}&from=${from}&to=${to}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchServiceProviderClick = async (
  page,
  rowsPerPage,
  searchValue,
  from,
  to
) => {
  try {
    const response = await adminRootInstance.get(
      `/reports/service-provider-click?offset=${page}&limit=${rowsPerPage}&searchValue=${searchValue}&from=${from}&to=${to}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchServiceProviderReport = async (
  page,
  rowsPerPage,
  searchValue,
  from,
  to,
  category,
  resident,
  sortColumn,
  sortBy
) => {
  try {
    const response = await adminRootInstance.get(
      `/reports/service-provider?offset=${page}&limit=${rowsPerPage}&searchValue=${searchValue}&from=${from}&to=${to}&category=${category}&resident=${resident}&sortColumn=${sortColumn}&sortBy=${sortBy}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchResidentReport = async (
  page,
  rowsPerPage,
  searchValue,
  from,
  to,
  building,
  sortColumn,
  sortBy
) => {
  try {
    const response = await adminRootInstance.get(
      `/reports/resident?offset=${page}&limit=${rowsPerPage}&searchValue=${searchValue}&from=${from}&to=${to}&building=${building}&sortColumn=${sortColumn}&sortBy=${sortBy}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchProductReport = async (
  page,
  rowsPerPage,
  from,
  to,
  building,
  apartment,
  resident,
  sortColumn,
  sortBy
) => {
  try {
    const response = await adminRootInstance.get(
      `/reports/product?offset=${page}&limit=${rowsPerPage}&from=${from}&to=${to}&building=${building}&apartment=${apartment}&resident=${resident}&sortColumn=${sortColumn}&sortBy=${sortBy}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchProductListingReport = async (
  id,
  from,
  to,
  searchValue,
  page,
  rowsPerPage,
  sortColumn,
  sortBy
) => {
  try {
    const response = await adminRootInstance.get(
      `/reports/product/listings/${id}?from=${from}&to=${to}&searchValue=${searchValue}&offset=${page}&limit=${rowsPerPage}&sortColumn=${sortColumn}&sortBy=${sortBy}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchWordCloudReport = async (searchValue, from, to) => {
  try {
    const response = await adminRootInstance.get(
      `/reports/search-term/all?searchValue=${searchValue}&from=${from}&to=${to}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchResidentReviews = async (residentId, params) => {
  try {
    const response = await adminRootInstance.get(
      `/reports/resident/reviews/${residentId}`,
      { params }
    );
    if (response?.status && response?.data) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchServiceProviderReviewReport = async (id, from, to) => {
  try {
    const response = await adminRootInstance.get(
      `/reports/service-provider-review/${id}?from=${from}&to=${to}`
    );

    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchResidentReferrals = async (residentId, params) => {
  try {
    const response = await adminRootInstance.get(
      `/reports/resident/referrals/${residentId}`,
      { params }
    );

    if (response?.status && response?.data) {
      return response.data;
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchServiceProviderFlagReport = async (id, from, to) => {
  try {
    const response = await adminRootInstance.get(
      `/reports/service-provider-flag/${id}?from=${from}&to=${to}`
    );

    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};
