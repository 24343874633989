import React from "react";
import Layout from "../../components/Layout/Layout";
import ServiceProviderFlags from "../../components/Reports/ServiceProviderFlags";

const ServiceProviderFlagsReportPage = () => {
  return (
    <Layout>
      <ServiceProviderFlags />
    </Layout>
  );
};

export default ServiceProviderFlagsReportPage;
