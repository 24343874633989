import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { fetchAllServiceCategory } from "../../services/service-category";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { enqueueSnackbar } from "notistack";
import Loader from "../Common/Loader";
import { createServiceProvider } from "../../services/createServiceProvider";
import { CENTER, FLEX, SMALL } from "../../utils/constants";

const Create = () => {
  const dispatch = useDispatch();
  const [serviceCategories, setServiceCategories] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const fetchServiceCategories = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllServiceCategory();
    if (response.success && response.data) {
      setServiceCategories(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchServiceCategories();
  }, [fetchServiceCategories]);

  const validationSchema = Yup.object({
    businessName: Yup.string()
      .required("Business name is required")
      .max(30, "Business name must be at most 30 characters"),
    contactName: Yup.string().required("Contact Name is required"),
    businessEmail: Yup.string()
      .required("Email is required")
      .email("Invalid email format")
      .max(50, "Email must be at most 50 characters"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
    serviceCategory: Yup.string().required("Service category is required"),
    aboutTheBusiness: Yup.string()
      .required("About the Business is required")
      .max(1000, "Description must be at most 1000 characters"),
    description: Yup.string()
      .required("Description is required")
      .max(1000, "Description must be at most 1000 characters"),
  });

  return (
    <Formik
      initialValues={{
        businessName: "",
        contactName: "",
        businessEmail: "",
        phone: "",
        serviceCategory: "",
        aboutTheBusiness: "",
        description: "",
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        dispatch(startLoading());
        const result = await createServiceProvider(values);
        if (result.success) {
          setIsSuccess(true);
          dispatch(stopLoading());
          enqueueSnackbar(result?.data, { variant: "success" });
        } else {
          dispatch(stopLoading());
          enqueueSnackbar(result?.data, { variant: "error" });
        }
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
      }) => {
        return !isSuccess ? (
          <Paper className="paper__container business__request" elevation={4}>
            <Typography>
              <h1 className="service__provider__form__heading">
                Create Service Provider
              </h1>
            </Typography>
            <form onSubmit={handleSubmit} className="form">
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <TextField
                    label="Business Name *"
                    fullWidth
                    size={SMALL}
                    value={values?.businessName}
                    onChange={handleChange}
                    name="businessName"
                    onBlur={handleBlur}
                    error={touched.businessName && Boolean(errors.businessName)}
                    helperText={touched.businessName && errors.businessName}
                  ></TextField>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    label="Contact Name *"
                    fullWidth
                    size={SMALL}
                    value={values?.contactName}
                    onChange={handleChange}
                    name="contactName"
                    onBlur={handleBlur}
                    error={touched.contactName && Boolean(errors.contactName)}
                    helperText={touched.contactName && errors.contactName}
                  ></TextField>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    label="Email *"
                    fullWidth
                    size={SMALL}
                    value={values?.businessEmail}
                    onChange={handleChange}
                    name="businessEmail"
                    onBlur={handleBlur}
                    error={
                      touched.businessEmail && Boolean(errors.businessEmail)
                    }
                    helperText={touched.businessEmail && errors.businessEmail}
                  ></TextField>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    label="Phone *"
                    fullWidth
                    size={SMALL}
                    value={values?.phone}
                    onChange={handleChange}
                    name="phone"
                    onBlur={handleBlur}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                  ></TextField>
                </Grid>
                <Grid item md={6}>
                  <FormControl
                    size={SMALL}
                    fullWidth
                    error={
                      touched.serviceCategory && Boolean(errors.serviceCategory)
                    }
                  >
                    <InputLabel id="service-category-label" required>
                      Service Category
                    </InputLabel>
                    <Select
                      labelId="service-category-label"
                      label="Service Category"
                      value={values?.serviceCategory || ""}
                      onChange={handleChange}
                      name="serviceCategory"
                      onBlur={handleBlur}
                    >
                      {serviceCategories.map((serviceCategory) => (
                        <MenuItem
                          key={serviceCategory?.id}
                          value={serviceCategory?.name}
                        >
                          {serviceCategory?.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.serviceCategory && errors.serviceCategory && (
                      <FormHelperText>{errors.serviceCategory}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    label="About the Business *"
                    fullWidth
                    size={SMALL}
                    value={values?.aboutTheBusiness}
                    onChange={handleChange}
                    name="aboutTheBusiness"
                    onBlur={handleBlur}
                    error={
                      touched.aboutTheBusiness &&
                      Boolean(errors.aboutTheBusiness)
                    }
                    helperText={
                      touched.aboutTheBusiness && errors.aboutTheBusiness
                    }
                  ></TextField>
                </Grid>
                <Grid item md={12} marginBottom={10}>
                  <TextField
                    label="Description *"
                    fullWidth
                    size={SMALL}
                    value={values.description || ""}
                    onChange={handleChange}
                    name="description"
                    multiline
                    minRows={5}
                    maxRows={5}
                    onBlur={handleBlur}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  ></TextField>
                </Grid>
                <Grid
                  item
                  md={12}
                  display={FLEX}
                  justifyContent={CENTER}
                  marginTop={"30px"}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    className="submit__button"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              <Loader />
            </form>
          </Paper>
        ) : (
          <div className="form__success__message__container">
            <h2 className="form__success__message">
              Thanks for reaching out. You will be contacted shortly.
            </h2>
          </div>
        );
      }}
    </Formik>
  );
};

export default Create;
