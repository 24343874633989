import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Slider,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Cropper from "react-easy-crop";
import { useCallback, useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import {
  AUTO,
  BUY_SELL_EDIT,
  BUY_SELL_VIEW,
  DEEP_OCEAN_BLUE,
  FLEX,
  IMAGE_PICKER_BORDER_COLOR,
  INHERIT,
  NONE,
  RED,
  RESIDENT,
  SERVICE_PROVIDER_CREATE,
  SERVICE_PROVIDER_EDIT,
  SOLID,
  WHITE,
} from "../../utils/constants";
import { useSelector } from "react-redux";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { imageCropContainerStyle } from "../Common/CommonStyles";

const ImagePicker = (props) => {
  const {
    setFieldValue,
    defaultImage,
    from,
    imageIndex,
    imageList,
    errors,
    touched,
  } = props;
  const isLoading = useSelector((store) => store.common.loading);

  const [preview, setPreview] = useState("");
  const [open, setOpen] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [cropperOpen, setCropperOpen] = useState(false);
  const [fileType, setfileType] = useState("");

  useEffect(() => {
    if (defaultImage) {
      const image =
        typeof defaultImage === "string"
          ? defaultImage
          : URL.createObjectURL(defaultImage);
      setPreview(image);
    } else {
      setPreview("");
    }
  }, [defaultImage]);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setfileType(e.target.files[0]?.type);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImageSrc(reader.result);
        setCropperOpen(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = async (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = useCallback(
    async (
      imageSrc,
      pixelCrop,
      resizeWidth = 0,
      resizeHeight = 0,
      quality = 1,
      rotation = 0,
      flip = { horizontal: false, vertical: false }
    ) => {
      const image = await createImage(imageSrc);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = resizeWidth || pixelCrop.width;
      canvas.height = resizeHeight || pixelCrop.height;
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        canvas.width,
        canvas.height
      );
      return new Promise((resolve, reject) => {
        canvas.toBlob(
          (file) => {
            resolve(URL.createObjectURL(file));
          },
          fileType,
          quality
        );
      });
    },
    [fileType]
  );

  const saveCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      setPreview(croppedImage);
      let myBlob = await fetch(croppedImage)
        .then((res) => res.blob())
        .then((myBlob) => {
          return myBlob;
        });
      const myFile = new File([myBlob], `image.${fileType}`, {
        type: fileType,
      });
      if (from === BUY_SELL_EDIT) {
        imageList[imageIndex].url = myFile;
        setFieldValue("image", imageList);
      } else {
        setFieldValue("image", myFile);
      }
      setCropperOpen(false);
      setImageSrc("");
      setCrop({ x: 0, y: 0 });
      setZoom(1);
    } catch (error) {
      enqueueSnackbar(error?.message || "Error occured while cropping image", {
        variant: "error",
      });
    }
  }, [
    croppedAreaPixels,
    getCroppedImg,
    imageSrc,
    setFieldValue,
    fileType,
    from,
    imageIndex,
    imageList,
  ]);

  const handleDelete = () => {
    if (from === BUY_SELL_EDIT) {
      imageList[imageIndex].url = null;
      setFieldValue("image", imageList);
    } else {
      setPreview("");
      setFieldValue("image", null);
    }
  };

  const handleView = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleCloseCropper = (reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setCropperOpen(false);
      setImageSrc("");
      setCrop({ x: 0, y: 0 });
      setZoom(1);
    }
  };

  const cancelHandler = () => {
    setCropperOpen(false);
    setImageSrc("");
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  return (
    <>
      <Box
        sx={{
          "&:hover": { border: `1px ${SOLID} ${DEEP_OCEAN_BLUE}` },
          "&:hover .iconContainer": preview ? { display: FLEX } : {},
          padding: preview ? "6px" : "0px",
          border:
            (from === SERVICE_PROVIDER_CREATE ||
              from === SERVICE_PROVIDER_EDIT) &&
            touched.image &&
            errors.image
              ? `1px ${SOLID} ${RED}`
              : `1px ${SOLID} ${IMAGE_PICKER_BORDER_COLOR}`,
          position: "relative",
        }}
        height={
          from === RESIDENT || from === BUY_SELL_EDIT || from === BUY_SELL_VIEW
            ? "100% !important"
            : AUTO
        }
        className="ServiceProviderCreate__image__picker__container"
      >
        {!preview && !isLoading && (
          <>
            {from !== BUY_SELL_VIEW && (
              <>
                <input
                  accept="image/*"
                  id="image-upload"
                  type="file"
                  onChange={handleImageChange}
                />
              </>
            )}

            {from !== BUY_SELL_VIEW && (
              <label htmlFor="image-upload">
                <Button
                  component="span"
                  sx={{
                    height:
                      from === BUY_SELL_EDIT
                        ? "31vh !important"
                        : from === RESIDENT
                        ? "37vh !important"
                        : "24vh !important",
                  }}
                  className="ServiceProviderCreate__image__picker__button"
                >
                  <div>
                    <AddPhotoAlternateIcon className="ServiceProviderCreate__image__picker__add__photo__icon" />
                    <Typography
                      color={
                        (from === SERVICE_PROVIDER_CREATE ||
                          from === SERVICE_PROVIDER_EDIT) &&
                        touched.image &&
                        errors.image &&
                        "red"
                      }
                      fontSize="1rem"
                      textTransform={NONE}
                    >
                      Upload Image{" "}
                      <span className={"custom-label-span"}> *</span>
                    </Typography>
                  </div>
                </Button>
              </label>
            )}

            {from === BUY_SELL_VIEW && (
              <div
                className="ServiceProviderCreate__image__picker__button"
                style={{
                  pointerEvents: NONE,
                  color: INHERIT,
                }}
              >
                <div>
                  <ImageNotSupportedIcon className="ServiceProviderCreate__image__picker__add__photo__icon" />
                  <Typography fontSize="1rem" textTransform={NONE}>
                    Image Not Uploaded
                  </Typography>
                </div>
              </div>
            )}

            {imageSrc && (
              <Modal
                open={cropperOpen}
                onClose={(_, reason) => {
                  handleCloseCropper(reason);
                }}
                className="ServiceProviderCreate__image__cropper__modal"
              >
                <div className="ServiceProviderCreate__image__cropper__modal__body">
                  <Box sx={imageCropContainerStyle}>
                    <Box>
                      <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      />
                    </Box>
                    <Box height={"auto !important"}>
                      <Slider
                        className="ServiceProviderCreate__image__cropper__slider"
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => setZoom(zoom)}
                      />

                      <Button
                        onClick={cancelHandler}
                        variant="contained"
                        sx={{
                          margin: "10px",
                        }}
                        className="submit__button"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={saveCroppedImage}
                        variant="contained"
                        color="primary"
                        className="submit__button"
                        sx={{
                          margin: "10px",
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </div>
              </Modal>
            )}
          </>
        )}
        {preview && (
          <>
            <img
              src={preview}
              alt="Preview"
              className="ServiceProviderCreate__image__preview"
            />
            <Box className="iconContainer ServiceProviderCreate__image__preview__icon__container">
              <IconButton color="primary" onClick={handleView}>
                <VisibilityIcon className="icon__color" />
              </IconButton>
              {from !== BUY_SELL_VIEW && (
                <IconButton className="icon__color" onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          </>
        )}
        <Grid style={{ position: "absolute", bottom: 10, display: FLEX }}>
          {imageList?.map((_, index) => (
            <li
              role="button"
              style={{
                display: "inline-block",
                margin: "0 8px",
                width: 10,
                height: 10,
                borderRadius: "50%",
                border: `2px solid ${DEEP_OCEAN_BLUE}`,
                backgroundColor: index === imageIndex ? DEEP_OCEAN_BLUE : WHITE,
              }}
            ></li>
          ))}
        </Grid>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <img
          src={preview}
          alt="Full Size"
          className="ServiceProviderCreate__image__preview__image"
        />
      </Modal>
    </>
  );
};

export default ImagePicker;
