import { createTheme } from "@mui/material";
import {
  AUTO,
  CENTER,
  DEEP_OCEAN_BLUE,
  FLEX,
  NONE,
  ROW,
} from "../../utils/constants";

export const NumberInputStyles = {
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
    {
      WebkitAppearance: NONE,
      margin: 0,
    },
};

export const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: "#758F05",
        },
        colorPrimary: {
          "&.Mui-checked": {
            color: "#DF3836",
          },
        },
        track: {
          opacity: 0.7,
          backgroundColor: "lightgrey",
          ".Mui-checked.Mui-checked + &": {
            opacity: 0.7,
            backgroundColor: "lightgrey",
          },
        },
      },
    },
  },
});

export const locationStyles = {
  cursor: "not-allowed",
  "& .MuiOutlinedInput-root": {
    pointerEvents: NONE,
    cursor: "not-allowed",
    "&:hover": {
      cursor: "not-allowed",
    },
    "& fieldset": {
      cursor: "not-allowed",
    },
  },
  "& .MuiInputLabel-root": {
    pointerEvents: NONE,
    cursor: "not-allowed",
  },
  "& .MuiInputBase-input": {
    cursor: "not-allowed",
    "&:hover": {
      cursor: "not-allowed",
    },
  },
};

export const imageCropContainerStyle = {
  marginLeft: AUTO,
  marginRight: AUTO,
  width: "550px",
  height: "550px",
  p: 0,
  display: FLEX,
  flexWrap: "wrap",
  flexDirection: ROW,
  " .reactEasyCrop_Container": {
    marginLeft: AUTO,
    marginRight: AUTO,
    width: "550px",
    height: "425px",
    border: `2px solid ${DEEP_OCEAN_BLUE}`,
    boxShadow: 24,
  },
  " .MuiBox-root": {
    width: "100%",
    height: "450px",
    position: "relative",
    textAlign: CENTER,
  },
};

export const reportsDatePickerStyle = {
  width: "287px",
  "& .MuiInputBase-input": { padding: "9px 14px" },
};
