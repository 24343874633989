import React from "react";
import Layout from "../../components/Layout/Layout.js";
import ServiceProviderEngagement from "../../components/Reports/ServiceProviderEngagement.js";

const ServiceProviderEngagementPage = () => {
    return (
        <Layout>
            <ServiceProviderEngagement />
        </Layout>
    );
};

export default ServiceProviderEngagementPage;
