import Layout from "../../components/Layout/Layout";
import View from "../../components/BuySell/View";

const BuySellViewPage = () => {
  return (
    <Layout>
      <View />
    </Layout>
  );
};

export default BuySellViewPage;
