import { Box, Button, Typography, IconButton, Grid, Tooltip } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DEEP_OCEAN_BLUE,
  FLEX,
  IMAGE_PICKER_BORDER_COLOR,
  NONE,
  SOLID,
} from "../../utils/constants";
import { useEffect, useState } from "react";

const UploadPdf = (props) => {
  const { setFieldValue, defaultImage, from, homeBased } = props;
  const [fileName, setFileName] = useState(
    defaultImage ? defaultImage.name : null
  );
  const [fileURL, setFileURL] = useState(null);

  useEffect(() => {
    if (defaultImage) {
      const url = URL.createObjectURL(defaultImage);
      setFileURL(url);
      setFileName(defaultImage.name);
      return () => {
        URL.revokeObjectURL(url); // Clean up when the component unmounts or defaultImage changes
      };
    }
  }, [defaultImage]);

  useEffect(() => {
    return () => {
      if (fileURL) {
        URL.revokeObjectURL(fileURL);
      }
    };
  }, [fileURL]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setFileName(file.name);
      setFileURL(url);

      // Set the correct field based on the 'from' prop
      if (from === "Terms") {
        setFieldValue("termsPdf", file);
      } else if (from === "Approval") {
        setFieldValue("approvalPdf", file);
      }
    }
  };

  const handleDelete = () => {
    setFileName(null);
    if (fileURL) {
      URL.revokeObjectURL(fileURL);
    }
    setFileURL(null);

    // Clear the correct field based on the 'from' prop
    if (from === "Terms") {
      setFieldValue("termsPdf", null);
      setFieldValue("deleteTnc", true);
    } else if (from === "Approval") {
      setFieldValue("approvalPdf", null);
      setFieldValue("deleteHomeApproval", true);
    }
  };

  const handlePreview = () => {
    if (fileURL) {
      window.open(fileURL, "_blank");
    }
  };

  return (
    <>
      <Typography>
        {from === "Terms" ? "Terms & Conditions" : "Approval Document"}
      </Typography>
      {!fileName ? (
        <Box
          sx={{
            "&:hover": { border: `1px ${SOLID} ${DEEP_OCEAN_BLUE}` },
            border: `1px ${SOLID} ${IMAGE_PICKER_BORDER_COLOR}`,
            position: "relative",
          }}
          height="100% !important"
          className="ServiceProviderCreate__image__picker__container"
        >
          <>
            <input
              disabled={!homeBased}
              accept="application/pdf"
              id={`pdf-upload-${from.toLowerCase()}`}
              type="file"
              onChange={handleImageChange}
            />
            <label htmlFor={`pdf-upload-${from.toLowerCase()}`}>
              <Button
                component="span"
                className="ServiceProviderCreate__image__picker__button"
              >
                <div className={!homeBased && "upload__input"}>
                  <AddPhotoAlternateIcon className="ServiceProviderCreate__image__picker__add__photo__icon" />
                  <Typography fontSize="1rem" textTransform={NONE}>
                    Upload PDF
                  </Typography>
                </div>
              </Button>
            </label>
          </>
        </Box>
      ) : (
        <Box className="Upload__pdf__filename">
            <Tooltip title={fileName} arrow>
              <Typography
                variant="body1"
                sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flexGrow: 1 }}
              >
                {fileName}
              </Typography>
            </Tooltip>
          <Grid display={FLEX}>
            <IconButton onClick={handlePreview} size="small">
              <VisibilityIcon />
            </IconButton>
            <IconButton onClick={handleDelete} size="small">
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default UploadPdf;
