import React from "react";
import Layout from "../../components/Layout/Layout.js";
import WordCloud from "../../components/Reports/WordCloud.js";

const WordCloudPage = () => {
  return (
    <Layout>
      <WordCloud />
    </Layout>
  );
};

export default WordCloudPage;
