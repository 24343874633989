import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Loader from "../Common/Loader";
import { Link, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  AUTO,
  BLACK,
  BOLD,
  CENTER,
  COLUMN,
  DEEP_OCEAN_BLUE,
  FLEX,
  FLEX_END,
  FLEX_START,
  NAME_PREFIX_TYPES,
  RED,
  SERVICE_LOCATION,
  SERVICE_PROVIDER_CREATE,
  SMALL,
  SPACE_BETWEEN,
  TEXT_COLOR,
} from "../../utils/constants";
import { Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { fetchAllServiceCategory } from "../../services/service-category";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import ImagePicker from "./ImagePicker";
import { fetchAllPreferredLanguage } from "../../services/preferred-language";
import { fetchAllResidentNames } from "../../services/resident";
import "./ServiceProvider.scss";
import "../Common/Common.scss";
import {
  addServiceProvider,
  fetchByBusinessName,
  fetchByPhoneNumber,
  uploadImage,
  fetchCountryCodes,
} from "../../services/service-provider";
import PhoneAutocomplete from "./PhoneAutocomplete";
import BusinessNameAutocomplete from "./BusinessNameAutocomplete";
import avatar from "../../assets/images/avatar.png";
import {  theme } from "../Common/CommonStyles";
import Validator from "./Validator";

const Create = (props) => {
  const breadcrumbs = [
    <Link
      key="1"
      color={DEEP_OCEAN_BLUE}
      to={"/admin/service-provider/all"}
      className="breadcrumb__link"
    >
      Service Provider
    </Link>,
    <Typography key="3" color={BLACK}>
      Add
    </Typography>,
  ];

  const dispatch = useDispatch();
  const [serviceCategories, setServiceCategories] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [preferredLanguage, setPreferredLanguage] = useState([]);
  const [residents, setResidents] = useState([]);
  const navigate = useNavigate();
  const [phoneData, setPhoneData] = useState([]);
  const [selectedPhoneData, setSelectedPhoneData] = useState(null);
  const [businessNameData, setBusinessNameData] = useState([]);
  const [selectedBusinessNameData, setSelectedBusinessNameData] =
    useState(null);
  const [queryParams, setQueryParams] = useState({});
  const [phoneError, setPhoneError] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState(`+91`);

  let setFieldValueFunc = () => { };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of searchParams) {
      params[key] = value;
    }
    setQueryParams(params);

    const handlePhoneError = async (value) => {
      if (value) {
        const response = await fetchByPhoneNumber(value);
        if (response.success && response.data) {
          setPhoneError(true);
        }
      }
    };
    handlePhoneError(params?.phone);
  }, [residents]);

  const fetchServiceCategories = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllServiceCategory();
    if (response.success && response.data) {
      setServiceCategories(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  const fetchAllCountryCodes = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchCountryCodes();
    if (response.success && response.data) {
      setCountryCodes(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  const fetchPreferredLanguage = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllPreferredLanguage();
    if (response.success && response.data) {
      setPreferredLanguage(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  const fetchResidentNames = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllResidentNames();
    if (response.success && response.data) {
      setResidents(response?.data);
      const searchParams = new URLSearchParams(window.location.search);
      const params = {};
      for (const [key, value] of searchParams) {
        params[key] = value;
      }
      if (params?.referredBy) {
        const resident = response?.data.find(
          (resident) => resident.id === +params?.referredBy
        );
        setFieldValueFunc("referredBy", resident);
      }
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchServiceCategories();
  }, [fetchServiceCategories]);

  useEffect(() => {
    fetchPreferredLanguage();
  }, [fetchPreferredLanguage]);

  useEffect(() => {
    fetchResidentNames();
  }, [fetchResidentNames]);

  useEffect(() => {
    fetchAllCountryCodes();
  }, [fetchAllCountryCodes]);

  return (
    <Formik
      initialValues={{
        namePrefix: "",
        firstName: queryParams?.firstName || "",
        lastName: queryParams?.lastName || "",
        phone: queryParams?.phone || "",
        countryCode: queryParams.countryCode || "",
        email: queryParams?.email || "",
        title: queryParams?.title || "",
        businessName: queryParams?.businessName || "",
        serviceCategory: queryParams?.serviceCategory || [],
        image: "",
        preferredLanguage: "",
        location: "",
        servicedTo: [],
        deliveryStatus: false,
        residentProvider: false,
        homeBased: false,
        address: "",
        description: queryParams?.description || "",
        referredBy: queryParams?.referredBy || [],
        referAnonymously: false,
        referrerAvailedService: false,
        serviceLocation: "On-site",
        latitude: "",
        longitude: "",
        locationUrl: "",
      }}
      enableReinitialize
      validationSchema={Validator(phoneData, phoneError)}
      onSubmit={async (values) => {
        const serviceLocationValues = values.serviceLocation.toUpperCase();
        if (serviceLocationValues === "ON-SITE") {
          values.serviceLocation = "ONSITE";
        } else if (serviceLocationValues === "AT HOME") {
          values.serviceLocation = "AT_HOME";
        } else {
          values.serviceLocation = serviceLocationValues;
        }

        values.doorDelivery =
          values.serviceLocation === "ONSITE"
            ? false
            : !values.deliveryStatus
              ? true
              : false;
        values.homeVisit =
          values.serviceLocation === "ONSITE"
            ? false
            : values.deliveryStatus
              ? true
              : false;
        const location = {
          formattedAddress: values.address,
          locationUrl: values.locationUrl,
          longitude: values.longitude,
          latitude: values.latitude,
          locality: values.address,
        };
        values.location = location;

        dispatch(startLoading());
        if (!values?.countryCode) {
          values = { ...values, countryCode: selectedCountryCode };
        }
        const result = await addServiceProvider(values);
        if (result.success) {
          if (values?.image) {
            const response = await uploadImage(
              values.image,
              result?.data?.data?.id
            );
            if (response.success) {
              dispatch(stopLoading());
              enqueueSnackbar(result?.data?.message, {
                variant: "success",
              });
              navigate(`/admin/service-provider/all`);
            } else {
              enqueueSnackbar(response?.data, { variant: "error" });
              navigate(`/admin/service-provider/all`);
            }
          } else {
            dispatch(stopLoading());
            enqueueSnackbar(result?.data?.message, {
              variant: "success",
            });
            navigate(`/admin/service-provider/all`);
          }
        } else {
          dispatch(stopLoading());
          enqueueSnackbar(result?.data, { variant: "error" });
        }
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        setFieldError,
      }) => {
        setFieldValueFunc = setFieldValue;
        const handleBusinessNameChange = async (value) => {
          setFieldValue("businessName", value);
          const response = await fetchByBusinessName(value);
          if (response.success && response.data) {
            setBusinessNameData(response.data);
          } else {
            setBusinessNameData([]);
          }
        };

        const onCountryCodeChanged = (countryCode) => {
          setFieldValue("countryCode", countryCode.target.value);
          setSelectedCountryCode(countryCode.target.value);
        };

        const handlePhoneNumberChange = async (value) => {
          setFieldValue("phone", value);
          // TODO: remove the 2 line below and uncomment the code - NV-641
          setPhoneData([]);
          setFieldError("phone", "");

          // if (value?.length === 10) {
          //   const response = await fetchByPhoneNumber(value);
          //   if (response.success && response.data) {
          //     setFieldError("phone", "Phone number already exist");
          //     setPhoneData([response.data]);
          //   } else {
          //     setPhoneData([]);
          //     setFieldError("phone", "");
          //   }
          // } else {
          //   setPhoneData([]);
          //   setFieldError("phone", "");
          // }
        };

        const handleReferredBy = (newValue) => {
          if (newValue.length === 0) {
            setFieldValue("referAnonymously", false);
            setFieldValue("referrerAvailedService", false);
          }
          setFieldValue("referredBy", newValue);
        };

        const handleServiceLocation = (e, location) => {
          let newValue;
          const currentValue = values?.serviceLocation;
          if (e.target.checked) {
            if (currentValue && currentValue !== location) {
              newValue = "Both";
            } else {
              newValue = location;
            }
          } else {
            if (currentValue === "Both") {
              newValue = SERVICE_LOCATION.find((n) => n !== location);
            } else {
              newValue = "";
            }
          }
          if (newValue === "On-site") {
            setFieldValue("deliveryStatus", false);
          }
          handleChange({
            target: {
              name: "serviceLocation",
              value: newValue,
            },
          });
        };

        return (
          <Paper
            className="paper__container"
            sx={{ height: AUTO }}
            elevation={4}
          >
            <form noValidate onSubmit={handleSubmit} className="form">
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Stack spacing={3}>
                    <Breadcrumbs
                      separator={<NavigateNextIcon />}
                      aria-label="breadcrumb"
                    >
                      {breadcrumbs}
                    </Breadcrumbs>
                  </Stack>
                </Grid>
                <Grid item md={4}>
                  <FormControl
                    size={SMALL}
                    fullWidth
                    error={touched.namePrefix && Boolean(errors.namePrefix)}
                  >
                    <InputLabel id="name-prefix-label" required>
                      Name Prefix
                    </InputLabel>
                    <Select
                      labelId="name-prefix-label"
                      label="Name Prefix"
                      value={values?.namePrefix || ""}
                      onChange={handleChange}
                      name="namePrefix"
                      onBlur={handleBlur}
                    >
                      {NAME_PREFIX_TYPES.map((namePrefixType) => (
                        <MenuItem key={namePrefixType} value={namePrefixType}>
                          {namePrefixType}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.namePrefix && errors.namePrefix && (
                      <FormHelperText>{errors.namePrefix}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <TextField
                    required
                    label="First Name"
                    fullWidth
                    size={SMALL}
                    value={values?.firstName}
                    onChange={handleChange}
                    name="firstName"
                    onBlur={handleBlur}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  ></TextField>
                </Grid>
                <Grid item md={4}>
                  <TextField
                    required
                    label="Last Name"
                    fullWidth
                    size={SMALL}
                    value={values?.lastName}
                    onChange={handleChange}
                    name="lastName"
                    onBlur={handleBlur}
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  ></TextField>
                </Grid>
                <Grid item md={4}>
                  <PhoneAutocomplete
                    selectedPhoneData={selectedPhoneData}
                    handlePhoneNumberChange={handlePhoneNumberChange}
                    setSelectedPhoneData={setSelectedPhoneData}
                    phoneData={phoneData}
                    values={values}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    countryCodes={countryCodes}
                    onCountryCodeChanged={onCountryCodeChanged}
                    selectedCountryCode={selectedCountryCode}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Email"
                    fullWidth
                    size={SMALL}
                    value={values?.email}
                    onChange={handleChange}
                    name="email"
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  ></TextField>
                </Grid>
                <Grid item md={4}>
                  <TextField
                    required
                    label="Title"
                    fullWidth
                    size={SMALL}
                    value={values?.title}
                    onChange={handleChange}
                    name="title"
                    onBlur={handleBlur}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                  ></TextField>
                </Grid>
                <Grid item md={4}>
                  <Autocomplete
                    size="small"
                    disableCloseOnSelect
                    multiple
                    options={[
                      ...values.serviceCategory,
                      ...serviceCategories
                        .map((serviceCategory) => serviceCategory.id)
                        .filter(
                          (option) => !values.serviceCategory.includes(option)
                        ),
                    ]}
                    getOptionLabel={(option) => {
                      const category = serviceCategories.find(
                        (s) => s.id === option
                      );
                      return category?.name || "";
                    }}
                    value={values?.serviceCategory}
                    onChange={(_, newValue) => {
                      setFieldValue("serviceCategory", newValue);
                    }}
                    onInputChange={(_, newValue) => {
                      setFieldValue("serviceCategory", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Service Category"
                        name="serviceCategory"
                        fullWidth
                        size={SMALL}
                        value={values?.serviceCategory}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.serviceCategory &&
                          Boolean(errors.serviceCategory)
                        }
                        helperText={
                          touched.serviceCategory && errors.serviceCategory
                        }
                      />
                    )}
                    renderTags={(selected, getTagProps) => {
                      const displayedTags = selected.slice(0, 1);
                      const hiddenTagsCount =
                        selected.length - displayedTags.length;
                      return (
                        <Box display="flex" alignItems="center">
                          {displayedTags.map((value, index) => (
                            <Chip
                              size="small"
                              key={value}
                              label={
                                serviceCategories.find(
                                  (serviceCategory) =>
                                    serviceCategory.id === value
                                )?.name
                              }
                              {...getTagProps({ index })}
                            />
                          ))}
                          {hiddenTagsCount > 0 && (
                            <Box ml={1}>+{hiddenTagsCount}</Box>
                          )}
                        </Box>
                      );
                    }}
                  />
                </Grid>
                <Grid item md={4}>
                  <BusinessNameAutocomplete
                    selectedBusinessNameData={selectedBusinessNameData}
                    handleBusinessNameChange={handleBusinessNameChange}
                    setSelectedBusinessNameData={setSelectedBusinessNameData}
                    businessNameData={businessNameData}
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControl
                    size={SMALL}
                    fullWidth
                    error={
                      touched.preferredLanguage &&
                      Boolean(errors.preferredLanguage)
                    }
                  >
                    <InputLabel id="preferred-language-label" required>
                      Preferred Language
                    </InputLabel>
                    <Select
                      labelId="preferred-language-label"
                      label="Preferred Language"
                      value={values?.preferredLanguage || ""}
                      onChange={handleChange}
                      name="preferredLanguage"
                      onBlur={handleBlur}
                    >
                      {preferredLanguage.map((language) => (
                        <MenuItem key={language?.id} value={language?.id}>
                          {language?.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.preferredLanguage && errors.preferredLanguage && (
                      <FormHelperText>
                        {errors.preferredLanguage}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <div style={{ marginLeft: "16px" }}>
                      <ImagePicker
                        from={SERVICE_PROVIDER_CREATE}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                      />
                      {touched.image && errors.image && (
                        <Grid>
                          <FormHelperText sx={{ color: RED }}>
                            Image is required
                          </FormHelperText>
                        </Grid>
                      )}
                    </div>
                  </Grid>
                  <Grid item md={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} marginTop={"22px"}>
                        <TextField
                          style={{ marginLeft: "9px" }}
                          label=" Latitude"
                          placeholder='Enter Latitude'
                          fullWidth
                          type="number"
                          inputProps={{
                            step: "any",
                            min: -180,
                            max: 180,
                          }}
                          required
                          size={SMALL}
                          value={values?.latitude}
                          onChange={handleChange}
                          name="latitude"
                          onBlur={handleBlur}
                          error={touched.latitude && Boolean(errors?.latitude)}
                          helperText={touched.latitude && errors?.latitude}
                          sx={{
                            '& input[type=number]': {
                              MozAppearance: 'textfield',
                            },
                            '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                              WebkitAppearance: 'none',
                              margin: 0,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} marginTop={"20px"}>
                        <div
                          style={{ marginLeft: "10px", paddingRight: "2px" }}
                        >
                          <TextField
                            label={"Longitude"}
                            placeholder="Enter longitude"
                            fullWidth
                            required
                            size={SMALL}
                            value={values?.longitude}
                            onChange={handleChange}
                            name="longitude"
                            type="number"
                            inputProps={{
                              step: "any",
                              min: -180,
                              max: 180,
                            }}
                            onBlur={handleBlur}
                            error={
                              touched.longitude && Boolean(errors?.longitude)
                            }
                            helperText={touched.longitude && errors?.longitude}
                            sx={{
                              '& input[type=number]': {
                                MozAppearance: 'textfield',
                              },
                              '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                                WebkitAppearance: 'none',
                                margin: 0,
                              },
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div style={{ marginLeft: "9px" }}>
                          <TextField
                            label={"Localion URL"}
                            placeholder="Enter your location URL"
                            fullWidth
                            required
                            size={SMALL}
                            value={values?.locationUrl || ""}
                            onChange={handleChange}
                            name="locationUrl"
                            onBlur={handleBlur}
                            error={
                              touched.locationUrl &&
                              Boolean(errors?.locationUrl)
                            }
                            helperText={
                              touched?.locationUrl && errors?.locationUrl
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div style={{ marginLeft: "9px", paddingRight: "2px" }}>
                          <TextField
                            multiline
                            minRows={3}
                            maxRows={3}
                            required
                            label="Address"
                            fullWidth
                            size={SMALL}
                            value={values?.address || ""}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue("address", e.target.value);
                            }}
                            name="address"
                            onBlur={handleBlur}
                            error={touched.address && Boolean(errors.address)}
                            helperText={touched.address && errors.address}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} marginTop={"16px"}>
                  <Autocomplete
                    size={SMALL}
                    multiple
                    disableCloseOnSelect
                    limitTags={1}
                    options={[
                      ...values.servicedTo,
                      ...residents
                        .map((r) => r.id)
                        .filter((id) => !values.servicedTo.includes(id)),
                    ]}
                    value={values?.servicedTo}
                    onChange={(_, newValue) => {
                      setFieldValue("servicedTo", newValue);
                    }}
                    getOptionLabel={(option) => {
                      const resident = residents?.find((r) => r.id === option);
                      return `${resident?.name} | ${resident?.apartment?.building?.name} | ${resident?.apartment?.number}`;
                    }}
                    renderOption={(props, option) => {
                      const resident = residents?.find((r) => r.id === option);
                      return (
                        <li {...props}>
                          <Grid container alignItems={CENTER} display={FLEX}>
                            <Grid
                              item
                              width={60}
                              height={60}
                              marginRight={"20px"}
                            >
                              <img
                                src={resident?.image ? resident?.image : avatar}
                                alt=""
                                width="100%"
                                height="100%"
                              />
                            </Grid>
                            <Grid item width="70%">
                              <Grid
                                container
                                alignItems={FLEX_START}
                                display={FLEX}
                                flexDirection={COLUMN}
                              >
                                <Grid item width="100%">
                                  <Typography
                                    variant="body2"
                                    color={DEEP_OCEAN_BLUE}
                                    noWrap
                                    fontWeight={BOLD}
                                  >
                                    {resident?.name}
                                  </Typography>
                                </Grid>
                                <Grid item width="100%">
                                  <Typography variant="body2">
                                    {resident?.apartment?.building?.name}
                                  </Typography>
                                </Grid>
                                <Grid item width="100%">
                                  <Typography variant="body2">
                                    {resident?.apartment?.number}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </li>
                      );
                    }}
                    renderTags={(selected, getTagProps) => {
                      const displayedTags = selected.slice(0, 1);
                      const hiddenTagsCount =
                        selected.length - displayedTags.length;
                      return (
                        <Box display={FLEX} alignItems={CENTER}>
                          {displayedTags.map((value, index) => (
                            <Chip
                              key={value}
                              label={
                                residents?.find((r) => r.id === value)?.name +
                                " | " +
                                residents?.find((r) => r.id === value)
                                  ?.apartment?.building?.name +
                                " | " +
                                residents?.find((r) => r.id === value)
                                  ?.apartment?.number
                              }
                              {...getTagProps({ index })}
                            />
                          ))}
                          {hiddenTagsCount > 0 && (
                            <Box ml={1}>+{hiddenTagsCount}</Box>
                          )}
                        </Box>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Serviced To"
                        name="servicedTo"
                        error={touched.servicedTo && Boolean(errors.servicedTo)}
                        helperText={touched.servicedTo && errors.servicedTo}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} marginTop={"16px"}>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    limitTags={1}
                    options={[
                      ...values.referredBy,
                      ...residents
                        .map((r) => r.id)
                        .filter((id) => !values.referredBy.includes(id)),
                    ]}
                    value={values?.referredBy}
                    onChange={(_, newValue) => {
                      handleReferredBy(newValue);
                    }}
                    getOptionLabel={(option) => {
                      const resident = residents?.find((r) => r.id === option);
                      return `${resident?.name} | ${resident?.apartment?.building?.name} | ${resident?.apartment?.number}`;
                    }}
                    renderOption={(props, option) => {
                      const resident = residents?.find((r) => r.id === option);
                      return (
                        <li {...props}>
                          <Grid container alignItems={CENTER} display={FLEX}>
                            <Grid
                              item
                              width={60}
                              height={60}
                              marginRight={"20px"}
                            >
                              <img
                                src={resident?.image ? resident?.image : avatar}
                                alt=""
                                width="100%"
                                height="100%"
                              />
                            </Grid>
                            <Grid item width="70%">
                              <Grid
                                container
                                alignItems={FLEX_START}
                                display={FLEX}
                                flexDirection={COLUMN}
                              >
                                <Grid item width="100%">
                                  <Typography
                                    variant="body2"
                                    color={DEEP_OCEAN_BLUE}
                                    noWrap
                                    fontWeight={BOLD}
                                  >
                                    {resident?.name}
                                  </Typography>
                                </Grid>
                                <Grid item width="100%">
                                  <Typography variant="body2">
                                    {resident?.apartment?.building?.name}
                                  </Typography>
                                </Grid>
                                <Grid item width="100%">
                                  <Typography variant="body2">
                                    {resident?.apartment?.number}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </li>
                      );
                    }}
                    renderTags={(selected, getTagProps) => {
                      const displayedTags = selected.slice(0, 1);
                      const hiddenTagsCount =
                        selected.length - displayedTags.length;
                      return (
                        <Box display={FLEX} alignItems={CENTER}>
                          {displayedTags.map((value, index) => (
                            <Chip
                              key={value}
                              label={
                                residents?.find((r) => r.id === value)?.name +
                                " | " +
                                residents?.find((r) => r.id === value)
                                  ?.apartment?.building?.name +
                                " | " +
                                residents?.find((r) => r.id === value)
                                  ?.apartment?.number
                              }
                              {...getTagProps({ index })}
                            />
                          ))}
                          {hiddenTagsCount > 0 && (
                            <Box ml={1}>+{hiddenTagsCount}</Box>
                          )}
                        </Box>
                      );
                    }}
                    size={SMALL}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Referred By"
                        name="referredBy"
                        error={touched.referredBy && Boolean(errors.referredBy)}
                        helperText={touched.referredBy && errors.referredBy}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3}>
                  <FormControl>
                    <FormControlLabel
                      key={"referAnonymously"}
                      control={
                        <Checkbox
                          sx={{
                            color: DEEP_OCEAN_BLUE,
                          }}
                          size={SMALL}
                          disabled={!values?.referredBy.length > 0}
                          name="referAnonymously"
                          checked={values?.referAnonymously}
                          value={values?.referAnonymously}
                          onChange={handleChange}
                        />
                      }
                      label={<Typography>Refer Anonymously</Typography>}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={3} display={FLEX} justifyContent={FLEX_END}>
                  <FormControl>
                    <FormControlLabel
                      key={"referrerAvailedService"}
                      control={
                        <Checkbox
                          sx={{
                            color: DEEP_OCEAN_BLUE,
                          }}
                          disabled={!values?.referredBy.length > 0}
                          size={SMALL}
                          name="referrerAvailedService"
                          checked={values?.referrerAvailedService}
                          value={values?.referrerAvailedService}
                          onChange={handleChange}
                        />
                      }
                      label={<Typography>Referrer Availed Service</Typography>}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12} marginBottom={10}>
                  <TextField
                    required
                    label="Description"
                    fullWidth
                    size={SMALL}
                    value={values.description || ""}
                    onChange={handleChange}
                    name="description"
                    multiline
                    minRows={5}
                    maxRows={5}
                    onBlur={handleBlur}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  ></TextField>
                </Grid>
                <Grid
                  width={"100%"}
                  display={FLEX}
                  paddingTop={"16px"}
                  paddingLeft={"16px"}
                  justifyContent={SPACE_BETWEEN}
                >
                  <Grid>
                    <FormGroup>
                      <Grid display={FLEX}>
                        <Typography
                          color={TEXT_COLOR}
                          fontSize="1rem"
                          fontWeight="400"
                          paddingTop={"8px"}
                          paddingRight={"10px"}
                        >
                          Service Location
                          <span className="MuiFormLabel-asterisk"> *</span>
                        </Typography>
                        {SERVICE_LOCATION.map((locationValue) => (
                          <FormControlLabel
                            name="serviceLocation"
                            key={locationValue}
                            control={
                              <Checkbox
                                sx={{
                                  color: DEEP_OCEAN_BLUE,
                                }}
                                size={SMALL}
                                value={values?.serviceLocation}
                                checked={
                                  values?.serviceLocation === locationValue ||
                                  values?.serviceLocation === "Both"
                                }
                                onChange={(e) => {
                                  handleServiceLocation(e, locationValue);
                                }}
                                onBlur={handleBlur}
                                error={
                                  touched.serviceLocation &&
                                  Boolean(errors.serviceLocation)
                                }
                              />
                            }
                            label={
                              <Typography className="label__fontsize">
                                {locationValue}
                              </Typography>
                            }
                          />
                        ))}
                      </Grid>
                      {touched.serviceLocation && errors.serviceLocation && (
                        <FormHelperText style={{ color: RED }}>
                          {errors.serviceLocation}
                        </FormHelperText>
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid>
                    <ThemeProvider theme={theme}>
                      <FormControl
                        size={SMALL}
                        fullWidth
                        className="ServiceProviderCreate__switch__container"
                      >
                        <Typography
                          fontSize="1rem"
                          fontWeight="400"
                          paddingTop={"8px"}
                        >
                          Door Delivery
                        </Typography>
                        <Switch
                          name="deliveryStatus"
                          checked={values?.deliveryStatus}
                          onChange={handleChange}
                          disabled={
                            !values?.serviceLocation ||
                            values?.serviceLocation === "On-site"
                          }
                        />
                        <Typography
                          fontSize="1rem"
                          fontWeight="400"
                          paddingTop={"8px"}
                        >
                          Home visit
                        </Typography>
                      </FormControl>
                    </ThemeProvider>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <FormControlLabel
                        key={"deleted"}
                        name="residentProvider"
                        control={
                          <Checkbox
                            sx={{
                              color: DEEP_OCEAN_BLUE,
                            }}
                            size={SMALL}
                            checked={values?.residentProvider}
                            value={values?.residentProvider}
                            onChange={handleChange}
                          />
                        }
                        label={<Typography>Resident Provider</Typography>}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <FormControlLabel
                        key={"homeBased"}
                        name="homeBased"
                        control={
                          <Checkbox
                            disabled={!values?.residentProvider}
                            sx={{
                              color: DEEP_OCEAN_BLUE,
                            }}
                            size={SMALL}
                            checked={
                              values?.residentProvider
                                ? values?.homeBased
                                : false
                            }
                            onChange={handleChange}
                          />
                        }
                        label={<Typography>Home Based</Typography>}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item md={12} display={FLEX} justifyContent={CENTER}>
                  <Button
                    type="submit"
                    variant="contained"
                    className="submit__button"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
              <Loader />
            </form>
          </Paper>
        );
      }}
    </Formik>
  );
};

export default Create;
