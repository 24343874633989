import Layout from "../../components/Layout/Layout";
import Edit from "../../components/BuySell/Edit";

const BuySellEditPage = () => {
  return (
    <Layout>
      <Edit />
    </Layout>
  );
};

export default BuySellEditPage;
