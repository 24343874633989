import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { enqueueSnackbar } from "notistack";
import { fetchServiceProviderReviewByToken } from "../../services/service-provider";
import { Container, Grid, Paper, Typography, Card } from "@mui/material";
import "./ServiceProviderReview.scss";
import NivasiDarkIcon from "../../utils/images/nivasi-icon-dark.png";
import PrivacyAndTermsIcon from "../../utils/images/privacy-and-terms.png";
import loader from "../../assets/images/Loader.gif";
import { FLEX } from "../../utils/constants";
import moment from "moment";
import Star from "../../utils/images/star.png";

const PublicReviewView = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [serviceProviderReview, setserviceProviderReview] = useState(null);
  const [serviceProviderReviewName, setserviceProviderReviewName] =
    useState(null);
  const [spinning, setSpinning] = useState(true);

  useEffect(() => {
    (async () => {
      dispatch(startLoading());
      const response = await fetchServiceProviderReviewByToken(token);
      if (response.success && response.data) {
        const serviceProviderReviewFromResponse = response?.data;
        setserviceProviderReview(serviceProviderReviewFromResponse);
        if (serviceProviderReviewFromResponse?.anonymous) {
          setserviceProviderReviewName(
            "an " +
              serviceProviderReviewFromResponse.firstName +
              " " +
              serviceProviderReviewFromResponse.lastName +
              " from " +
              serviceProviderReviewFromResponse.community
          );
        } else {
          setserviceProviderReviewName(
            serviceProviderReviewFromResponse.firstName +
              ", " +
              serviceProviderReviewFromResponse.towerName +
              " from " +
              serviceProviderReviewFromResponse.community
          );
        }
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
      setSpinning(false);
    })();
  }, [dispatch, token]);

  return (
    <React.Fragment>
      {!spinning ? (
        <Container>
          <Paper
            className="container"
            elevation={4}
            sx={{ overflow: "auto" }}
            style={{ maxHeight: 750, overflow: "auto" }}
          >
            <Grid container spacing={2} display={"flex"} marginBottom={2}>
              <Grid item xs={8}>
                <Grid container spacing={2} padding={2}>
                  <div className="container-fluid">
                    <img
                      src={NivasiDarkIcon}
                      className="navbar-brand nivasi-icon"
                      alt="Nivasi Icon"
                    />
                  </div>
                </Grid>
                <Typography
                  className={"privacy-terms-heading"}
                  fontWeight={"600"}
                >
                  {"Review by "}
                  {serviceProviderReviewName || ""}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <img
                  src={PrivacyAndTermsIcon}
                  alt=""
                  style={{ width: "60px", height: "70px", paddingTop: "70px" }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              marginTop={2}
              display={FLEX}
              marginBottom={2}
            >
              <Grid item md={3} sm={5}>
                <Typography fontWeight={"600"}>Rating :</Typography>
              </Grid>

              <Grid item md={9} sm={7}>
                <Grid container>
                  <Grid item>{serviceProviderReview?.rating}</Grid>
                  <Grid item>
                    <img
                      src={Star}
                      alt=""
                      style={{ width: 15, height: 15, marginLeft: 3 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" marginBottom={2}>
              <Grid item sm={12} xs={12} md={3}>
                <Typography fontWeight={"600"}>Review :</Typography>
              </Grid>
              <Grid item sm={12} xs={12} md={9}>
                <Typography variant="body1">
                  {serviceProviderReview?.description ||
                    "No description available"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={3} sm={5}>
                <Typography fontSize={15} fontWeight={"600"}>
                  Reviewed On :
                </Typography>
              </Grid>
              <Grid item md={9} sm={7}>
                <Typography>
                  {moment(serviceProviderReview?.createdDate).format(
                    "Do MMM YYYY"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      ) : (
        <Card
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 1000,
          }}
        >
          <img src={loader} alt="" className="Nivasi__Loader" />
        </Card>
      )}
    </React.Fragment>
  );
};
export default PublicReviewView;
