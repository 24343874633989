import adminRootInstance from "../utils/config/axios-config";

export const handleAdminLogin = async (values) => {
  try {
    const response = await adminRootInstance.post("/auth/login", {
      email: values.email,
      password: values.password,
    });
    if (response?.data?.success && response?.data?.data?.token) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};
