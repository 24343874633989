import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./Slices/AuthSlice";
import CommonReducer from "./Slices/CommonSlice";

const Store = configureStore({
  reducer: {
    auth: authReducer,
    common: CommonReducer,
  },
});

export default Store;
