import React from "react";
import Layout from "../../components/Layout/Layout.js";
import ResidentEngagement from "../../components/Reports/ResidentEngagement.js";

const ResidentEngagementPage = () => {
    return (
        <Layout>
            <ResidentEngagement />
        </Layout>
    );
};

export default ResidentEngagementPage;
