import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import {
  AUTO,
  CENTER,
  DEEP_OCEAN_BLUE,
  FLEX,
  SPACE_BETWEEN,
} from "../../utils/constants";
import { Link, useLocation } from "react-router-dom";

const FlagModal = (props) => {
  const { openFlagPopover, setOpenFlagPopover, flagMessage } = props;
  const location = useLocation();
  const isServiceProvider = location.pathname.includes("service-provider");
  const flags = isServiceProvider
    ? flagMessage?.serviceProviderFlags
    : flagMessage?.buySellItemFlags;

  const handleClose = () => {
    setOpenFlagPopover(null);
  };
  const open = Boolean(openFlagPopover);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="flag__modal">
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "12px",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align={CENTER}
            marginBottom={"15px"}
            color={DEEP_OCEAN_BLUE}
            fontWeight={600}
          >
            Flagged Details
          </Typography>
          <List sx={{ overflow: AUTO, maxHeight: 500 }}>
            <Grid container spacing={3}>
              <Grid item md={2} display={FLEX} justifyContent={SPACE_BETWEEN}>
                <Typography
                  paddingLeft={"15px"}
                  className="TableComp__table__header"
                >
                  Flagged by
                </Typography>
              </Grid>
              <Grid item md={10} display={FLEX} alignItems={CENTER}>
                <Typography
                  className="TableComp__table__header"
                  paddingLeft={"5px"}
                >
                  Reason
                </Typography>
              </Grid>
            </Grid>
            {flags?.map((flag) => {
              return isServiceProvider ? (
                <>
                  <ListItem>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        md={2}
                        display={FLEX}
                        justifyContent={SPACE_BETWEEN}
                      >
                        <Link
                          key={flag?.id}
                          color={DEEP_OCEAN_BLUE}
                          to={`/admin/resident/view/${flag?.resident?.id}`}
                        >
                          <Typography className="Flagged__By__Name">
                            {flag?.resident?.firstName +
                              " " +
                              flag?.resident?.lastName}
                          </Typography>
                        </Link>
                      </Grid>
                      <Grid item md={10} display={FLEX} alignItems={CENTER}>
                        <Typography>{flag?.reason}</Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                  {flags.length > 1 && <Divider component="li" />}
                </>
              ) : (
                <>
                  <ListItem>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        md={2}
                        display={FLEX}
                        justifyContent={SPACE_BETWEEN}
                      >
                        <Link
                          key={flag?.id}
                          color={DEEP_OCEAN_BLUE}
                          to={`/admin/resident/view/${flag?.resident?.id}`}
                        >
                          <Typography className="Flagged__By__Name">
                            {flag?.resident?.firstName +
                              " " +
                              flag?.resident?.lastName}
                          </Typography>
                        </Link>
                      </Grid>
                      <Grid item md={10} display={FLEX} alignItems={CENTER}>
                        <Typography>{flag?.reason}</Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                  {flags.length > 1 && <Divider component="li" />}
                </>
              );
            })}
          </List>
        </Typography>
        <Grid
          item
          md={12}
          display={FLEX}
          justifyContent={CENTER}
          paddingTop={"30px"}
        >
          <Button
            type="submit"
            variant="contained"
            className="submit__button"
            onClick={handleClose}
          >
            Close
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default FlagModal;
