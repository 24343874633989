import Layout from "../../components/Layout/Layout";
import ServiceProvider from "../../components/ServiceProvider/ServiceProvider";

const ServiceProviderListPage = () => {
  return (
    <Layout>
      <ServiceProvider />
    </Layout>
  );
};

export default ServiceProviderListPage;
