import React from "react";
import Layout from "../../components/Layout/Layout.js";
import ResidentReviews from "../../components/Reports/ResidentReviews.js";

const ResidentReviewsPage = () => {
    return (
        <Layout>
            <ResidentReviews />
        </Layout>
    );
};

export default ResidentReviewsPage;
