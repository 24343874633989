import adminRootInstance from "../utils/config/axios-config";

export const addServiceProvider = async (values) => {
  try {
    const response = await adminRootInstance.post(`/service-provider`, values);
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchAllServiceProvider = async (
  page,
  rowsPerPage,
  searchValue,
  filterValues,
  sortColumn,
  sortBy
) => {
  try {
    const response = await adminRootInstance.get(
      `/service-provider?offset=${page}&limit=${rowsPerPage}&searchValue=${searchValue}&category=${filterValues.category}&preferredLanguage=${filterValues.preferredLanguage}&deleteStatus=${filterValues.status}&resident=${filterValues.resident}&sortColumn=${sortColumn}&sortBy=${sortBy}&flagged=${filterValues.flagged}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchByBusinessName = async (businessName) => {
  try {
    const response = await adminRootInstance.get(
      `/service-provider/fetch-by-business-name/${businessName}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchServiceProvider = async (id) => {
  try {
    const response = await adminRootInstance.get(`/service-provider/${id}`);
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchServiceProviderTncAgreement = async (id) => {
  try {
    const response = await adminRootInstance.get(`/service-provider/tnc/agreement/${id}`);
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchHomeBasedApprovalDocument = async (id) => {
  try {
    const response = await adminRootInstance.get(`/service-provider/home-based/approval/${id}`);
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const deleteTncDocument = async (id) => {
  try {
    const response = await adminRootInstance.delete(
      `/service-provider/tnc/agreement/${id}`
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const deleteHomeBasedApprovalDocument = async (id) => {
  try {
    const response = await adminRootInstance.delete(
      `/service-provider/home-based/approval/${id}`
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchServiceProviderByToken = async (userToken) => {
  try {
    const response = await adminRootInstance.get(`/auth/service-provider/tnc`, {
      params: {
        token: userToken,
      },
    });
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchServiceProviderReviewByToken = async (userToken) => {
  try {
    const response = await adminRootInstance.get(`/auth/review`, {
      params: {
        token: userToken,
      },
    });
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const updateServiceProvider = async (id, values) => {
  try {
    const response = await adminRootInstance.put(
      `/service-provider/${id}`,
      values
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data?.message,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const acceptTermsAndConditions = async (userToken, ip) => {
  try {
    const response = await adminRootInstance.post(
      `/auth/service-provider/tnc/accept`,
      null,
      {
        params: {
          token: userToken,
          ipAddress: ip
        },
      }
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data?.message,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const sendServiceProviderTerms = async (id) => {
  try {
    const response = await adminRootInstance.post(
      `/service-provider/tnc/send/${id}`
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const deleteServiceProvider = async (id, reason) => {
  try {
    const response = await adminRootInstance.post(`/service-provider/${id}`, {
      reason: reason,
    });
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data.message,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchByPhoneNumber = async (phoneNumber) => {
  try {
    const response = await adminRootInstance.get(
      `/service-provider/fetch-by-phone-number/${phoneNumber}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const uploadImage = async (file, id) => {
  try {
    const formData = new FormData();
    const newFile = new File([file], `image.${file?.type?.split("/").pop()}`);
    formData.append("file", newFile);
    const response = await adminRootInstance.post(
      `/service-provider/image-upload/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const deleteServiceProviderImage = async (id) => {
  try {
    const response = await adminRootInstance.delete(
      `/service-provider/remove-image/${id}`
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchCountryCodes = async () => {
  try {
    const response = await adminRootInstance.get(
      "https://restcountries.com/v3.1/all"
    );
    const countryData = response.data.map((country) => ({
      code: country.cca2,
      label: country.name.common,
      phone:
        country.idd.root +
        (country.idd.suffixes ? country.idd.suffixes[0] : ""), // Phone code
    }));
    return {
      success: true,
      data: countryData,
    };
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const uploadTncPdf = async (file, id, uploadedById) => {
  try {
    const formData = new FormData();
    const newFile = new File([file], `image.${file?.type?.split("/").pop()}`, {
      type: "application/pdf",
    });
    formData.append("file", newFile);
    const response = await adminRootInstance.post(
      `/service-provider/upload/tnc/${id}/${uploadedById}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const uploadApprovalPdf = async (file, id, approvedById) => {
  try {
    const formData = new FormData();
    const newFile = new File([file], `image.${file?.type?.split("/").pop()}`, {
      type: "application/pdf",
    });
    formData.append("file", newFile);
    const response = await adminRootInstance.post(
      `/service-provider/upload/home-based/approval/${id}/${approvedById}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const updateServiceProviderTncProviderDate = async (id) => {
  try {
    const response = await adminRootInstance.put(
      `/service-provider/tnc/pdf-generation-date/${id}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const fetchServiceProviderFlags = async (id) => {
  try {
    const response = await adminRootInstance.get(
      `/reports/service-provider/flags/${id}`
    );
    if (response?.data?.success && response?.data?.data) {
      return {
        success: true,
        data: response?.data?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const approveServiceProvider = async (id, approvedById) => {
  try {
    const response = await adminRootInstance.post(
      `service-provider/approve/home-based/${id}/${approvedById}`
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};
