import { Box, Button, Grid, Modal, Rating, Typography } from "@mui/material";
import React from "react";
import { CENTER, DEEP_OCEAN_BLUE, FLEX } from "../../utils/constants";

const View = (props) => {
  const { viewModal, setViewModal, editData } = props;
  return (
    <Modal
      open={viewModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="DeleteReason__Modal">
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              align={CENTER}
            >
              View Review
            </Typography>
          </Grid>
          <Grid item md={4} display={FLEX}>
            <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
              Review By
            </Typography>
            <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
              :
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Typography>
              {editData?.resident?.firstName +
                " " +
                editData?.resident?.lastName}
            </Typography>
          </Grid>

          <Grid item md={4} display={FLEX}>
            <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
              Description
            </Typography>
            <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
              :
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Typography>{editData?.description}</Typography>
          </Grid>

          <Grid item md={4} display={FLEX}>
            <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
              Review To
            </Typography>
            <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
              :
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Typography>
              {editData?.serviceProvider?.firstName +
                " " +
                editData?.serviceProvider?.lastName}
            </Typography>
          </Grid>

          <Grid item md={4} display={FLEX}>
            <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
              Rating
            </Typography>
            <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
              :
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Rating
              readOnly
              value={editData?.rating}
              precision={0.5}
              name="rating"
            />
          </Grid>
          {editData?.deleted && (
            <>
              <Grid item md={4} display={FLEX}>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Deleted Reason
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8}>
                <Typography>
                  {editData?.serviceProvider?.firstName +
                    " " +
                    editData?.serviceProvider?.lastName}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item md={12} display={FLEX} justifyContent={CENTER}>
            <Button
              type="submit"
              variant="contained"
              className="submit__button"
              onClick={() => setViewModal(false)}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default View;
