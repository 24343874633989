import * as yup from "yup";
const phoneRegEx =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const firstNameValidator = yup
  .string()
  .trim()
  .min(3, "Minimum 3 characters!")
  .max(50, "Maximum 50 characters")
  .required("First Name is required!");
export const emailValidator = yup
  .string()
  .trim()
  .email("Must be a valid email!")
  .max(255)
  .required("Email is required!");
export const passwordValidator = yup
  .string()
  .trim()
  .min(5, "Minimum 5 characters")
  .max(30, "Maximum 30 characters")
  .required("Password is required!");
export const lastNameValidator = yup
  .string()
  .trim()
  .min(2, "Minimum 2 characters!")
  .max(50, "Maximum 50 characters")
  .required("Last Name is required!");
export const addressValidator = yup
  .string()
  .trim()
  .min(5, "Minimum 5 characters!")
  .max(50, "Maximum 50 characters!")
  .required("Please Enter Address!");
export const serviceDescValidator = yup
  .string()
  .trim()
  .min(5, "Minimum 5 characters!")
  .max(50, "Maximum 50 characters!")
  .required("Service Description is required!");
export const aadhaarNoValidator = yup
  .string()
  .trim()
  .min(12, "Must be 12 Numbers!")
  .required("Aadhaar Number is required!")
  .matches(phoneRegEx, "Must be Valid Aadhaar Number");
export const panNoValidator = yup
  .string()
  .trim()
  .min(10, "Must be 10 characters!")
  .required("Pan Number is required!");
export const serviceValidator = yup
  .string()
  .trim()
  .min(4, "Minimum 4 characters!")
  .required("Service is required!");
export const phoneNoValidator = yup
  .string()
  .trim()
  .required("Phone Number is required!")
  .matches(phoneRegEx, "Must be Valid Phone Number");
