import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import "./Layout.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import ReviewsIcon from "@mui/icons-material/Reviews";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import main_logo_icon from "../../assets/images/main-logo-white.png";
import ekamIllam from "../../assets/images/ekamIllam.png";
import { useLocation, useNavigate, Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import "./Layout.scss";
import { useDispatch, useSelector } from "react-redux";
import { logoutHandler } from "../../redux/Slices/AuthSlice";
import ConstructionIcon from "@mui/icons-material/Construction";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import {
  CENTER,
  DEEP_OCEAN_BLUE,
  INHERIT,
  NO_WRAP,
  TEAL_BLUE,
  WHITE,
} from "../../utils/constants";

const drawerWidth = 300;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: NO_WRAP,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const defaultTheme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: DEEP_OCEAN_BLUE,
          color: "#D2F5FF",
        },
      },
    },
  },
});

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userName = useSelector((store) => store.auth.userName);
  const isPublic = location.pathname.includes("public");
  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch(logoutHandler());
    localStorage.clear();
    navigate("/admin/login");
  };
  const menuItems = [
    {
      name: "Dashboard",
      to: "/admin/dashboard",
      text: "/admin/dashboard",
      icon: (
        <ListItemIcon>
          <DashboardIcon
            sx={{
              backgroundColor:
                location.pathname === "/admin/dashboard"
                  ? TEAL_BLUE
                  : DEEP_OCEAN_BLUE,
              color: WHITE,
              fontSize: 30,
            }}
          />
        </ListItemIcon>
      ),
    },
    {
      name: "Residents",
      to: "/admin/resident/all",
      text: "/admin/resident",
      icon: (
        <ListItemIcon>
          <GroupsIcon
            sx={{
              backgroundColor: location.pathname.includes("/admin/resident")
                ? TEAL_BLUE
                : DEEP_OCEAN_BLUE,
              color: WHITE,
              fontSize: 30,
            }}
          />
        </ListItemIcon>
      ),
    },
    {
      name: "Service Providers",
      to: "/admin/service-provider/all",
      text: "/admin/service-provider",
      icon: (
        <ListItemIcon>
          <ConstructionIcon
            sx={{
              backgroundColor: location.pathname.includes(
                "/admin/service-provider"
              )
                ? TEAL_BLUE
                : DEEP_OCEAN_BLUE,
              color: WHITE,
              fontSize: 30,
            }}
          />
        </ListItemIcon>
      ),
    },
    {
      name: "Review Management",
      to: "/admin/review-management/all",
      text: "/admin/review-management",
      icon: (
        <ListItemIcon>
          <ReviewsIcon
            sx={{
              backgroundColor: location.pathname.includes(
                "/admin/review-management"
              )
                ? TEAL_BLUE
                : DEEP_OCEAN_BLUE,
              color: WHITE,
              fontSize: 30,
            }}
          />
        </ListItemIcon>
      ),
    },
    {
      name: "Buy & Sell",
      to: "/admin/buy-sell/all",
      text: "/admin/buy-sell",
      icon: (
        <ListItemIcon>
          <LocalMallIcon
            sx={{
              backgroundColor: location.pathname.includes("/admin/buy-sell")
                ? TEAL_BLUE
                : DEEP_OCEAN_BLUE,
              color: WHITE,
              fontSize: 30,
            }}
          />
        </ListItemIcon>
      ),
    },
    {
      name: "Reports",
      to: "/admin/reports/all",
      text: "/admin/reports",
      icon: (
        <ListItemIcon>
          <StickyNote2OutlinedIcon
            sx={{
              backgroundColor: location.pathname.includes("/admin/reports")
                ? "#2D6788"
                : DEEP_OCEAN_BLUE,
              color: WHITE,
              fontSize: 30,
            }}
          />
        </ListItemIcon>
      ),
    },
  ];
  const isActive = (menuItem) => {
    return location.pathname.includes(menuItem.text);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box className="Layout__container">
        <CssBaseline />
        <AppBar
          position="absolute"
          open={true}
          className="Layout__header__container"
          sx={
            isPublic && {
              width: "100%",
            }
          }
        >
          <Toolbar className="Layout__header__toolbar">
            <Typography
              component="h1"
              variant="h6"
              align={CENTER}
              noWrap
              className="Layout__header__logo__container"
            >
              <img src={main_logo_icon} alt="" height="30px" />
            </Typography>
            {!isPublic && (
              <>
                <AccountBoxIcon />
                <Typography padding={2}>{userName}</Typography>
                <IconButton color={INHERIT} onClick={logoutUser}>
                  <LogoutIcon />
                </IconButton>
              </>
            )}
          </Toolbar>
        </AppBar>

        {!isPublic && (
          <Drawer variant="permanent" open={true} className="Layout__drawer">
            <Toolbar>
              <div
                className="Layout__home__icon__container"
                onClick={() => navigate("/admin/home")}
              >
                <img
                  src={ekamIllam}
                  alt=""
                  height="30px"
                  className="Layout__home__icon"
                />
              </div>
            </Toolbar>
            <List>
              {menuItems.map((item) => (
                <ListItem
                  key={item?.name}
                  component={Link}
                  to={item.to}
                  sx={{
                    backgroundColor: isActive(item) ? TEAL_BLUE : "",
                  }}
                >
                  {item.icon}
                  <ListItemText
                    primary={item.name}
                    className="Layout__menu__item__text"
                  />
                </ListItem>
              ))}
            </List>
          </Drawer>
        )}
        <Box component="main" className="Layout__main__component__container">
          <Toolbar />
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
