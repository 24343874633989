import {
  Backdrop,
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import loader from "../../assets/images/Loader.gif";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./Resident.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchResident } from "../../services/resident";
import moment from "moment";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import {
  AUTO,
  BLACK,
  CENTER,
  DEEP_OCEAN_BLUE,
  FLEX,
  INHERIT,
  NONE,
  RESIDENT_VIEW,
  SPACE_BETWEEN,
} from "../../utils/constants";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import ImagePicker from "../ServiceProvider/ImagePicker";

const View = () => {
  const [resident, setResident] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(startLoading());
      const response = await fetchResident(id);
      if (response.success && response.data) {
        setResident(response?.data);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    };
    fetchData();
  }, [id, dispatch]);

  const breadcrumbs = [
    <Link
      key="1"
      color={DEEP_OCEAN_BLUE}
      to={"/admin/resident/all"}
      className="breadcrumb__link"
    >
      Residents
    </Link>,
    <Typography key="3" color={BLACK}>
      View
    </Typography>,
  ];

  const loading = useSelector((state) => state.common.loading);

  return (
    <>
      <div>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          color={DEEP_OCEAN_BLUE}
          open={loading}
        >
          <img src={loader} alt="" className="Nivasi__Loader" />
        </Backdrop>
      </div>
      <Paper className="paper__container" elevation={4}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Stack spacing={3}>
              <Breadcrumbs
                separator={<NavigateNextIcon />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Stack>
          </Grid>
          <Grid item md={3}>
            <Paper
              elevation={4}
              className="ResidentView__basic__details__container"
            >
              {resident?.image === null ? (
                <Box
                  border="1px solid rgba(0, 0, 0, 0.23)"
                  height={"100%"}
                  display={FLEX}
                  justifyContent={CENTER}
                  alignItems={CENTER}
                  padding={"6px"}
                  borderRadius={"4px"}
                >
                  <div
                    className="ServiceProviderCreate__image__picker__button"
                    style={{
                      pointerEvents: NONE,
                      color: INHERIT,
                    }}
                  >
                    <div>
                      <ImageNotSupportedIcon className="ServiceProviderCreate__image__picker__add__photo__icon" />
                      <Typography fontSize="1rem" textTransform={NONE}>
                        Image Not Uploaded
                      </Typography>
                    </div>
                  </div>
                </Box>
              ) : (
                <Box
                  border="1px solid rgba(0, 0, 0, 0.23)"
                  height={"100%"}
                  display={FLEX}
                  justifyContent={CENTER}
                  alignItems={CENTER}
                  padding={"6px"}
                  borderRadius={"4px"}
                >
                  <ImagePicker
                    defaultImage={resident?.image}
                    from={RESIDENT_VIEW}
                  />
                </Box>
              )}
            </Paper>
          </Grid>
          <Grid item md={9}>
            <Paper
              className="ResidentView__basic__details__container"
              elevation={4}
            >
              <Grid container spacing={3}>
                <Grid
                  item
                  md={12}
                  display={FLEX}
                  justifyContent={SPACE_BETWEEN}
                  alignItems={CENTER}
                >
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Full Name
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid
                    md={9}
                    paddingLeft={2}
                    display={FLEX}
                    alignItems={CENTER}
                  >
                    <Typography
                      fontWeight={600}
                      color={DEEP_OCEAN_BLUE}
                      className={resident?.resident ? "" : "data__opacity"}
                    >
                      {resident?.resident
                        ? `${resident?.resident?.firstName} ${resident?.resident?.lastName}`
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={12} marginTop={"-20px"} display={FLEX}>
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Community
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid md={9} paddingLeft={2}>
                    <Typography>
                      {resident?.resident?.community?.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={12} marginTop={"-20px"} display={FLEX}>
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Phone
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid md={9} paddingLeft={2}>
                    {resident?.resident?.phone ? (
                      <Typography>{`${
                        resident?.resident?.countryCode
                      }-${resident?.resident?.phone?.slice(
                        0,
                        5
                      )}-${resident?.resident?.phone?.slice(5)}`}</Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <Grid item md={12} marginTop={"-20px"} display={FLEX}>
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Email
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid md={9} paddingLeft={2}>
                    <Typography>{resident?.resident?.email}</Typography>
                  </Grid>
                </Grid>
                <Grid item md={12} marginTop={"-20px"} display={FLEX}>
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Joined Date
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid md={9} paddingLeft={2}>
                    <Typography
                      className={resident?.resident ? "" : "data__opacity"}
                    >
                      {resident?.resident
                        ? moment(resident?.resident?.createdDate).format(
                            "Do MMMM YYYY"
                          )
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={12}>
            <Grid container spacing={3}>
              <Grid item md={12} paddingBottom={1}>
                <div className="ResidentView__section__divider">
                  <span className="ResidentView__section__divider__span">
                    Personal Information
                  </span>
                </div>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Salutation
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography>{resident?.resident?.namePrefix}</Typography>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Apartment No
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography>
                  {resident?.resident?.apartment?.apartmentNumber}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Building
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography>{resident?.resident?.building?.name}</Typography>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Resident Type
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography>{resident?.resident?.residentType}</Typography>
              </Grid>
              {resident?.resident?.otherDeletionReason && (
                <>
                  <Grid
                    item
                    md={4}
                    display={FLEX}
                    justifyContent={SPACE_BETWEEN}
                  >
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Deleted Reason
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid item md={8} display={FLEX} alignItems={CENTER}>
                    <Typography>
                      {resident?.resident?.otherDeletionReason}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid
                item
                md={3}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={CENTER}
                marginLeft={AUTO}
                marginRight={AUTO}
              ></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default View;
