import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CENTER,
  DEEP_OCEAN_BLUE,
  FLEX,
  FLEX_END,
  FLEX_START,
  INHERIT,
  NONE,
  SPACE_BETWEEN,
  TOP,
} from "../../utils/constants";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

const ModalView = (props) => {
  const { viewModal, setViewModal, serviceProvider } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const editHandler = () => {
    setViewModal(false);
    navigate(
      `/admin/service-provider/edit/${serviceProvider?.serviceProvider?.id}`
    );
  };

  return (
    <Modal
      open={viewModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal__container"
    >
      <Paper elevation={4} className="modal__view">
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              align={CENTER}
              paddingBottom={2}
            >
              View Service Provider
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Paper
              elevation={4}
              className="ResidentView__basic__details__container"
            >
              {serviceProvider?.image === null ? (
                <Box
                  border="1px solid rgba(0, 0, 0, 0.23)"
                  height={"100%"}
                  display={FLEX}
                  justifyContent={CENTER}
                  alignItems={CENTER}
                  padding={"6px"}
                  borderRadius={"4px"}
                >
                  <div
                    className="ServiceProviderCreate__image__picker__button"
                    style={{
                      pointerEvents: NONE,
                      color: INHERIT,
                    }}
                  >
                    <div>
                      <ImageNotSupportedIcon className="ServiceProviderCreate__image__picker__add__photo__icon" />
                      <Typography fontSize="1rem" textTransform={NONE}>
                        Image Not Uploaded
                      </Typography>
                    </div>
                  </div>
                </Box>
              ) : (
                <Box
                  border="1px solid rgba(0, 0, 0, 0.23)"
                  height={"100%"}
                  display={FLEX}
                  justifyContent={CENTER}
                  alignItems={CENTER}
                  padding={"6px"}
                  borderRadius={"4px"}
                >
                  <img
                    src={serviceProvider?.image}
                    alt=""
                    width={"100%"}
                    height={"100%"}
                  ></img>
                </Box>
              )}
            </Paper>
          </Grid>
          <Grid item md={9}>
            <Paper
              className="ResidentView__basic__details__container"
              elevation={4}
            >
              <Grid container spacing={1}>
                <Grid item md={12} display={FLEX} alignItems={CENTER}>
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Full Name
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid
                    md={9}
                    paddingLeft={2}
                    display={FLEX}
                    alignItems={CENTER}
                  >
                    <Typography
                      fontSize={35}
                      fontWeight={600}
                      color={DEEP_OCEAN_BLUE}
                      className={
                        serviceProvider?.serviceProvider ? "" : "data__opacity"
                      }
                    >
                      {serviceProvider?.serviceProvider
                        ? `${serviceProvider?.serviceProvider?.firstName} ${serviceProvider?.serviceProvider?.lastName}`
                        : "-"}
                    </Typography>
                    {serviceProvider?.serviceProvider?.resident && (
                      <Box component="span" className="view__modal__badge">
                        <Typography fontWeight={600}>R</Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid item display={FLEX} md={12}>
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Phone
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid md={9} paddingLeft={2}>
                    <Typography
                      className={
                        serviceProvider?.serviceProvider ? "" : "data__opacity"
                      }
                    >
                      {serviceProvider?.serviceProvider?.phone &&
                      serviceProvider.serviceProvider.phone.length === 10
                        ? `${
                            serviceProvider.serviceProvider.countryCode
                          }-${serviceProvider.serviceProvider.phone.slice(
                            0,
                            5
                          )}-${serviceProvider.serviceProvider.phone.slice(5)}`
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={12} display={FLEX}>
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Business Name
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid md={9} paddingLeft={2}>
                    <Typography
                      className={
                        serviceProvider?.serviceProvider ? "" : "data__opacity"
                      }
                    >
                      {serviceProvider?.serviceProvider?.businessName || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={12} display={FLEX}>
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Preferred Language
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid md={9} paddingLeft={2}>
                    <Typography
                      className={
                        serviceProvider?.serviceProvider ? "" : "data__opacity"
                      }
                    >
                      {serviceProvider?.serviceProvider?.preferredLanguage
                        ?.name || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={12} display={FLEX}>
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Address
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid md={9} paddingLeft={2}>
                    <Typography
                      className={
                        serviceProvider?.serviceProvider ? "" : "data__opacity"
                      }
                    >
                      {serviceProvider?.serviceProvider?.address
                        ?.formattedAddress || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={12} display={FLEX}>
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Location
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid md={9} paddingLeft={2}>
                    <Link
                      key="1"
                      color={DEEP_OCEAN_BLUE}
                      to={
                        serviceProvider?.serviceProvider?.address?.locationUrl
                      }
                      target="_blank"
                      className="ServiceProviderView__location__link"
                    >
                      {serviceProvider?.serviceProvider?.address?.locationUrl}
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={12}>
            <Grid container spacing={3}>
              <Grid item md={12} paddingBottom={1}>
                <div className="ResidentView__section__divider">
                  <span className="ResidentView__section__divider__span">
                    Other Information
                  </span>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Email
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography>
                  {serviceProvider?.serviceProvider?.email || "-"}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Name Prefix
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography>
                  {serviceProvider?.serviceProvider?.namePrefix || "-"}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Title
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography>
                  {serviceProvider?.serviceProvider?.title || "-"}
                </Typography>
              </Grid>
              <Grid item md={4} display={FLEX} justifyContent={SPACE_BETWEEN}>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Serviced To
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Tooltip
                  title={
                    serviceProvider?.servicedToList
                      ?.map(
                        (resident) =>
                          resident?.resident.firstName +
                          " " +
                          resident?.resident.lastName
                      )
                      ?.join(", ") || "-"
                  }
                  placement={TOP}
                  arrow
                >
                  <Typography>
                    {serviceProvider?.servicedToList
                      ?.map(
                        (resident) =>
                          resident?.resident.firstName +
                          " " +
                          resident?.resident.lastName
                      )
                      ?.join(", ") || "-"}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Joined on
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography>
                  {serviceProvider?.serviceProvider?.createdDate
                    ? moment(
                        serviceProvider?.serviceProvider?.createdDate
                      ).format("Do MMMM YYYY")
                    : "-"}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Category
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Tooltip
                  title={
                    serviceProvider?.serviceProvider?.categories
                      ?.map((category) => category.name)
                      .join(", ") || "-"
                  }
                  placement={TOP}
                  arrow
                >
                  <Typography>
                    {serviceProvider?.serviceProvider?.categories
                      ?.map((category) => category.name)
                      .join(", ") || "-"}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid
                item
                md={5}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Door Delivery
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={7} display={FLEX} alignItems={CENTER}>
                <Typography>
                  {serviceProvider?.serviceProvider?.deliverable ? "Yes" : "No"}
                </Typography>
              </Grid>
              <Grid
                item
                md={5}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Home Visit
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={7} display={FLEX} alignItems={CENTER}>
                <Typography>
                  {serviceProvider?.serviceProvider?.homeVisit ? "Yes" : "No"}
                </Typography>
              </Grid>
              <Grid item md={5} display={FLEX} justifyContent={SPACE_BETWEEN}>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Referred By
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={7} display={FLEX} alignItems={CENTER}>
                <Tooltip
                  title={
                    serviceProvider?.referredByList
                      ?.map(
                        (resident) =>
                          resident?.resident.firstName +
                          " " +
                          resident?.resident.lastName
                      )
                      ?.join(", ") || "-"
                  }
                  placement={TOP}
                  arrow
                >
                  <Typography>
                    {serviceProvider?.referredByList
                      ?.map(
                        (resident) =>
                          resident?.resident.firstName +
                          " " +
                          resident?.resident.lastName
                      )
                      ?.join(", ") || "-"}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid
                item
                md={5}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Refer Anonymously
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={7} display={FLEX} alignItems={CENTER}>
                <Typography>
                  {serviceProvider?.serviceProvider?.referrerAnonymous
                    ? "Yes"
                    : "No"}
                </Typography>
              </Grid>
              <Grid
                item
                md={5}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Referrer Availed Service
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={7} display={FLEX} alignItems={CENTER}>
                <Typography>
                  {serviceProvider?.serviceProvider?.referrerAvailedService
                    ? "Yes"
                    : "No"}
                </Typography>
              </Grid>
              <Grid
                item
                md={5}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Distance From Community
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={7} display={FLEX} alignItems={CENTER}>
                <Typography>
                  {serviceProvider?.serviceProvider?.address
                    ?.distanceFromCommunity
                    ? serviceProvider?.serviceProvider?.address
                        ?.distanceFromCommunity + " Km"
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2} display={FLEX} justifyContent={SPACE_BETWEEN}>
            <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
              Description
            </Typography>
            <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
              :
            </Typography>
          </Grid>
          <Grid item md={10}>
            <Typography>
              {serviceProvider?.serviceProvider?.description}
            </Typography>
          </Grid>
          <Grid item md={6} justifyContent={FLEX_END} display={FLEX}>
            <Button
              variant="outlined"
              className="cancel__button"
              onClick={() => setViewModal(false)}
            >
              Close
            </Button>
          </Grid>

          {location.pathname.includes("service-provider") && (
            <Grid item md={6} justifyContent={FLEX_START} display={FLEX}>
              <Button
                variant="contained"
                className="submit__button"
                onClick={editHandler}
              >
                Edit
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Modal>
  );
};

export default ModalView;
