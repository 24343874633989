import { Font, StyleSheet } from "@react-pdf/renderer";
import {
  BOLD,
  CENTER,
  COLUMN,
  LEFT,
  NONE,
  ROW,
  SPACE_BETWEEN,
  WHITE,
} from "../constants";

Font.register({
  family: "DM Sans",
  fonts: [
    {
      src: require("../../assets/fonts/DMSans_18pt-Regular.ttf"),
      fontWeight: "normal",
    },
    {
      src: require("../../assets/fonts/DMSans_18pt-Bold.ttf"),
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "DM Serif Display",
  fonts: [
    {
      src: require("../../assets/fonts/DMSerifDisplay-Regular.ttf"),
      fontWeight: "normal",
    },
  ],
});

Font.register({
  family: "Noto Sans Tamil",
  fonts: [
    {
      src: require("../../assets/fonts/NotoSansTamil-Regular.ttf"),
      fontWeight: "normal",
    },
    {
      src: require("../../assets/fonts/NotoSansTamil-Bold.ttf"),
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "DM Sans",
    flexDirection: COLUMN,
    backgroundColor: WHITE,
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },

  //Pin PDF Styles
  greeting: {
    fontSize: 9,
    marginTop: 50,
    marginBottom: 2,
  },
  apartment: {
    fontSize: 8,
    color: "#545454",
    marginBottom: 2,
  },
  text: {
    fontSize: 11,
    marginTop: 5,
  },
  pinText: {
    fontFamily: "DM Serif Display",
    fontSize: 17,
    marginTop: 29,
    backgroundColor: "#FFF0DE",
    padding: 10,
    fontWeight: BOLD,
    width: 600,
    marginLeft: -40,
    paddingLeft: 38,
  },
  pinNo: {
    fontSize: 20,
    fontWeight: BOLD,
    position: "absolute",
    bottom: 30,
    right: 42,
    backgroundColor: WHITE,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 20,
    paddingRight: 20,
    border: "1px solid #545454",
    borderRadius: 10,
    width: 116,
    textAlign: CENTER,
  },
  logo: {
    width: 100,
    marginBottom: -20,
  },
  mobilimg: {
    width: 250,
    marginTop: -10,
    marginLeft: 20,
  },
  columns: {
    flexDirection: "row",
    justifyContent: SPACE_BETWEEN,
  },
  column: {
    flex: 1,
    padding: 10,
    marginRight: 10,
    paddingTop: 15,
  },
  lastColumn: {
    flex: 1,
    padding: 10,
  },
  pinColumn: {
    position: "relative",
  },
  shopping: {
    width: 15,
    height: 15,
  },
  para: {
    fontSize: 10,
    marginTop: 8,
    color: "#545454",
  },
  title: {
    flexDirection: "row",
    alignItems: CENTER,
  },
  head: {
    marginLeft: 7,
    fontSize: 13,
    fontWeight: BOLD,
  },
  info: {
    backgroundColor: "#FBF8F5",
    marginTop: -10,
    marginBottom: -6,
    paddingTop: 15,
    paddingBottom: 10,
    width: 605,
    paddingLeft: 28,
    marginLeft: -28,
    paddingRight: 30,
  },
  line: {
    width: "95%",
    height: 1,
    backgroundColor: "#D9D9D9",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 8,
  },
  footerLine: {
    width: "97%",
    height: 1,
    backgroundColor: "#D9D9D9",
    marginTop: 7,
    marginBottom: 5,
    marginLeft: 7,
  },
  bulletPara: {
    fontSize: 7,
    marginTop: 8,
    color: "#545454",
    paddingLeft: 10,
    lineHeight: 1.5,
    marginBottom: 5,
  },
  thanksMsg: {
    width: "99%",
    paddingLeft: 8,
    marginTop: 30,
    marginBottom: 30,
  },
  thanks: {
    fontSize: 11,
    marginTop: 20,
  },
  team: {
    fontSize: 11,
    fontWeight: BOLD,
  },
  qr: {
    width: 35,
  },
  footerContact: {
    fontSize: 8,
    width: 400,
    marginLeft: -130,
  },
  startupIndia: {
    width: 70,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: -130,
  },
  footerColumns: {
    flexDirection: "row",
    alignItems: CENTER,
  },
  footerColumn: {
    flex: 1,
    padding: 10,
    marginRight: 10,
    paddingTop: 15,
  },
  footerLogo: {
    width: 35,
    marginLeft: 125,
  },
  underline: {
    textDecoration: "underline",
  },

  //Only Pin PDF Styles
  onlyPinLogo: {
    width: 100,
    marginBottom: -20,
    opacity: 0,
  },
  onlyPinText: {
    fontSize: 11,
    marginTop: 5,
    opacity: 0,
  },

  onlyPinPinText: {
    fontFamily: "DM Serif Display",
    fontSize: 17,
    marginTop: 29,
    backgroundColor: "#FFF0DE",
    padding: 10,
    fontWeight: BOLD,
    width: 600,
    marginLeft: -40,
    paddingLeft: 38,
    opacity: 0,
  },

  onlyPinMobilimg: {
    width: 250,
    marginTop: -10,
    marginLeft: 20,
    opacity: 0,
  },

  onlyPinNo: {
    fontSize: 20,
    fontWeight: BOLD,
    position: "absolute",
    bottom: 21,
    right: 43,
    backgroundColor: WHITE,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 20,
    paddingRight: 20,
    border: NONE,
    borderRadius: 10,
    width: 116,
    textAlign: CENTER,
  },

  onlyPinInfo: {
    backgroundColor: "#FBF8F5",
    marginTop: -10,
    marginBottom: -6,
    paddingTop: 15,
    paddingBottom: 10,
    width: 605,
    paddingLeft: 28,
    marginLeft: -28,
    paddingRight: 30,
    opacity: 0,
  },

  onlyThanksMsg: {
    width: "99%",
    paddingLeft: 8,
    marginTop: 30,
    marginBottom: 30,
    opacity: 0,
  },

  onlyFooterLine: {
    width: "97%",
    height: 1,
    backgroundColor: "#D9D9D9",
    marginTop: 7,
    marginBottom: 5,
    marginLeft: 7,
    opacity: 0,
  },

  onlyFooterContact: {
    fontSize: 8,
    width: 400,
    marginLeft: -130,
    opacity: 0,
  },

  onlyFooterLogo: {
    width: 35,
    marginLeft: 125,
    opacity: 0,
  },

  onlyStartupIndia: {
    width: 70,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: -130,
    opacity: 0,
  },

  onlyQr: {
    width: 35,
    opacity: 0,
  },

  //Terms & Conditions PDF Styles - English
  heading: {
    fontFamily: "DM Serif Display",
    fontSize: 30,
    marginTop: 29,
    padding: 10,
    fontWeight: BOLD,
    width: 350,
    marginLeft: -40,
    paddingLeft: 38,
  },
  nivasiLogo: {
    width: 200,
    marginLeft: 80,
    marginTop: -20,
  },
  row: {
    flexDirection: ROW,
    justifyContent: SPACE_BETWEEN,
  },
  label: {
    width: "50%",
    fontSize: 10,
    marginTop: 5,
  },
  value: {
    width: "150%",
    textAlign: LEFT,
    fontSize: 10,
    marginTop: 5,
  },
  date: {
    fontSize: 12,
    fontWeight: BOLD,
  },
  bodyContent: {
    marginLeft: 8,
    marginRight: 8,
  },
  bodyNumber: {
    fontSize: 11,
    color: "#858585",
    fontWeight: BOLD,
    marginTop: 15,
  },
  bodyTitle: {
    fontSize: 20,
    fontWeight: BOLD,
  },
  bodyPara: {
    fontSize: 10,
    marginTop: 10,
  },
  signDate: {
    fontSize: 12,
    marginTop: 20,
  },
  signPlace: {
    fontSize: 12,
  },
  sign: {
    fontSize: 12,
    marginLeft: 150,
    marginTop: 20,
  },
  termsFooter: {
    backgroundColor: "#333737",
    marginLeft: -10,
    width: 700,
    zIndex: 1,
    marginBottom: -2,
  },
  termsFooterCommunity: {
    fontSize: 25,
    marginLeft: 40,
    color: "#EE693E",
    width: 230,
    lineHeight: 1,
  },
  footerImage: {
    marginLeft: -14,
    width: 600,
    zIndex: 0,
    marginBottom: -10,
  },
  termsFooterSupport: {
    fontSize: 20,
    fontWeight: BOLD,
    backgroundColor: "#565F5F",
    padding: 10,
    borderRadius: 20,
    marginLeft: -80,
    width: 260,
    color: WHITE,
    textAlign: CENTER,
    paddingBottom: 20,
  },
  termsFooterSupportText: {
    fontSize: 10,
    fontWeight: "normal",
  },
  footerLogoImage: {
    width: 70,
    marginLeft: 40,
    marginTop: 20,
  },
  registeredText: {
    fontSize: 6,
    color: WHITE,
    marginLeft: 40,
    marginTop: 5,
  },

  //Terms & Conditions PDF Styles - Tamil
  tamilHeading: {
    fontFamily: "Noto Sans Tamil",
    fontSize: 20,
    marginTop: 29,
    padding: 10,
    fontWeight: BOLD,
    width: 350,
    marginLeft: -40,
    paddingLeft: 38,
  },
  tamilDate: {
    fontSize: 12,
    fontWeight: BOLD,
    fontFamily: "Noto Sans Tamil",
  },
  tamilBodyTitle: {
    fontSize: 15,
    fontWeight: BOLD,
    fontFamily: "Noto Sans Tamil",
  },
  tamilBodyPara: {
    fontSize: 9.5,
    marginTop: 10,
    fontFamily: "Noto Sans Tamil",
  },
});

export default styles;
