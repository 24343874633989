import React from "react";
import Layout from "../../components/Layout/Layout.js";
import View from "../../components/Resident/View.js";

const ResidentViewPage = () => {
  return (
    <Layout>
      <View />
    </Layout>
  );
};

export default ResidentViewPage;
