import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  InputAdornment,
  OutlinedInput,
  Paper,
  Grid,
  Button,
  Autocomplete,
  TextField,
  Box,
  Chip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "../Common/DataTable";
import ConfirmationModal from "../Common/ConfirmationModal";
import Loader from "../Common/Loader";
import Edit from "./Edit";
import { handleInputChange } from "../../helpers/GetMultiselectFilterValues";
import {
  UNHIDE_MESSAGE,
  REINSTATE_MESSAGE,
  HIDE_MESSAGE,
  REMOVE_MESSAGE,
  SMALL,
  CENTER,
  REVIEW_LIST,
  START,
  ALL,
  REVIEW_TYPE_FILTER,
  REVIEW_STATE_FILTER,
  REVIEW_STATUS_FILTER,
} from "../../utils/constants";
import { useDispatch } from "react-redux";
import {
  openConfirmationModal,
  startLoading,
  stopLoading,
} from "../../redux/Slices/CommonSlice";
import FlagPopover from "./FlagPopover";
import View from "./View";
import {
  deleteServiceProviderReview,
  fetchAllServiceProviderReview,
  unHideServiceProviderReview,
} from "../../services/review-management";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { reviewManagementCsvReport } from "../../services/csvReport";

const List = () => {
  const initialFilterValues = localStorage.getItem(
    "serviceProviderReviewFilterValues"
  )
    ? JSON.parse(localStorage.getItem("serviceProviderReviewFilterValues"))
    : {
        anonymous: [ALL],
        flagged: [ALL],
        deleted: [ALL],
      };

  const [serviceProviderReview, setServiceProviderReview] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [openFlagPopover, setOpenFlagPopover] = useState(null);
  const [flagMessage, setFlagMessage] = useState({});
  const [deleteReviewId, setDeleteReviewId] = useState(null);
  const [unHideReviewId, setUnhideReviewId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const filterValuesRef = useRef(filterValues);
  const rowsPerPageRef = useRef(rowsPerPage);
  const [sortBy, setSortBy] = useState("asc");
  const [sortColumn, setSortColumn] = useState("Review To");
  const searchValuesRef = useRef(searchValue);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isInitialRender = useRef(true);

  useEffect(() => {
    setPage(0);
    localStorage.setItem(
      "serviceProviderReviewFilterValues",
      JSON.stringify({
        anonymous: filterValues.anonymous,
        flagged: filterValues.flagged,
        deleted: filterValues.deleted,
      })
    );
  }, [filterValues]);

  useEffect(() => {
    setPage(0);
    filterValuesRef.current = filterValues;
  }, [filterValues]);

  useEffect(() => {
    searchValuesRef.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    rowsPerPageRef.current = rowsPerPage;
  }, [rowsPerPage]);

  const fetchData = useCallback(
    async (pageVal, sortColumn, sortBy) => {
      dispatch(startLoading());
      const response = await fetchAllServiceProviderReview(
        pageVal || page, // page
        rowsPerPage, // rowsPerPage
        searchValuesRef.current,
        filterValues.anonymous,
        filterValues.flagged,
        filterValues.deleted,
        sortColumn || "Review To",
        sortBy || "asc"
      );
      if (response.success && response.data?.serviceProviderReviews) {
        setTotalRecords(response?.data?.totalRecords || 0);
        setServiceProviderReview(response?.data?.serviceProviderReviews);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    },
    [page, rowsPerPage, dispatch, filterValues]
  );

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    setPage(0);
    const searchReview = async () => {
      dispatch(startLoading());
      const response = await fetchAllServiceProviderReview(
        0,
        rowsPerPageRef.current,
        searchValue,
        filterValuesRef.current.anonymous,
        filterValuesRef.current.flagged,
        filterValuesRef.current.deleted,
        sortColumn,
        sortBy
      );
      if (response.success && response.data?.serviceProviderReviews) {
        setTotalRecords(response?.data?.totalRecords || 0);
        setServiceProviderReview(response?.data?.serviceProviderReviews);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    };
    const debouncer = setTimeout(() => {
      searchReview();
    }, 500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [searchValue, dispatch, sortBy, sortColumn]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onEdit = (review) => {
    setOpenModal(true);
    setEditData(review);
  };

  const onView = (review) => {
    setViewModal(true);
    setEditData(review);
  };

  const handleClick = (review, event) => {
    setOpenFlagPopover(event.currentTarget);
    setFlagMessage(review);
  };

  const onDelete = (review) => {
    setDeleteReviewId(review.id);
    if (review?.deleted) {
      setConfirmationMessage(REINSTATE_MESSAGE);
      dispatch(openConfirmationModal());
    } else {
      setConfirmationMessage(REMOVE_MESSAGE);
      dispatch(openConfirmationModal());
    }
  };

  const onUnhide = (review) => {
    setUnhideReviewId(review.id);
    setConfirmationMessage(review?.hide ? UNHIDE_MESSAGE : HIDE_MESSAGE);
    dispatch(openConfirmationModal());
  };

  const onUnhideHandler = async () => {
    dispatch(startLoading());
    setUnhideReviewId(null);
    setDeleteReviewId(null);
    const response = await unHideServiceProviderReview(unHideReviewId);
    if (response.success && response.data) {
      fetchData(page, sortColumn, sortBy);
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "success" });
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(newPage, sortColumn, sortBy);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    rowsPerPageRef.current = parseInt(event.target.value, 10);
    setPage(0);
  };

  const onDeleteHandler = async () => {
    dispatch(startLoading());
    setUnhideReviewId(null);
    setDeleteReviewId(null);
    const response = await deleteServiceProviderReview(deleteReviewId);
    fetchData();
    if (response.success && response.data) {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "success" });
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  };

  const sortOrderHandler = (header) => {
    setSortBy(sortBy === "asc" ? "desc" : "asc");
    setSortColumn(header);
  };

  const handleViewClick = (index, row) => {
    if (index === 0) {
      navigate(
        `/admin/service-provider/view/${row?.data?.serviceProvider?.id}`
      );
    } else if (index === 1) {
      navigate(`/admin/resident/view/${row?.data?.resident?.id}`);
    }
  };

  const clearHandler = () => {
    setFilterValues(() => ({
      anonymous: [ALL],
      flagged: [ALL],
      deleted: [ALL],
    }));
    setSearchValue("");
  };

  const downloadHandler = async () => {
    const response = await reviewManagementCsvReport(
      0,
      rowsPerPageRef.current,
      searchValue,
      filterValuesRef.current.anonymous,
      filterValuesRef.current.flagged,
      filterValuesRef.current.deleted,
      sortColumn,
      sortBy
    );
    if (response.data) {
      const fileName = "Review-management-report.csv";
      const file = new Blob([response.data], { type: "application/csv" });
      const url = window.URL.createObjectURL(file);
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = url;
      anchor.click();
    } else {
      enqueueSnackbar(response.data, { variant: "error" });
    }
  };

  return (
    <Paper className="container" elevation={4}>
      <Grid container spacing={1} paddingBottom={2} alignItems={CENTER}>
        <Grid item md={6}>
          <OutlinedInput
            fullWidth
            placeholder="Search by service provider name"
            size={SMALL}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value.trim())}
            startAdornment={
              <InputAdornment position={START}>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item md={6}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={[
              ALL,
              ...filterValues?.anonymous.filter(
                (selectedValue) => selectedValue !== ALL
              ),
              ...REVIEW_TYPE_FILTER.filter(
                (option) =>
                  !filterValues?.anonymous.includes(option) && option !== ALL
              ),
            ]}
            value={filterValues?.anonymous}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };
              handleInputChange(
                event,
                "anonymous",
                setFilterValues,
                filterValues,
                () => {},
                ALL
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Type" name="anonymous" />
            )}
            renderTags={(selected, getTagProps) => {
              const displayedTags = selected.slice(0, 2);
              const hiddenTagsCount = selected.length - displayedTags.length;
              return (
                <Box display="flex" alignItems="center">
                  {displayedTags.map((value, index) => (
                    <Chip
                      size="small"
                      key={value}
                      label={
                        value === ALL
                          ? ALL
                          : REVIEW_TYPE_FILTER.find(
                              (anonymous) => anonymous === value
                            )
                      }
                      {...getTagProps({ index })}
                    />
                  ))}
                  {hiddenTagsCount > 0 && <Box ml={1}>+{hiddenTagsCount}</Box>}
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item md={4} marginTop={1}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={[
              ALL,
              ...filterValues?.flagged.filter(
                (selectedValue) => selectedValue !== ALL
              ),
              ...REVIEW_STATE_FILTER.filter(
                (option) =>
                  !filterValues?.flagged.includes(option) && option !== ALL
              ),
            ]}
            value={filterValues?.flagged}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };
              handleInputChange(
                event,
                "flagged",
                setFilterValues,
                filterValues,
                () => {},
                ALL
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="State" name="flagged" />
            )}
            renderTags={(selected, getTagProps) => {
              const displayedTags = selected.slice(0, 2);
              const hiddenTagsCount = selected.length - displayedTags.length;
              return (
                <Box display="flex" alignItems="center">
                  {displayedTags.map((value, index) => (
                    <Chip
                      size="small"
                      key={value}
                      label={
                        value === ALL
                          ? ALL
                          : REVIEW_STATE_FILTER.find(
                              (flagged) => flagged === value
                            )
                      }
                      {...getTagProps({ index })}
                    />
                  ))}
                  {hiddenTagsCount > 0 && <Box ml={1}>+{hiddenTagsCount}</Box>}
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item md={4} marginTop={1}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={[
              ALL,
              ...filterValues?.deleted.filter(
                (selectedValue) => selectedValue !== ALL
              ),
              ...REVIEW_STATUS_FILTER.filter(
                (option) =>
                  !filterValues?.deleted.includes(option) && option !== ALL
              ),
            ]}
            value={filterValues?.deleted}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };
              handleInputChange(
                event,
                "deleted",
                setFilterValues,
                filterValues,
                () => {},
                ALL
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Status" name="deleted" />
            )}
            renderTags={(selected, getTagProps) => {
              const displayedTags = selected.slice(0, 2);
              const hiddenTagsCount = selected.length - displayedTags.length;
              return (
                <Box display="flex" alignItems="center">
                  {displayedTags.map((value, index) => (
                    <Chip
                      size="small"
                      key={value}
                      label={
                        value === ALL
                          ? ALL
                          : REVIEW_STATUS_FILTER.find(
                              (deleted) => deleted === value
                            )
                      }
                      {...getTagProps({ index })}
                    />
                  ))}
                  {hiddenTagsCount > 0 && <Box ml={1}>+{hiddenTagsCount}</Box>}
                </Box>
              );
            }}
          />
        </Grid>
        <Grid md={2} item className="resident__clear__button" marginTop={1}>
          <Button
            variant="contained"
            className="submit__button"
            onClick={clearHandler}
          >
            Clear All
          </Button>
        </Grid>
        <Grid md={2} item className="resident__clear__button" marginTop={1}>
          <Button
            variant="contained"
            className="submit__button"
            onClick={downloadHandler}
          >
            Download CSV
          </Button>
        </Grid>
      </Grid>
      <DataTable
        from={REVIEW_LIST}
        headers={[
          { label: "Review To", width: "10%" },
          { label: "Review By", width: "10%" },
          { label: "Review" },
          { label: "Reviewed On" },
          { label: "Views", width: "5%" },
          { label: "Helpful", width: "5%" },
          { label: "Rating" },
          { label: "Actions" },
        ]}
        data={serviceProviderReview.map((row) => {
          return {
            id: row?.serviceProviderReview?.id,
            commonColumns: [
              row?.serviceProviderReview?.serviceProvider?.firstName
                ? row?.serviceProviderReview?.serviceProvider?.firstName +
                  " " +
                  row?.serviceProviderReview?.serviceProvider?.lastName +
                  " | " +
                  row?.serviceProviderReview?.serviceProvider?.businessName
                : "-",
              row?.serviceProviderReview?.resident?.firstName
                ? row?.serviceProviderReview?.resident?.firstName +
                  " " +
                  row?.serviceProviderReview?.resident?.lastName +
                  " | " +
                  row?.serviceProviderReview?.resident?.apartment?.number +
                  " | " +
                  row?.serviceProviderReview?.resident?.apartment?.building
                    ?.name
                : "-",
              row?.serviceProviderReview?.description || "-",
              row?.serviceProviderReview?.createdDate
                ? moment(row?.serviceProviderReview?.createdDate).format(
                    "DD MMM YYYY"
                  )
                : "-",
              row?.viewCount || "0",
              row?.helpfulCount || "0",
            ],
            ratingValue: row?.serviceProviderReview?.rating,
            data: row?.serviceProviderReview,
            flag: row?.serviceProviderReview?.flaggedBy,
          };
        })}
        onView={onView}
        onEdit={onEdit}
        onDelete={onDelete}
        onUnhide={onUnhide}
        sortOrderHandler={sortOrderHandler}
        totalRecords={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleClick={handleClick}
        sortBy={sortBy}
        sortColumn={sortColumn}
        fetchData={fetchData}
        classes="Review__Service__Provider"
        handleViewClick={handleViewClick}
      />
      <ConfirmationModal
        message={confirmationMessage}
        onOkHandler={unHideReviewId ? onUnhideHandler : onDeleteHandler}
        onCancelHandler={() => {
          setUnhideReviewId(null);
          setDeleteReviewId(null);
        }}
        cancelText="No"
        okText="Yes"
      />
      <Loader />
      <View
        viewModal={viewModal}
        setViewModal={setViewModal}
        editData={editData}
      />
      <Edit
        openModal={openModal}
        setOpenModal={setOpenModal}
        editData={editData}
        setEditData={setEditData}
        fetchData={fetchData}
      />
      <FlagPopover
        openFlagPopover={openFlagPopover}
        setOpenFlagPopover={setOpenFlagPopover}
        flagMessage={flagMessage}
        setFlagMessage={setFlagMessage}
      />
    </Paper>
  );
};

export default List;
