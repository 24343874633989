import {
  Backdrop,
  Badge,
  Breadcrumbs,
  Grid,
  Paper,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import {
  BLACK,
  BUY_SELL_VIEW,
  CENTER,
  DEEP_OCEAN_BLUE,
  FLEX,
  SPACE_BETWEEN,
  WHITE,
} from "../../utils/constants";
import loader from "../../assets/images/Loader.gif";
import { useDispatch, useSelector } from "react-redux";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import moment from "moment";
import { useEffect, useState } from "react";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { Link, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { fetchProduct } from "../../services/buy-sell";
import ImagePicker from "../ServiceProvider/ImagePicker";

const View = () => {
  const [products, setProducts] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    const fetchProductData = async () => {
      dispatch(startLoading());
      const response = await fetchProduct(id);
      if (response.success && response?.data) {
        while (response?.data?.images?.length < 3) {
          response?.data?.images.push({ id: null, url: null });
        }
        setProducts(response?.data);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    };
    fetchProductData();
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(startLoading());
    const preloadImages = async () => {
      const promises = products?.images
        ?.filter((image) => image.id)
        ?.map((src) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src?.url;
            img.onload = resolve;
            img.onerror = reject;
          });
        });

      try {
        await Promise.all(promises);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
      }
    };

    preloadImages();
  }, [products?.images, dispatch]);

  const breadcrumbs = [
    <Link
      key="1"
      color={DEEP_OCEAN_BLUE}
      to={"/admin/buy-sell/all"}
      className="breadcrumb__link"
    >
      Buy & Sell
    </Link>,
    <Typography key="3" color={BLACK}>
      View
    </Typography>,
  ];

  const indexHandler = () => {
    setImageIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));
  };

  const imageIndexHandler = () => {
    setImageIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
  };

  const loading = useSelector((state) => state.common.loading);
  return (
    <>
      <Backdrop
        className={products?.buySellItemCategory ? "" : "loader"}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        color={DEEP_OCEAN_BLUE}
        open={loading}
      >
        <img src={loader} alt="" className="Nivasi__Loader" />
      </Backdrop>
      <Paper className="paper__container" elevation={4}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Stack spacing={3}>
              <Breadcrumbs
                separator={<NavigateNextIcon />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Stack>
          </Grid>
          <Grid item md={3}>
            <Paper
              elevation={4}
              className="BuyAndSellView__basic__details__container"
            >
              <div
                onClick={indexHandler}
                style={{
                  cursor: "pointer",
                }}
              >
                <SvgIcon>
                  <path d="M14 6l-6 6 6 6V6z" fill={DEEP_OCEAN_BLUE} />
                </SvgIcon>
              </div>
              <ImagePicker
                defaultImage={products?.images?.[imageIndex]?.url}
                imageList={products?.images}
                imageIndex={imageIndex}
                from={BUY_SELL_VIEW}
              />
              <div
                onClick={imageIndexHandler}
                style={{
                  cursor: "pointer",
                }}
              >
                <SvgIcon>
                  <path d="M10 6l6 6-6 6V6z" fill={DEEP_OCEAN_BLUE} />
                </SvgIcon>
              </div>
            </Paper>
          </Grid>
          <Grid item md={9}>
            <Paper
              className="ResidentView__basic__details__container"
              elevation={4}
            >
              <Grid container>
                <Grid item md={12}>
                  <Typography
                    fontSize={35}
                    fontWeight={600}
                    color={DEEP_OCEAN_BLUE}
                    marginBottom={"15px"}
                    className={
                      products?.buySellItemCategory ? "" : "data__opacity"
                    }
                  >
                    {products?.buySellItemCategory
                      ? products?.buySellItemCategory?.buySellItem?.title
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Grid paddingBottom={"10px"}>
                    <Typography
                      paddingBottom={"5px"}
                      color={DEEP_OCEAN_BLUE}
                      fontWeight={600}
                    >
                      Resident
                    </Typography>
                    <Typography
                      className={
                        products?.buySellItemCategory ? "" : "data__opacity"
                      }
                    >
                      {products?.buySellItemCategory
                        ? products?.buySellItemCategory?.buySellItem?.resident
                            ?.firstName +
                          " " +
                          products?.buySellItemCategory?.buySellItem?.resident
                            ?.lastName
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid paddingBottom={"10px"}>
                    <Typography
                      color={DEEP_OCEAN_BLUE}
                      fontWeight={600}
                      paddingBottom={"5px"}
                    >
                      Category
                    </Typography>
                    <Typography
                      className={
                        products?.buySellItemCategory ? "" : "data__opacity"
                      }
                    >
                      {products?.buySellItemCategory
                        ? products?.buySellItemCategory?.productCategory?.name
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      color={DEEP_OCEAN_BLUE}
                      fontWeight={600}
                      paddingBottom={"5px"}
                    >
                      Condition
                    </Typography>
                    <Typography
                      className={
                        products?.buySellItemCategory ? "" : "data__opacity"
                      }
                    >
                      {products?.buySellItemCategory
                        ? products?.buySellItemCategory?.buySellItem?.condition
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid paddingBottom={"10px"}>
                    <Typography
                      color={DEEP_OCEAN_BLUE}
                      fontWeight={600}
                      paddingBottom={"5px"}
                    >
                      Delivery Type
                    </Typography>
                    <Typography
                      className={
                        products?.buySellItemCategory ? "" : "data__opacity"
                      }
                    >
                      {products?.buySellItemCategory
                        ? products?.buySellItemCategory?.buySellItem
                            ?.deliveryType?.name
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid paddingBottom={"10px"}>
                    <Typography
                      color={DEEP_OCEAN_BLUE}
                      fontWeight={600}
                      paddingBottom={"5px"}
                    >
                      Created Date
                    </Typography>
                    <Typography
                      className={
                        products?.buySellItemCategory ? "" : "data__opacity"
                      }
                    >
                      {products?.buySellItemCategory
                        ? moment(
                            products?.buySellItemCategory?.buySellItem
                              ?.createdDate
                          ).format("Do MMMM YYYY")
                        : "-"}
                    </Typography>
                  </Grid>
                  {products?.buySellItemCategory?.buySellItem?.sold && (
                    <Grid paddingLeft={"15px"}>
                      <Badge
                        badgeContent={"Sold"}
                        sx={{
                          "& .MuiBadge-badge": {
                            color: WHITE,
                            backgroundColor: DEEP_OCEAN_BLUE,
                          },
                        }}
                      ></Badge>
                    </Grid>
                  )}
                  {products?.buySellItemCategory?.buySellItem?.freeListing && (
                    <Grid paddingLeft={"15px"}>
                      <Badge
                        badgeContent={"Free"}
                        sx={{
                          "& .MuiBadge-badge": {
                            color: WHITE,
                            backgroundColor: DEEP_OCEAN_BLUE,
                          },
                        }}
                      ></Badge>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={12}>
            <Grid container spacing={3}>
              <Grid item md={12} paddingBottom={1}>
                <div className="ResidentView__section__divider">
                  <span className="ResidentView__section__divider__span">
                    Other Information
                  </span>
                </div>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Description
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography
                  className={
                    products?.buySellItemCategory ? "" : "data__opacity"
                  }
                >
                  {products?.buySellItemCategory
                    ? products?.buySellItemCategory?.buySellItem?.description
                    : "-"}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Original Price
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography
                  className={
                    products?.buySellItemCategory ? "" : "data__opacity"
                  }
                >
                  {products?.buySellItemCategory
                    ? `${
                        products?.buySellItemCategory?.buySellItem
                          ?.originalPrice +
                        " " +
                        "Rs"
                      }`
                    : "-"}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Asking Price
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography
                  className={
                    products?.buySellItemCategory ? "" : "data__opacity"
                  }
                >
                  {products?.buySellItemCategory
                    ? `${
                        products?.buySellItemCategory?.buySellItem
                          ?.askingPrice +
                        " " +
                        "Rs"
                      }`
                    : "-"}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Expiry Date
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography
                  className={
                    products?.buySellItemCategory ? "" : "data__opacity"
                  }
                >
                  {products?.buySellItemCategory
                    ? moment(
                        products?.buySellItemCategory?.buySellItem?.expiryDate
                      ).format("Do MMMM YYYY")
                    : "-"}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Year of Purchase
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography
                  className={
                    products?.buySellItemCategory ? "" : "data__opacity"
                  }
                >
                  {products?.buySellItemCategory
                    ? products?.buySellItemCategory?.buySellItem?.yearOfPurchase
                    : "-"}
                </Typography>
              </Grid>
              {products?.buySellItemCategory?.buySellItem?.deletedReason && (
                <>
                  <Grid
                    item
                    md={4}
                    display={FLEX}
                    justifyContent={SPACE_BETWEEN}
                  >
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Deleted Reason
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid item md={8} display={FLEX} alignItems={CENTER}>
                    <Typography>
                      {
                        products?.buySellItemCategory?.buySellItem
                          ?.deletedReason
                      }
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default View;
