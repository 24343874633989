import Dashboard from "../../components/Dashboard/Dashboard.js";
import React from "react";
import Layout from "../../components/Layout/Layout.js";

const DashboardPage = () => {
  return (
    <Layout>
      <Dashboard />
    </Layout>
  );
};

export default DashboardPage;
