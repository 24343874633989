import React from "react";
import ServiceProviderListFlags from "../../components/ServiceProvider/ServiceProviderListFlags";
import Layout from "../../components/Layout/Layout";

const ServiceProviderFlagsPage = () => {
  return (
    <Layout>
      <ServiceProviderListFlags />
    </Layout>
  );
};

export default ServiceProviderFlagsPage;
