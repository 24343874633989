import adminRootInstance from "../utils/config/axios-config";

export const residentCsvReport = async (
  searchValue,
  status,
  deleteStatus,
  sortColumn,
  sortBy,
  building
) => {
  try {
    const response = await adminRootInstance.get(
      `/residents/export/csv?searchValue=${searchValue}&status=${status}&deleteStatus=${deleteStatus}&sortColumn=${sortColumn}&sortBy=${sortBy}&building=${building}`
    );
    if (response?.data) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const serviceProviderCsvReport = async (
  searchValue,
  category,
  preferredLanguage,
  deleteStatus,
  resident,
  sortColumn,
  sortBy,
  flagged
) => {
  try {
    const response = await adminRootInstance.get(
      `/service-provider/export/csv?searchValue=${searchValue}&category=${category}&preferredLanguage=${preferredLanguage}&deleteStatus=${deleteStatus}&resident=${resident}&sortColumn=${sortColumn}&sortBy=${sortBy}&flagged=${flagged}`
    );
    if (response?.data) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const reviewManagementCsvReport = async (
  offset,
  limit,
  searchValue,
  anonymous,
  flagged,
  status,
  sortColumn,
  sortBy
) => {
  try {
    const response = await adminRootInstance.get(
      `/service-provider-review/export/csv?offset=${offset}&limit=${limit}&searchValue=${searchValue}&anonymous=${anonymous}&flagged=${flagged}&status=${status}&sortColumn=${sortColumn}&sortBy=${sortBy}`
    );
    if (response?.data) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const buySellCsvReport = async (
  searchValue,
  category,
  resident,
  condition,
  deliveryType,
  freeListing,
  sold,
  expired,
  building,
  apartment,
  deleteStatus,
  sortColumn,
  sortBy,
  flagged
) => {
  try {
    const response = await adminRootInstance.get(
      `/product/export/csv?searchValue=${searchValue}&category=${category}&resident=${resident}&condition=${condition}&deliveryType=${deliveryType}&freeListing=${freeListing}&sold=${sold}&expired=${expired}&building=${building}&apartment=${apartment}&deleteStatus=${deleteStatus}&sortColumn=${sortColumn}&sortBy=${sortBy}&flagged=${flagged}`
    );
    if (response?.data) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const residentEngagementCsvReport = async (
  searchValue,
  from,
  to,
  building,
  sortColumn,
  sortBy
) => {
  try {
    const response = await adminRootInstance.get(
      `/reports/resident/export/csv?searchValue=${searchValue}&from=${from}&to=${to}&building=${building}&sortColumn=${sortColumn}&sortBy=${sortBy}`
    );
    if (response?.data) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const buySellProductCsvReport = async (
  offset,
  limit,
  from,
  to,
  building,
  apartment,
  resident,
  sortColumn,
  sortBy
) => {
  try {
    const response = await adminRootInstance.get(
      `/reports/product/export/csv?offset=${offset}&limit=${limit}&from=${from}&to=${to}&building=${building}&apartment=${apartment}&resident=${resident}&sortColumn=${sortColumn}&sortBy=${sortBy}`
    );
    if (response?.data) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const wordCloudCsvReport = async (searchValue, from, to) => {
  try {
    const response = await adminRootInstance.get(
      `/reports/search-term/export/csv?searchValue=${searchValue}&from=${from}&to=${to}`
    );
    if (response?.data) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export const serviceProviderEngagementCsvReport = async (
  searchValue,
  from,
  to,
  category,
  resident,
  sortColumn,
  sortBy
) => {
  try {
    const response = await adminRootInstance.get(
      `/reports/service-provider/export/csv?searchValue=${searchValue}&from=${from}&to=${to}&category=${category}&resident=${resident}&sortColumn=${sortColumn}&sortBy=${sortBy}`
    );
    if (response?.data) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};
